const IconPlus = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.44444 3V7.44444H3V8.55556H7.44444V13H8.55556V8.55556H13V7.44444H8.55556V3H7.44444Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconPlus
