const BlogPostSubTitle = ({ text }) => {
  return <h2 dangerouslySetInnerHTML={{ __html: text }} />
}

BlogPostSubTitle.defaultProps = {
  paragraph: ''
}
BlogPostSubTitle.ApiBlockToProps = data => {
  return {
    text: data.value
  }
}
export default BlogPostSubTitle
