const IconArrowRight = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4672 16.6663L16.6211 9.99967L10.4672 3.33301L9.48397 4.24065L14.1871 9.33575L3.33341 9.33575L3.33341 10.6739L14.1776 10.6739L9.48397 15.7587L10.4672 16.6663Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconArrowRight
