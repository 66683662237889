const IconPlay = (props) => {
  return (
    <svg
      width='68'
      height='40'
      viewBox='0 0 68 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='68' height='40' rx='20' fill='white' />
      <path
        d='M40.4756 20.6265L28.6335 27.1028H28.6321C28.3954 27.2321 28.1042 27.2321 27.8675 27.1028C27.6321 26.9736 27.4858 26.7348 27.4858 26.4762V13.5234C27.4858 13.2649 27.6321 13.0261 27.8675 12.8968C28.1043 12.7675 28.3954 12.7675 28.6321 12.8968L40.4742 19.3731H40.4756C40.711 19.5024 40.8572 19.7412 40.8572 19.9998C40.8572 20.2584 40.711 20.4972 40.4756 20.6265Z'
        fill='#F92044'
      />
    </svg>
  )
}

export default IconPlay
