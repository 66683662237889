import * as S from './FilterGroup.styled'

const FilterGroup = ({ title, children, noBorder, style }) => {
  return (
    <S.FilterGroup noBorder={noBorder} style={style}>
      {title && <S.FilterGroupTitle>{title}</S.FilterGroupTitle>}
      {children}
    </S.FilterGroup>
  )
}

export default FilterGroup
