import SVGIcon from '../icon/svgIcon'

const ResultsBlock = ({ value }) => {
  const { title, results } = value

  return (
    <div className='o-wrapper'>
      <div className='c-key-points'>
        {title && <h2 className='c-key-points__title'>{title}</h2>}
        <div className='o-layout c-key-points__points'>
          {results &&
            results.length > 0 &&
            results.map((item, index) => (
              <div
                key={index}
                className='c-key-point o-layout__item u-1/2@tablet u-1/3@desktop'
              >
                <div className='c-key-point__icon'>
                  <SVGIcon name={item.icon} />
                </div>
                <h3 className='c-key-point__title'>{item.title}</h3>
                <p className='c-key-point__copy'>{item.content}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default ResultsBlock
