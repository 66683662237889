import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import ArrowRight from '@icons/ArrowRight'
import * as S from './TestimonalCarousel.styled'
import { Container } from '@styles/global.styled';
import Testimonial from '@global/Blocks/TestimonalCarousel/Testimonial'
import React from 'react'
import Button from '@global/Button'
import IconArrowRight from '@icons/ArrowRight'
import { Link } from '@routes'
import IconCarouselArrowLeft from '@icons/CarouselArrowLeft'

const NextArrow = ({ onClick, className }) => {
  return (
    <S.CarouselNext onClick={onClick} className={className}>
      <ArrowRight />
    </S.CarouselNext>
  )
}

const PrevArrow = ({ onClick, className }) => {
  return (
    <S.CarouselPrev onClick={onClick} className={className}>
      <IconCarouselArrowLeft />
    </S.CarouselPrev>
  )
}

const TestimonialCarousel = (props) => {
  const { title, testimonials, callToActionText, callToActionLink } = props
  const settings = {
    arrows: true,
    infinite: false,
    fade: true,
    slidesToShow: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <S.Wrapper>
      <Container>
        <S.Header>
        {title && <S.Title>{title}</S.Title>}
          {callToActionLink?.path && (
            <S.ViewMoreLink>
              <Link route={callToActionLink?.path}>
                <Button
                  border
                  color='secondary'
                  endIcon={<IconArrowRight />}
                  size='lg'
                >
                  {callToActionText}
                </Button>
              </Link>
            </S.ViewMoreLink>
          )}
        </S.Header>
        <Slider {...settings}>
          {
            (testimonials && testimonials.length > 0)
            && testimonials.map((item, index) => {
              return (
                <Testimonial
                  testimonial={item}
                  key={item.id}
                />
              )
            })
          }
        </Slider>
        {callToActionLink?.path && (
          <S.ViewMoreLinkMobile>
            <Link route={callToActionLink?.path}>
              <Button
                border
                color='secondary'
                endIcon={<IconArrowRight />}
                size='lg'
              >
                {callToActionText}
              </Button>
            </Link>
          </S.ViewMoreLinkMobile>
        )}
      </Container>
    </S.Wrapper>
  )
}

TestimonialCarousel.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return
  }

  const { heading, testimonials, call_to_action_text, call_to_action_link, call_to_action_url } = data.value;
  const props = {
    title: heading,
    callToActionText: call_to_action_text || 'View More',
    callToActionLink: call_to_action_link,
    callToActionUrl: call_to_action_url,
    testimonials: testimonials
      ? testimonials
        .filter((t) => t.id)
        .map((t) => {
          return {
            ...t,
            name: t.name !== 'No Text' ? t.name : '',
            ...(t.image_thumbnail && { image: t.image_thumbnail }),
            ...(t.headline && { headline: t.headline }),
            ...(t.rating && { rating: t.rating }),
            image: t.image,
          }
        })
      : [],
  }

  if (props.testimonials.length > 0) {
    return props;
  }
}

export default TestimonialCarousel
