import { formatDate } from '@lib/date'
import * as S from './ListingInspection.styled'

const ListingInspection = ({ listing }) => {
  return listing.listingType != 'sold' && listing.inspection ? (
    <S.ListingInspection>
      Open:{' '}
      {formatDate(
        listing.inspection?.inspection_time,
        'EEEE do MMMM, h:mmaaa',
        listing.timezone
      )}
      {' - '}
      {formatDate(
        listing.inspection?.inspection_end_time,
        'h:mmaaa',
        listing.timezone
      )}
    </S.ListingInspection>
  ) : null
}

export default ListingInspection
