import ReactGA from 'react-ga'
import { ApiKeys } from './variables'

// initialise Google Analytics
export const initGA = () => {
  if (!ApiKeys.ANALYTICS_ID) return
  ReactGA.initialize(ApiKeys.ANALYTICS_ID)
}

// log a page view
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

// log an event
export const logEvent = (
  category = '',
  action = '',
  label = '',
  value = null
) => {
  if (category && action) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value,
      nonInteraction: true
    })
  }
}

// log an exception
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}
