import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: 40px;
    font-size: 0;
    line-height: 0;
    color: ${COLORS.white};

    &:hover {
      color: ${COLORS.primary};
    }

    &:first-child {
      margin-left: 0;
    }
  }

  @media ${DEVICES.mobile} {
    padding-bottom: 8px;
  }
`
