import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textSM } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const MultiImageAndTextBody = styled.div`
  padding: 20px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

export const MultiImageAndTextHeading = styled.h3`
  ${text2XL};
  text-transform: none;
  font-family: ${FONTS.medium};
  margin-bottom: 12px;
`

export const MultiImageAndTextContent = styled.div`
  color: ${COLORS.navi};
  margin-bottom: 20px;
`

export const MultiImageAndTextLink = styled.div`
  color: ${COLORS.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${COLORS.nature.base};
  padding: 12px 16px 16px;
  margin: 0 -16px;
  transition: all 0.25s ease-in;

  &:hover {
    color: ${COLORS.primary};
  }

  span {
    ${textSM};
    text-transform: uppercase;
    font-family: ${FONTS.medium};
    letter-spacing: 1px;
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }
`

export const MultiImageAndText = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0px 0px 36px 0px;
  background-color: ${COLORS.nature[95]};

  > a {
    line-height: 0;
  }

  ${({ noLink }) =>
    noLink &&
    css`
      border-radius: 0px 0px 60px 0px;

      ${MultiImageAndTextHeading} {
        margin-bottom: 20px;
      }

      ${MultiImageAndTextBody} {
        padding: 28px 24px;
      }

      ${MultiImageAndTextContent} {
        margin-bottom: 0;
      }
    `}

  ${({ noContent }) =>
    noContent &&
    css`
      ${MultiImageAndTextHeading} {
        margin-bottom: 20px;
      }

      ${MultiImageAndTextBody} {
        justify-content: space-between;
      }
    `}
`
