import { COLORS } from '@styles/colors'
import { FONTS, textBase } from '@styles/fonts'
import styled from 'styled-components'

export const AwardCard = styled.div`
  text-align: center;
`

export const AwardCardImage = styled.div`
  margin-bottom: 20px;

  img {
    width: 130px;
  }
`

export const AwardCardTitle = styled.h4`
  ${textBase};
  font-family: ${FONTS.medium};
  text-transform: unset;
  margin-bottom: 12px;
`

export const AwardCardDes = styled.p`
  color: ${COLORS.navi};
`
