import { UrlBuilder, titleCase } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getAutocompleteData = (params, properties = true) => {
  return apiCall(UrlBuilder(apis.AUTOCOMPLETE, params)).then((response) => {
    let formOptions = []
    if (response.autocomplete) {
      Object.keys(response.autocomplete).map((key) => {
        response.autocomplete[key].length > 0 &&
          response.autocomplete[key].map((option) => {
            if (key == 'properties' && properties) {
              option.type = key
              formOptions.push({
                value: JSON.stringify(option),
                label: option.address,
                slug: option.slug,
              })
            } else {
              formOptions.push({
                value: JSON.stringify({ value: option, type: key }),
                label: titleCase(option),
              })
            }
          })
      })
    }
    return formOptions
  })
}
