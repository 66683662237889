import * as S from './Spinner.styled'

const Spinner = ({ height, width }) => {
  return (
    <S.SpinnerWrap>
      <S.Spinner style={{ width, height }} />
    </S.SpinnerWrap>
  )
}

Spinner.defaultProps = {
  height: 48,
  width: 48,
}

export default Spinner
