import FilterGroup from '../FilterGroup'
import { Tag, Tags } from '../FiltersForm.styled'
import { useFormikContext } from 'formik'

const OPTIONS = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: '1+',
    value: 1,
  },
  {
    label: '2+',
    value: 2,
  },
  {
    label: '3+',
    value: 3,
  },
  {
    label: '4+',
    value: 4,
  },
  {
    label: '5+',
    value: 5,
  },
  {
    label: '10+',
    value: 10,
  },
  {
    label: '20+',
    value: 20,
  },
  {
    label: '30+',
    value: 30,
  },
  {
    label: '50+',
    value: 50,
  },
]

const NoOfCarspacesField = () => {
  const { values, setFieldValue } = useFormikContext()

  const onChange = (name, value) => {
    setFieldValue(name, value)
  }

  return (
    <FilterGroup title=''>
      <label>No. of Carspaces</label>
      <Tags>
        {OPTIONS.map((it) => (
          <Tag
            key={`parking__gte-${it.value}`}
            onClick={() => onChange('parking__gte', it.value)}
            active={(values.parking__gte ?? '') === it.value}
          >
            {it.label}
          </Tag>
        ))}
      </Tags>
    </FilterGroup>
  )
}

export default NoOfCarspacesField
