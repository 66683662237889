import { css } from 'styled-components'

export const FONTS = {
  regular: 'BrandonText-Regular',
  medium: 'BrandonText-Medium',
  bold: 'BrandonText-Bold',
}

export const textXS = css`
  font-size: 12px;
  line-height: 18px;
`

export const textSM = css`
  font-size: 14px;
  line-height: 20px;
`

export const textBase = css`
  font-size: 16px;
  line-height: 22px;
`

export const textLG = css`
  font-size: 18px;
  line-height: 26px;
`

export const textXL = css`
  font-size: 20px;
  line-height: 28px;
`

export const text2XL = css`
  font-size: 22px;
  line-height: 30px;
`

export const text3XL = css`
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 2px;
`

export const text4XL = css`
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 2.4px;
`

export const text5XL = css`
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 2.8px;
`
