import React from 'react'
import { Link } from '@routes'
import Image from '@global/Image'
import ArrowRightCircleFilled from '@icons/ArrowRightCircleFilled'
import * as S from './PostCard.styled'

const PostCard = (props) => {
  const { heading, imgUrl, link, content, call_to_action_link } = props
  const linkUrl = link?.href || link?.path || link?.url
  const linkLabel = link?.label
  const noContent = !content && link
  const noLink = content && !link

  return (
    <S.MultiImageAndText noContent={noContent} noLink={noLink}>
      {link ? (
        <Link route={linkUrl} passHref>
          <Image src={imgUrl} alt={heading} />
        </Link>
      ) : (
        <Image src={imgUrl} alt={heading} />
      )}
      <S.MultiImageAndTextBody>
        <div>
          <S.MultiImageAndTextHeading>
            {call_to_action_link?.path || linkUrl ? (
              <Link route={call_to_action_link?.path || linkUrl} passHref>
                {heading}
              </Link>
            ) : (
              heading
            )}
          </S.MultiImageAndTextHeading>
          {content && (
            <S.MultiImageAndTextContent>{content}</S.MultiImageAndTextContent>
          )}
        </div>
        {link && linkLabel && (
          <Link route={linkUrl} passHref>
            <S.MultiImageAndTextLink>
              <span>{linkLabel}</span>
              <ArrowRightCircleFilled />
            </S.MultiImageAndTextLink>
          </Link>
        )}
      </S.MultiImageAndTextBody>
    </S.MultiImageAndText>
  )
}

export default PostCard
