import Field from '@global/FormInput/Field'
import { Col, Row } from '@styles/global.styled'
import { useFormikContext } from 'formik'
import FilterGroup from '../FilterGroup'
import { maxPrice, maxRent, minPrice, minRent } from '@lib/searchFields'
import { LISTING_TYPE, PROPERTY_CLASS } from '@constants/property'

const PriceRangeFields = () => {
  const { values, setFieldValue } = useFormikContext()

  const minField =
    values.listing_type === LISTING_TYPE.LEASE ? minRent : minPrice
  const maxField =
    values.listing_type === LISTING_TYPE.LEASE ? maxRent : maxPrice

  return (
    <FilterGroup
      title='Price Range'
      noBorder={values.property_class === PROPERTY_CLASS.COMMERCIAL}
    >
      <Row gutter={18}>
        <Col xs={6} sm={6}>
          <Field
            name={minField.name}
            options={minField.options}
            label='Min. Price'
            type='select'
            value={values[minField.name] || minField.options[0].value}
            onChange={(value) => setFieldValue(minField.name, value)}
          />
        </Col>
        <Col xs={6} sm={6}>
          <Field
            name={maxField.name}
            options={maxField.options.filter(
              (it) => it.value > (values[minField.name] ?? 0) || it.value === ''
            )}
            label='Max. Price'
            type='select'
            value={values[maxField.name] || maxField.options[0].value}
            onChange={(value) => setFieldValue(maxField.name, value)}
          />
        </Col>
      </Row>
    </FilterGroup>
  )
}

export default PriceRangeFields
