import { useContext, useEffect, useState } from 'react'
import IconYoutubeFilled from '@icons/YoutubeFilled'
import IconLinkedinFilled from '@icons/LinkedinFilled'
import IconFacebookFilled from '@icons/FacebookFilled'
import IconInstagramFilled from '@icons/InstagramFilled'
import { SiteSettingsContext } from '@context/SiteSettingsContext'
import { Link } from '@routes'
import * as S from './SocialLinks.styled'

const SocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([])
  const settingContext = useContext(SiteSettingsContext)

  useEffect(() => {
    if (
      settingContext?.settings &&
      settingContext?.settings?.socialmediasettings
    ) {
      let links = []
      Object.keys(settingContext.settings.socialmediasettings).map((key) => {
        if (settingContext.settings.socialmediasettings[key]) {
          switch (key) {
            case 'facebook_url': {
              links.push({
                icon: <IconFacebookFilled />,
                url: settingContext.settings.socialmediasettings[key],
              })
              break
            }
            case 'linkedin_url': {
              links.push({
                icon: <IconLinkedinFilled />,
                url: settingContext.settings.socialmediasettings[key],
              })
              break
            }
            case 'instagram_handle': {
              links.push({
                icon: <IconInstagramFilled />,
                url: `https://www.instagram.com/${settingContext.settings.socialmediasettings[key]}/`,
              })
              break
            }
            case 'twitter_handle': {
              links.push({
                icon: <IconInstagramFilled />,
                url: `https://twitter.com/${settingContext.settings.socialmediasettings[key]}/`,
              })
              break
            }
            case 'youtube_url': {
              links.push({
                icon: <IconYoutubeFilled />,
                url: settingContext.settings.socialmediasettings[key],
              })
              break
            }
          }
        }
      })

      setSocialLinks(links)
    }
  }, [settingContext])

  return (
    <S.SocialLinks>
      {socialLinks.map((it) => (
        <Link route={it.url} key={it.url} target='_blank'>
          {it.icon}
        </Link>
      ))}
    </S.SocialLinks>
  )
}

export default SocialLinks
