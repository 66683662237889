import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

export const CarouselButtonStyle = css`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.secondary};
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s ease-in;

  &.slick-disabled {
    display: none;
  }

  &:before {
    display: none;
  }

  svg {
    position: relative;
  }

  &:hover {
    background-color: ${COLORS.primary};
  }
`

export const Carousel = styled.div`
  padding: 45px 0 35px;

  .slick-list {
    margin-right: -26px;
    display: flex;
  }

  .slick-track {
    display: flex;
    flex: 1;
  }

  .slick-slide {
    margin-right: 26px;
  }

  .slick-slider {
    .slick-prev {
      left: -18px;
    }

    .slick-next {
      right: -18px;
    }
  }

  .slick-dots {
    display: flex;
    align-items: center;
    background-color: ${COLORS.nature.base};
    height: 2px;
    margin-top: 54px;
    position: relative;
    bottom: auto;

    @media ${DEVICES.mobile} {
      margin-top: 20px;
    }

    > li {
      border-radius: 10px;
      height: 2px;
      transition: all 0.25s;
      margin: 0;
      flex: 1;
      width: 100%;

      button {
        height: 2px;
        transition: all 0.25s;
        width: 100%;

        &::before {
          display: none;
        }
      }

      &:hover,
      &.slick-active {
        background-color: ${COLORS.primary};
        cursor: pointer;
      }
    }
  }

  @media ${DEVICES.mobile} {
    padding-top: 30px;
  }

  .slick-prev,
  .slick-next {
    ${CarouselButtonStyle};
  }

  .slick-next {
    svg {
      right: -1px;
    }
  }

  .slick-prev {
    svg {
      left: -1px;
    }
  }
`

export const CarouselNext = styled.div``
export const CarouselPev = styled.div``
export const CarouselIndicators = styled.ul``
