import SVGIcon from '../../global/icon/svgIcon'

const ThreeColumnInfo = ({ title, columns }) => {
  return (
    <div className='o-wrapper'>
      <div className='c-three-column-instructional-box'>
        <div className='c-three-column-instructional-box__hd'>
          <h2>{title}</h2>
        </div>

        <div className='c-three-column-instructional-box__bd'>
          <div className='o-layout o-layout--large'>
            {columns &&
              columns.length > 0 &&
              columns.map((column, i) => (
                <div className='o-layout__item u-1/3@tablet' key={i}>
                  <SVGIcon name={column.icon} />
                  <h4 className='u-h4'>{column.content}</h4>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

ThreeColumnInfo.defaultProps = {
  title: 'Setting up your Property Alert',
  columns: [
    {
      icon: 'icon-propertyalert-1',
      content:
        'Register your details to create your personal Barry Plant account.'
    },
    {
      icon: 'icon-propertyalert-2',
      content:
        "Set up your Property Alert preferences by selecting the kinds of properties you're looking for."
    },
    {
      icon: 'icon-propertyalert-3',
      content:
        'Receive proprety alerts straight to your inbox, create wishlists, view saved properties and more.'
    }
  ]
}

export default ThreeColumnInfo
