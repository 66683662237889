import { stripTags, truncateText } from '@lib/helpers'
import Testimonial from './Testimonial'
import GridItems from '../../GridItems'
import { COLORS } from '@styles/colors'

const SitewideTestimonials = ({ testimonials, title }) => {
  return (
    <GridItems
      title={title}
      items={testimonials}
      itemSize={4}
      minVisibleDesktopItems={3}
      minVisibleMobileItems={2}
      itemMarginBottom={48}
      renderItem={(testimonial) => <Testimonial testimonial={testimonial} />}
      style={{ backgroundColor: COLORS.sand, padding: '80px 0' }}
    />
  )
}

SitewideTestimonials.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return
  }

  const props = {
    title: data.value.heading,
    testimonials: [],
  }

  data.value.testimonials &&
    data.value.testimonials.map((t) => {
      // The testimonial content returns from the API with <p> tags
      let it = t
      if (t.testimonials) {
        it = t.testimonials
      }
      if (it.id) {
        const strippedComments = truncateText(
          stripTags(it.comments),
          300,
          ` ...${
            it.get_absolute_url
              ? `<a href="${it.get_absolute_url}">view more</>`
              : ''
          }`
        )
        let testimonial = {
          ...it,
          copy: strippedComments,
          name: it.name != 'No Text' ? it.name : '', // The API is responding with "No Text"
        }
        if (it.image_thumbnail) {
          testimonial.image = it.image_thumbnail
        }
        if (it.headline) {
          testimonial.headline = t.headline
        }
        if (t.rating) {
          testimonial.rating = t.rating
        }
        props.testimonials.push(testimonial)
      }
    })

  if (props.testimonials.length > 0) {
    return props
  }
}

export default SitewideTestimonials
