import PropertySearchFrom from '@global/PropertySearchFrom'
import Hero from '../Hero'

const HeaderPanel = ({ value }) => {
  return (
    <Hero
      bgVideoUrl={value.header_video_url}
      title={value.header_title}
      bgImageUrl={value.header_image}
      description={value.header_description}
      searchForm={<PropertySearchFrom />}
      fullscreen
    />
  )
}

export default HeaderPanel
