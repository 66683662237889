import Button from '@global/Button'
import { Router } from '@routes'
import * as S from './NoResults.styled'

const NoResults = ({ title, description, onClearSearch }) => {
  const clearSearch = () => {
    if (onClearSearch) {
      onClearSearch()
    } else {
      const { state } = Router.router
      Router.pushRoute(state.asPath.split('?')[0])
    }
  }

  return (
    <S.NoResults>
      <h3>{title}</h3>
      <p>{description}</p>
      <Button color='primary' border onClick={clearSearch}>
        Clear search
      </Button>
    </S.NoResults>
  )
}

export default NoResults
