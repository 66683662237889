// Mapping of the API block names to the components.
import TwoColumnImageText from './TwoColumnImageText'
import HeroSection from './Hero'
import CalloutStatistics from './CalloutStatistics'
import ColumnEqualCta from './TwinEqualCTA'
import SignUpBanner from '../SignUpBanner'
import OurResults from './OurResults'
import SitewideTestimonial from './SitewideTestimonials'
import SiteRatedTestimonials from './RatedTestimonials'
import ListingPreview from './ListingPreview'
import ImgText from './ImageAndText'
import FullWidthText from './FullWidthText'
import TakeMeToNavigation from '../TakeMeToNavigation'
import ThreeColumnInfo from '../three-column-info/ThreeColumnInfo'
import CheckListBox from '../checklist/CheckListBox'
import OurTeam from './OurTeam'
import VideoFeature from './VideoFeature'
import IconSlider from '../../layouts/icon-slider/IconSlider'
import BarryplantTVBlock from '../../layouts/barryplant-tv-block/BarryplantTVBlock'
import ResourceTile from '../../layouts/resource-tile/ResourceTile'
import FeaturedSuburbs from '../FeaturedSuburbs'
import SmallButtonsLarge from '../small-buttons/SmallButtonsLarge'
import BlogParagraph from '../../blog/BlogParagraph'
import BlogDropQuote from '../../blog/BlogDropQuote'
import ImageGallery from './ImageGallery'
import FeaturedImage from 'components/global/Blocks/FeaturedImage'
import BlogPostSubTitle from '../../blog/BlogPostSubTitle'
import HeroSearch from './HeroSearch'
import ProjectPreview from './ProjectPreview'
import DynamicForm from '../Form/DynamicForm'
import CareersInformation from '../../Careers/CareersInformation'
import ServicedByTitles from '../../SuburbProfile/ServicedBy/ServicedByTitles'
import ServicedBy from '../../SuburbProfile/ServicedBy'
import LatestVideo from 'components/global/LatestVideo'
import IframeWrapper from './IframeWrapper'
import AdBanner from '../AdBanner'
import CTADownloadBlock from '../cta-download-block/CTADownloadBlock'
import Investors from '../investors/Investors'
import ImageTextCTA from '@global/Blocks/ImageTextCTA'
import Awards from './Awards'
import Text from './Text'

// only import map on client side
import dynamic from 'next/dynamic'
import ResultsBlock from '../results-block/ResultsBlock'
import MultiImageAndText from '@global/Blocks/MultiImageAndText'
import NewsList from '@global/Blocks/NewsList'
import HeaderPanel from './HeaderPanel'
import Video from '@global/Video'
import Accordions from './Accordions'
import CarouselTwinEqualCTA from './CarouselTwinEqualCTA'
import TwoColumnText from '@global/TwoColumnText'
import FeaturedArticleHero from './FeaturedArticleHero'
import ArticlesPanel from 'components/global/Blocks/ArticlesPanel'
import VideosList from '@global/Blocks/VideoList'
import TestimonialCarousel from '@global/Blocks/TestimonalCarousel'
import Divider from './Divider'
import LatestJobs from './LatestJobs'
import OfficeGroupGird from '@global/Blocks/OfficeGroupGird'

const DynamicMapNetworkEmbeddedMap = dynamic(
  () => import('../../layouts/network-embedded-map/NetworkEmbeddedMap'),
  {
    ssr: false,
  }
)

const SuburbOffice = dynamic(() => import('../SuburbOffice'), {
  ssr: true,
})

const AppraisalFormBlock = dynamic(() => import('./AppraisalFormBlock'), {
  ssr: false,
})

export default {
  // Connects the API block "type" field to the component.
  hero: HeroSection,
  twin_image_and_text: TwoColumnImageText,
  stat_circle: CalloutStatistics,
  twin_equal_cta: ColumnEqualCta,
  lifestyle_categories: ColumnEqualCta,
  email_signup: SignUpBanner,
  our_results: OurResults,
  testimonials: TestimonialCarousel,
  rated_testimonials: SiteRatedTestimonials,
  centered_image_and_text: ImgText,
  video_and_text: VideosList,
  recent_properties: ListingPreview,
  single_type_recent_properties: ListingPreview,
  image_and_text: ImgText,
  our_network: DynamicMapNetworkEmbeddedMap,
  text: Text,
  ad_banner: AdBanner,
  paragraph: BlogParagraph,
  take_me_to: TakeMeToNavigation,
  three_column_instructions: ThreeColumnInfo,
  checklist: CheckListBox,
  our_team: OurTeam,
  full_width_video: VideoFeature,
  featured_properties: ListingPreview,
  awards: IconSlider,
  full_width_text: FullWidthText,
  latest_news: NewsList,
  careers: LatestJobs,
  resources_panel: ResourceTile,
  bp_tv_panel: BarryplantTVBlock,
  featured_suburbs: FeaturedSuburbs,
  link_panels: SmallButtonsLarge,
  subtitle: BlogPostSubTitle,
  drop_quote: BlogDropQuote,
  blog_image_and_text: FeaturedImage,
  image_gallery: ImageGallery,
  hero_call_to_action: ImageTextCTA,
  calculators_section: SmallButtonsLarge,
  community_causes: IconSlider,
  accordian: Accordions,
  hero_search: HeroSearch,
  developers: IconSlider,
  projects_slider: ProjectPreview,
  property_search_form: HeroSearch,
  search: HeroSearch,
  form: DynamicForm,
  suburbs_serviced: SuburbOffice,
  careers_info: CareersInformation,
  servicing_offices: ServicedByTitles,
  key_serviced_office: ServicedBy,
  full_screen_hero: HeroSection,
  agent_testimonials: SitewideTestimonial,
  appraisal_form: AppraisalFormBlock,
  iframe: IframeWrapper,
  iframe_block: IframeWrapper,
  latest_videos: LatestVideo,
  CTA_download_block: CTADownloadBlock,
  results_block: ResultsBlock,
  multi_image_and_text: MultiImageAndText,
  investors: Investors,
  image_and_text_cta: ImageTextCTA,
  awards_block: Awards,
  header_panel: HeaderPanel,
  video_block: Video,
  featured_video: Video,
  carousel_twin_equal_cta: CarouselTwinEqualCTA,
  two_column_text: TwoColumnText,
  article_hero: FeaturedArticleHero,
  articles_panel: ArticlesPanel,
  divider_block: Divider,
  office_group_grid: OfficeGroupGird,
}
