import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, text2XL, textLG } from '@styles/fonts'

export const FavouriteModal = styled.div`
  position: relative;
  text-align: center;
  padding: 40px 35px 32px 35px;
  background: ${COLORS.nature[95]};

  @media ${DEVICES.mobile} {
    padding: 28px 20px 40px 20px;
  }

  svg {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
  }
`

export const FavouriteModalContent = styled.p`
  ${text2XL};
  margin-bottom: 32px;
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};

  @media ${DEVICES.mobile} {
    ${textLG};
    font-family: ${FONTS.regular};
  }
`

export const FavouriteModalButtons = styled.div`
  button {
    min-width: 120px;
    margin: 0 10px;
  }
`
