const IconCarouselArrowLeft = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.53275 16.6663L3.37891 9.99967L9.53275 3.33301L10.516 4.24065L5.81286 9.33575L16.6666 9.33575L16.6666 10.6739L5.82237 10.6739L10.516 15.7587L9.53275 16.6663Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconCarouselArrowLeft
