import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, text2XL, text3XL } from '@styles/fonts'

export const OfficeGroupGirdWrap = styled.div`
  width: 100%;
  
  .col {
    margin-bottom: 15px;
  }
  
  @media ${DEVICES.xl} {
    width: 90%;
  }
`

export const OfficeGroupGirdTitle = styled.h3`
  ${text3XL};
  font-family: ${FONTS.medium};
  font-weight: 420;
  text-transform: initial;
  margin-bottom: 20px;
  
  @media ${DEVICES.mobile} {
    ${text2XL};
  }
`
