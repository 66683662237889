import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const ImageTextCTA = styled.div`
  position: relative;
  padding: 64px 45px 60px;
  background: url(${({ bgImage }) => bgImage}) no-repeat top center;
  background-size: cover;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(0, 23, 135, 0.8) 8.07%,
      rgba(0, 0, 0, 0) 50%
    );

    @media ${DEVICES.mobile} {
      background: linear-gradient(
        0deg,
        rgba(0, 23, 135, 0.8) 26%,
        rgba(0, 0, 0, 0) 90%
      );
    }
  }

  @media ${DEVICES.mobile} {
    padding: 294px 22px 48px 16px;
  }
`

export const CTAContent = styled.div`
  position: relative;
  text-align: start;
  z-index: 2;
  max-width: 400px;
  margin-bottom: 80px;

  @media ${DEVICES.mobile} {
    max-width: 100%;
    margin-bottom: 32px;
  }
`

export const CTAContentTitle = styled.h3`
  color: ${COLORS.white};
  line-height: 38px;
  margin-bottom: 23px;

  @media ${DEVICES.mobile} {
    font-size: 22px;
    letter-spacing: 1.6px;
    margin-bottom: 28px;
  }
`

export const CTAContentDes = styled.p`
  max-width: 300px;
  line-height: 22px;
  color: ${COLORS.white};
`

export const CTALinks = styled.div`
  margin-left: auto;
  position: relative;
  z-index: 2;
  background: ${COLORS.nature[95]};
  max-width: 430px;
  padding: 32px;

  > ul li {
    list-style-type: none;
    margin-bottom: 12px;

    @media ${DEVICES.mobile} {
      margin-bottom: 8px;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${COLORS.secondary};
      font-family: ${FONTS.medium};
      letter-spacing: 1.4px;
      text-transform: uppercase;

      &:hover {
        color: ${COLORS.primary};
      }

      @media ${DEVICES.mobile} {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${DEVICES.mobile} {
    max-width: 100%;
    padding: 24px 20px;
  }
`

export const CTALinkDes = styled.p`
  display: block;
  color: ${COLORS.navi};
  margin-bottom: 32px;
`
