import SVGIcon from '../icon/svgIcon'

const CheckListBox = ({ items }) => {
  return (
    <div className='c-checklist-block'>
      <div className='o-wrapper'>
        <ul className='o-layout c-checklist-block__list'>
          {items &&
            items.length > 0 &&
            items.map((item, i) => (
              <li
                className='checklist-item o-layout__item u-1/2@tablet'
                key={i}
              >
                <span className='checklist-icon'>
                  <SVGIcon name='icon-checkmark' />
                </span>
                <div className='checklist-text'>{item}</div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

CheckListBox.defaultProps = {
  items: [
    'Post Office (forward your mail)',
    'Gas supplier',
    'Electricity supplier',
    'Schools/Childcare',
    'Insurance companies',
    'Australian Taxation Office',
    'Electoral Commission',
    'Medicare',
    'Heath Insurance',
    'Doctor',
    'Dentist',
    'Bank/s and credit card companies',
    'Solicitor',
    'Superannuation',
    'Newspaper and magazine subscriptions',
    'Phone company',
    'Local library',
    'Video store',
    'Gym / sporting clubs',
    'and of course - friends and family'
  ]
}

CheckListBox.ApiBlockToProps = data => {
  if (!data.value || !data.value.items) {
    return
  }

  return {
    items: data.value.items
  }
}

export default CheckListBox
