import { Router } from '@routes'
import dynamic from 'next/dynamic'

const Select = dynamic(() => import('@global/FormInput/Select'), { ssr: false })

const TakeMeToNavigation = ({ field }) => (
  <Select
    {...field}
    value={value}
    onChange={(e) => {
      Router.pushRoute(e.target.value)
      window.scrollTo(0, 0)
    }}
  />
)

TakeMeToNavigation.defaultProps = {
  field: {
    type: 'select',
    label: 'Take me to:',
    options: [
      {
        value: '/',
        label: 'Select from list',
      },
      {
        value: '/',
        label: 'Office',
      },
      {
        value: '/',
        label: 'Home',
      },
    ],
  },
}

TakeMeToNavigation.ApiBlockToProps = (data) => {
  // EXPECTED
  // {
  //   type: "take_me_to",
  //   links: [
  //     {slug: "slug", title: "Title", path: "/"}
  //   ]
  // }
  let componentProps = {
    label: data.value.title,
    field: {
      itemClass: 'c-form__row c-form__row--select select-take-me-to',
      type: 'select',
      label: 'Select from list',
      fieldSize: 'u-10/12@tablet u-width-55@xwide',
      options: [
        {
          label: 'Select from list',
          value: '#',
        },
      ],
    },
  }
  data.value.links &&
    data.value.links.map((link) => {
      componentProps.field.options.push({
        value: link.path,
        label: link.title,
      })
    })
  return componentProps
}

export default TakeMeToNavigation
