const Accordion = ({ accordionGroup }) => (
  <div className='c-accordion' data-accordion-group>
    {accordionGroup?.length > 0 &&
      accordionGroup.map((accordion, key) => (
        <div
          className='c-accordion__row'
          data-accordion
          data-speed='400'
          data-single-open='1'
          key={`accordion-${key}`}
        >
          <div className='c-accordion__row-control' data-control>
            <h5>{accordion.title}</h5>
          </div>
          <div
            className='c-accordion__row-content'
            data-content
            dangerouslySetInnerHTML={{ __html: accordion.content }}
          />
        </div>
      ))}
  </div>
)
Accordion.defaultProps = {
  accordionGroup: [],
}
export default Accordion
