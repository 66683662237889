import { Col, Container, Row } from '@styles/global.styled'
import StatisticsCircle from './StatisticsCircle'

const CalloutStatistics = ({ statistics }) => (
  <Container>
    <Row gutter={16}>
      {statistics?.length > 0 &&
        statistics.map((statistic, i) => (
          <Col md={4} key={`statistic-${i}`}>
            <StatisticsCircle statistic={statistic} />
          </Col>
        ))}
    </Row>
  </Container>
)

CalloutStatistics.defaultProps = {
  statistics: [],
}

CalloutStatistics.ApiBlockToProps = (data) => {
  const statistics = []
  data?.value?.stats &&
    data.value.stats.map((circle) => {
      statistics.push({
        label: circle.subtitle,
        value: circle.value,
        number: parseInt(circle.value.replace(/\D+/g, '')),
      })
    })

  return { statistics }
}

export default CalloutStatistics
