import { createContext, useContext, useState } from 'react'
import { getOfficeMenus } from '../lib/api-services/cmsPageService'

export const OfficeSiteContext = createContext()

export const OfficeSiteProvider = ({ children }) => {
  const defaultState = {
    officeId: null,
    officeName: null,
    officePath: null,
    searchSettings: null,
    menus: null,
  }
  const [state, setState] = useState(defaultState)

  const reset = () => {
    setState(defaultState)
  }

  const enableSite = async (
    officeId,
    officeName,
    officePath,
    searchSettings
  ) => {
    const menuData = await getOfficeMenus(officeId)
    await setState({
      officeId: officeId,
      officeName: officeName,
      officePath: officePath,
      searchSettings: searchSettings,
      menus:
        menuData && menuData.length ? extractOfficeMegaNav(menuData) : null,
    })
  }

  return (
    <OfficeSiteContext.Provider
      value={{
        currentSite: state,
        resetSite: reset,
        enableSite: enableSite,
      }}
    >
      {children}
    </OfficeSiteContext.Provider>
  )
}

const extractOfficeMegaNav = (menuData) => {
  // Accepts the raw Office menu API data and cleans it per the expected
  // defaultProps on the megaNav object
  let megaNavMenus = []
  const rawMegaNavMenus = menuData.filter((e) => {
    return e.handle.startsWith('mega_nav')
  })
  rawMegaNavMenus &&
    rawMegaNavMenus.length > 0 &&
    rawMegaNavMenus.map((megaNavSection) => {
      let sectionData = {}
      megaNavSection.menu_items &&
        megaNavSection.menu_items.map(({ value }, i) => {
          const label = value.link_text || value.label
          const url = value.link_page
            ? value.link_page.path
            : value.link_url + value.url_append

          if (i == 0) {
            // First item is the heading
            sectionData = {
              id: value.link_text || value.label,
              label: label,
              url: url,
              subNavItems: [],
            }
          } else {
            sectionData.subNavItems.push({
              label: label,
              url: url,
            })
          }
        })
      megaNavMenus.push(sectionData)
    })
  return megaNavMenus
}

export const useOfficeSiteContext = () => useContext(OfficeSiteContext)
