import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'

export const OffMarketOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 71, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: ${COLORS.white};

  p {
    margin-bottom: 14px;
    font-size: 22px;
    line-height: 30px;
    font-family: ${FONTS.bold};
    text-transform: uppercase;
    letter-spacing: 1.6px;
  }
`
