import cookie from 'js-cookie'

export const setCookie = (key, value, expires) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: expires || 1,
      path: '/',
    })
  }
}

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    })
  }
}

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req)
}

const getCookieFromBrowser = (key) => {
  return cookie.get(key)
}

const getCookieFromServer = (key, req) => {
  if (!req) return null
  if (Object.prototype.hasOwnProperty.call(req, 'req')) {
    req = req.req
  }

  if (!req.headers || !req.headers.cookie) {
    return undefined
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`))
  if (!rawCookie) {
    return undefined
  }

  let [cookieName, ...cookieData] = rawCookie.split('=')
  if (cookieData && cookieData.length > 0) {
    return cookieData.join('=')
  }
  return undefined
}

export const JWT_COOKIE = 'jwt'
export const SEARCHPAGE_COOKIE = 'search'
export const POPUP_COOKIE = 'popup'
export const ACTIVEPIPE_COOKIE = 'activepipe'
export const USERID_COOKIE = 'userid'
