export const COLORS = {
  primary: '#F92044',
  secondary: '#001FB9',
  ultramarine: '#0C0C5B',
  navi: '#0A1965',
  sand: '#EDE8DE',
  nature: {
    base: '#EDE8DE',
    95: '#F5F2EB',
    80: '#DBD7CD',
  },
  cobalt: {
    70: '#006EFF',
  },
  grey: {
    10: '#E2E2E5',
    20: '#C9C9CC',
    40: '#979799',
    50: '#EFEFF2',
    60: '#656566',
  },
  white: '#ffffff',
  // Other color
  primaryLight: '#083757', // TODO
  secondaryLight: '#d3130d', // TODO
  error: '#F92044',
  greyLight: '#E9E9E9',
}
