const CarouselNextArrow = props => {
  return (
    <svg
      width='9'
      height='17'
      viewBox='0 0 9 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.43164 8.66357L1.64719 16.6636L0.431641 15.2302L6.02881 8.66357L0.459908 2.09691L1.67546 0.663574L8.43164 8.66357Z'
        fill='white'
      />
    </svg>
  )
}

export default CarouselNextArrow
