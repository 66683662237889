import GridItems from '../../GridItems'
import Award from './Award'

const Awards = ({ awards, title }) => {
  return (
    <GridItems
      title={title}
      items={awards}
      itemSize={3}
      minVisibleDesktopItems={4}
      minVisibleMobileItems={2}
      itemMarginBottom={32}
      renderItem={(award) => <Award award={award} />}
    />
  )
}

Awards.ApiBlockToProps = (data) => {
  const rawData = data.value
  return {
    title: rawData?.heading || 'Awards & Recognition',
    awards: rawData?.awards ?? [],
  }
}

export default Awards
