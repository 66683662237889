import styled from 'styled-components'
import { Link } from '@routes'

export const Social = styled.div`
  h4 {
    margin-bottom: 20px;
  }
`

export const SocialLinks = styled.div`
  margin-bottom: -16px;
  display: flex;
`

export const SocialLink = styled(Link)`
  font-size: 0;
  line-height: 0;
  margin-right: 28px;
  margin-bottom: 16px;
  display: inline-block;

  > svg {
    width: 18px;
    height: 18px;
  }
`
