import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

const BACKGROUND_COLOR = {
  blue: COLORS.secondary,
  red: COLORS.primary,
  grey: COLORS.nature[95],
}

const SPACE = {
  large: 120,
  meidum: 100,
  small: 64,
}

const SPACE_MOBILE = {
  large: 72,
  medium: 72,
  small: 40,
}

export const Divider = styled.div`
  padding-bottom: 100px;

  @media ${DEVICES.mobile} {
    padding-bottom: 72px;
  }

  ${({ size }) =>
    size &&
    css`
      padding-bottom: ${SPACE[size]}px;

      @media ${DEVICES.mobile} {
        padding-bottom: ${SPACE_MOBILE[size]}px;
      }
    `}

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${BACKGROUND_COLOR[bgColor]};
    `}
`
