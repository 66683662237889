import React, { useContext, useEffect, useState } from 'react'

export const SiteSettingsContext = React.createContext()

export const SiteSettingsContextProvider = ({ siteSettings, children }) => {
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    setSettings(siteSettings)
  }, [siteSettings])

  return (
    <SiteSettingsContext.Provider
      value={{
        settings,
      }}
    >
      {children}
    </SiteSettingsContext.Provider>
  )
}

export const useSiteSettingsContext = () => useContext(SiteSettingsContext)
