import ReactSelect, { components } from 'react-select'
import IconSelect2ArrowDown from '@icons/Select2ArrowDown'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconSelect2ArrowDown />
    </components.DropdownIndicator>
  )
}

const Select = ({
  options,
  defaultValue,
  onChange,
  placeholder,
  ...restProps
}) => (
  <ReactSelect
    {...restProps}
    isMulti={restProps.multiple}
    defaultValue={defaultValue}
    placeholder={placeholder ?? 'Select'}
    className='main-select-container'
    classNamePrefix='react-select'
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator: DropdownIndicator,
    }}
    options={options}
    onChange={onChange}
  />
)

export default Select
