import JobResults from '@components/Careers/JobResults'
import { getWagtailPageBySlug } from '@lib/api-services/cmsPageService'
import { truncateText, stripTags } from '@lib/helpers'
import { Router } from '@routes'
import { Container } from '@styles/global.styled'

const LatestJobs = ({ title, jobs, totalJobs, itemPerPage, searchFields }) => {
  if (!jobs?.length) return null

  const getJobs = async (params) => {
    const res = await getWagtailPageBySlug(Router.router.state.query.slug, {
      ...params,
      job_page: params.page,
    })
    const careers = res.pageData.content_blocks.find(
      (it) => it.type === 'careers'
    )?.value

    const data = {
      count: careers?.total_jobs,
      results: careers?.latest_jobs,
    }

    return data
  }

  return (
    <Container>
      <JobResults
        title={title}
        itemPerPage={itemPerPage}
        jobs={{ count: totalJobs, results: jobs }}
        searchFields={searchFields}
        apiCall={getJobs}
      />
    </Container>
  )
}

LatestJobs.defaultProps = {
  title: 'Latest Jobs',
  vacancies: [],
}

LatestJobs.ApiBlockToProps = (data, type) => {
  let searchFields = []
  if (data.value.offices) {
    searchFields.push({
      type: 'select',
      name: 'office',
      options: data.value.offices.map((it) => ({
        label: it.name || 'All Offices',
        value: it.id || '',
      })),
    })
  }

  if (data.value.departments) {
    searchFields.push({
      type: 'select',
      name: 'department',
      options: data.value.departments.map((it) => ({
        label: it.name || 'All Departments',
        value: it.id || '',
      })),
    })
  }

  return {
    title: data.value.title,
    itemPerPage: data.value.number_to_show,
    totalJobs: data.value.total_jobs,
    rightLink: {
      label: data.value.call_to_action_text,
      url:
        data.value.call_to_acttion_url ||
        (data.value.call_to_action_link && data.value.call_to_action_link.path),
    },
    jobs: data.value.latest_jobs.map((item) => {
      return {
        name: item.job_name,
        location: item.location,
        type: item.job_type,
        description: truncateText(stripTags(item.description), 100, '…'), //Strip html and truncate text to 100 characters
        link: item.path,
      }
    }),
    searchFields,
  }
}
export default LatestJobs
