import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import VideoFeature from '@global/Blocks/VideoFeature'
import CarouselNextArrow from '@icons/CarouselNextArrow'
import CarouselPrevArrow from '@icons/CarouselPrevArrow'
import * as S from './VideosList.styled'

const NextArrow = ({ onClick, className }) => {
  return (
    <S.CarouselNext onClick={onClick} className={className}>
      <CarouselNextArrow />
    </S.CarouselNext>
  )
}

const PrevArrow = ({ onClick, className }) => {
  return (
    <S.CarouselPrev onClick={onClick} className={className}>
      <CarouselPrevArrow />
    </S.CarouselPrev>
  )
}

const VideosList = ({ words, items, link }) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: true,
    focusOnSelect: false,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <S.CarouselIndicators>{dots}</S.CarouselIndicators>,
  }

  return (
    <>
      {items && (
        <S.VideosListWrap>
          {items.length === 1 ? (
            <S.VideoCardWrap>
              <VideoFeature value={items[0]} />
            </S.VideoCardWrap>
          ) : (
            <S.Carousel>
              <Slider {...settings}>
                {items.map((item, index) => {
                  return (
                    <S.VideoCardWrap key={index}>
                      <VideoFeature
                        value={item}
                        positionVideo={item?.image_location}
                      />
                    </S.VideoCardWrap>
                  )
                })}
              </Slider>
            </S.Carousel>
          )}
        </S.VideosListWrap>
      )}
    </>
  )
}

VideosList.defaultProps = {
  items: [],
}

VideosList.ApiBlockToProps = (data) => {
  return { items: data.value.videos }
}

export default VideosList
