import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const OfficesList = styled.div`
  padding: 100px 42px;
  position: relative;
  z-index: 11;
  background-color: ${COLORS.nature[95]};

  @media ${DEVICES.tablet} {
    padding: 70px 16px;
  }
`

export const OfficesListInner = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const OfficesListTitle = styled.h4`
  text-transform: uppercase;
  color: ${COLORS.secondary};
  font-size: 22px;
  font-family: ${FONTS.bold};
  line-height: 30px;
  letter-spacing: 1.6px;
  margin-bottom: 32px;
`

export const OfficesListLinks = styled.div`
  margin-bottom: -6px;

  a {
    color: ${COLORS.navi};
    margin-bottom: 6px;
    font-size: 14px;
    display: inline-block;

    &:hover {
      color: ${COLORS.primary};
      text-decoration: underline;
    }
  }
`
