const IconLinkedinFilled = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.6049 0C0.718538 0 0 0.718537 0 1.6049V14.3951C0 15.2815 0.718537 16 1.6049 16H14.3951C15.2815 16 16 15.2815 16 14.3951V1.6049C16 0.718538 15.2815 0 14.3951 0H1.6049ZM4.07009 5.28163H4.05557C3.32987 5.28163 2.85938 4.74753 2.85938 4.07117C2.85938 3.38099 3.34378 2.85742 4.08399 2.85742C4.8236 2.85742 5.27838 3.37967 5.29289 4.0692C5.29289 4.74555 4.82361 5.28163 4.07009 5.28163ZM3.11842 6.1552H5.12317V13.142H3.11842V6.1552ZM10.8716 13.1432H13.1449V9.25765C13.1449 7.09423 12.0255 6.08859 10.6007 6.08859C9.45226 6.08859 8.73261 6.77285 8.59956 7.25229V6.15576H6.34747C6.3765 6.73794 6.34747 13.1432 6.34747 13.1432H8.59956V9.36236L8.5993 9.28689C8.59835 9.10223 8.59743 8.92358 8.64855 8.79138C8.80336 8.37055 9.13598 7.93523 9.73407 7.93523C10.516 7.93523 10.8716 8.58063 10.8716 9.527V13.1432Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconLinkedinFilled
