import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled from 'styled-components'

export const ListingSaleInfo = styled.div`
  color: ${COLORS.primary};

  strong {
    text-transform: uppercase;
    margin-right: 8px;
    letter-spacing: 1px;
  }
`
