import React from 'react'
import Blocks from '../../components/global/Blocks'
import { domain } from '../../lib/variables'

export const componentFromBlockType = (blockType) => {
  // Takes the block "type" value from the API and returns the component
  return Blocks[blockType] != undefined && Blocks[blockType].component
    ? Blocks[blockType].component
    : React.Component
}

export const cleanBlockData = (blockType, data) => {
  // Takes the block type and the API data and returns it cleaned as per the component propTypes/defaultProps format
  if (Blocks[blockType] && Blocks[blockType].dataFunction) {
    return Blocks[blockType].dataFunction(data)
  }
  return data
}

export const WagtailMetaToNextSEO = (pageData, meta) => {
  // Converts the "meta" from the API into an NextSEO object
  let seo = {}
  if (!meta) {
    return seo
  }
  seo.title =
    meta.meta_title && meta.meta_title != '' ? meta.meta_title : pageData.title

  if (meta.description || meta.meta_description) {
    seo.description = meta.description || meta.meta_description
  }

  if (meta.canonical) {
    seo.canonical = meta.canonical
  } else if (meta.path) {
    seo.canonical = `${domain}${meta.path}`
  }

  if (
    meta.og_title ||
    meta.og_type ||
    meta.og_url ||
    meta.og_img ||
    meta.og_description
  ) {
    let openGraph = {}
    openGraph.url = meta.og_url ? meta.og_url : ''
    openGraph.type = meta.og_type ? meta.og_type : ''
    openGraph.title = meta.og_title ? meta.og_title : ''
    ;(openGraph.description = meta.og_description
      ? meta.og_description
      : seo.description),
      (openGraph.images = meta.og_img ? [{ url: meta.og_img }] : [])

    seo.openGraph = openGraph
  }

  if (meta.twitter_card || meta.twitter_site || meta.twitter_creator) {
    let twitter = {}
    twitter.cardType = meta.twitter_card ? meta.twitter_card : null
    twitter.site = meta.twitter_site ? meta.twitter_site : null
    twitter.handle = meta.twitter_creator ? meta.twitter_creator : null
    seo.twitter = twitter
  }

  return seo
}

export const flatMenusToNav = (flatMenus) => {
  // Takes the response from getFlatMenus and returns the various
  // context variables used in _app.jsx
  let footerMenus = []
  let accountMenus = []
  let megaNavMenus = []
  let footerMainMenus = []
  if (flatMenus.items && flatMenus.items.length > 0) {
    footerMenus = flatMenus.items.find((e) => {
      return e.handle === 'footer'
    })
    footerMenus =
      footerMenus &&
      footerMenus.menu_items.map((mn) => {
        return {
          label: mn.link_text || mn.label,
          url: mn.url,
        }
      })

    const rawFooterMainMenus = flatMenus.items.filter((e) => {
      return e.handle.startsWith('footer_')
    })

    rawFooterMainMenus?.length > 0 &&
      rawFooterMainMenus.map((it) => {
        let sectionData = {
          heading: it.title,
          menu: [],
        }
        it.menu_items?.length > 0 &&
          it.menu_items.map((item) => {
            sectionData.menu.push({
              label: item.link_text || item.label,
              url: item.url,
            })
          })
        footerMainMenus.push(sectionData)
      })

    accountMenus = flatMenus.items.find((e) => {
      return e.handle === 'header_alerts'
    })
    accountMenus =
      accountMenus &&
      accountMenus.menu_items.map((mn) => {
        return {
          label: mn.link_text || mn.label,
          url: mn.url,
        }
      })

    const rawMegaNavMenus = flatMenus.items.filter((e) => {
      return e.handle.startsWith('mega_nav')
    })

    rawMegaNavMenus &&
      rawMegaNavMenus.length > 0 &&
      rawMegaNavMenus.map((megaNavSection) => {
        let sectionData = {}
        megaNavSection.menu_items &&
          megaNavSection.menu_items.map((item, i) => {
            if (i == 0) {
              // First item is the heading
              sectionData = {
                id: item.label,
                label: item.link_text || item.label,
                url: item.url,
                subNavItems: [],
              }
            } else {
              sectionData.subNavItems.push({
                label: item.link_text || item.label,
                url: item.url,
              })
            }
          })
        megaNavMenus.push(sectionData)
      })
  }

  return [footerMenus, footerMainMenus, accountMenus, megaNavMenus]
}

export const mainMenusToNav = (mainMenusData) => {
  let mainMenus = []
  if (mainMenusData.items && mainMenusData.items.length > 0) {
    mainMenus = mainMenusData.items[0].menu_items.map((mn) => {
      return {
        label: mn.link_text || mn.label,
        href: mn.url,
      }
    })
  }
  return mainMenus
}
