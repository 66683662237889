const IconPlayHover = (props) => {
  return (
    <svg
      width='68'
      height='40'
      viewBox='0 0 68 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='68' height='40' rx='20' fill='#001FB9' />
      <path
        d='M40.4756 20.6267L28.6335 27.1031H28.6321C28.3954 27.2324 28.1042 27.2324 27.8675 27.1031C27.6321 26.9738 27.4858 26.735 27.4858 26.4764V13.5237C27.4858 13.2651 27.6321 13.0263 27.8675 12.897C28.1043 12.7677 28.3954 12.7677 28.6321 12.897L40.4742 19.3734H40.4756C40.711 19.5027 40.8572 19.7414 40.8572 20C40.8572 20.2587 40.711 20.4974 40.4756 20.6267Z'
        fill='white' />
    </svg>
  )
}

export default IconPlayHover
