import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, text3XL, textBase } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const VideoContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > a {
    //margin-bottom: 30px;
    height: 65px;
  }

  @media ${DEVICES.tablet} {
    //padding-right: 20px;
  }

  @media ${DEVICES.mobile} {
    > a {
      margin-top: 20px;
    }
  }
`

export const VideoTitle = styled.h3`
  ${text3XL};
  margin-bottom: 20px;
  color: ${COLORS.secondary};
  letter-spacing: 2px;
  text-transform: uppercase;

  @media ${DEVICES.mobile} {
    margin-top: 28px;
  }
`

export const Subtitle = styled.span`
  ${textBase};
  font-family: ${FONTS.bold};
  color: ${COLORS.secondary};
  text-transform: uppercase;
`

export const VideoDes = styled.p`
  margin-bottom: 28px;
  line-height: 22px;
  color: ${COLORS.navi};

  @media ${DEVICES.mobile} {
    margin-top: 20px;
  }
`

export const ButtonWrap = styled.div`
  height: 65px;
`

export const VideoFeature = styled.div`
  background-color: ${COLORS.sand};
  padding: 120px 42px;

  @media ${DEVICES.tablet} {
    padding: 72px 16px;

    ${VideoContent} {
      max-width: unset;
      .custom-button {
        margin-bottom: 60px;
      }
    }

    ${VideoTitle} {
      ${text2XL};
      letter-spacing: 1.6px;
    }
  }

  ${({ theme }) =>
    theme === 'blue' &&
    css`
      background-color: ${COLORS.navi};

      ${VideoTitle},
      ${VideoDes} {
        color: ${COLORS.white};
      }

      button {
        background-color: ${COLORS.white};
        color: ${COLORS.secondary};
      }
    `}
`

export const VideoWrap = styled.div`
  width: 57%;
`

export const VideoFeatureInner = styled.div`
  display: flex;
  gap: 86px;

  @media ${DEVICES.mobile} {
    flex-direction: column;

    ${VideoContent} {
      margin-bottom: 30px;
      gap: 0;
    }

    ${VideoWrap} {
      width: 100%;
    }
  }

  @media ${DEVICES.tablet} {
    gap: 20px;
  }
`
