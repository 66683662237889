const IconPlusBig = (props) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.375 3.375V8.375H3.375V9.625H8.375V14.625H9.625V9.625H14.625V8.375H9.625V3.375H8.375Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconPlusBig
