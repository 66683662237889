import { useContext } from 'react'
import FullWidthVideo from '../../global/Blocks/VideoFeature'
import SVGIcon from '../../global/icon/svgIcon'
import { SiteSettingsContext } from '../../../context/SiteSettingsContext'
import dynamic from 'next/dynamic'

const Carousel = dynamic(() => import('@global/Carousel'))

const BarryplantTVBlock = ({
  title,
  headingVideo,
  videos,
  filterByLabel,
  videoViewMoreLink,
}) => {
  // get youtube URL
  const { settings } = useContext(SiteSettingsContext)
  const youtubeUrl =
    (settings?.socialmediasettings &&
      settings?.socialmediasettings?.youtube_url) ||
    null

  return (
    <div className='c-barryplant-tv-block'>
      <div className='o-wrapper'>
        <div className='c-barryplant-tv-block__heading'>
          <div className='o-layout'>
            <div className='o-layout__item u-1/3@desktop'>
              <h4>{title}</h4>
              <h3>{headingVideo.title}</h3>
              <h5>{headingVideo.intro}</h5>
              {youtubeUrl && (
                <a
                  href={youtubeUrl}
                  className='c-barryplant-tv-block__follow-youtube'
                  target='_blank'
                >
                  <SVGIcon name={headingVideo.followOnYoutube.icon} />{' '}
                  {headingVideo.followOnYoutube.label}
                </a>
              )}
            </div>

            <div className='o-layout__item u-2/3@desktop'>
              <div className='c-barryplant-tv-block__video-wrap'>
                <FullWidthVideo {...headingVideo} />
                <div className='c-barryplant-tv-block__video-caption'>
                  <h4>{headingVideo.caption}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className='o-layout'>
          <div className='o-layout__item'>
            <div className='c-barryplant-tv-block__list'>
              {filterByLabel && (
                <div className='c-barryplant-tv-block__list-hd'>
                  <div className='c-barryplant-tv-block__filter'>
                    <div className='c-barryplant-tv-block__filter-by'>
                      <span className='c-barryplant-tv-block__filter-by-label'>
                        {filterByLabel}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className='c-barryplant-tv-block__list-bd'>
                <Carousel link={videoViewMoreLink}>
                  {videos?.map((video, key) => (
                    <div key={key} className='o-layout__item'>
                      <div className='c-barryplant-tv-block__video-wrap'>
                        <FullWidthVideo {...video} noWrap />
                        <div className='c-barryplant-tv-block__video-caption'>
                          <h4>
                            {video.caption
                              ? video.caption
                              : video.title
                              ? video.title
                              : ''}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BarryplantTVBlock.defaultProps = {
  title: 'Barry Plant TV',
  headingVideo: {},
  filterByLabel: 'View featured videos',
  videoViewMoreLink: {
    link: '#',
    label: '...view more',
  },
  videos: [],
}
BarryplantTVBlock.ApiBlockToProps = (data) => {
  if (!data) return {}
  const {
    featured_videos,
    key_video,
    call_to_action_link,
    call_to_action_text,
    call_to_acttion_url,
  } = data.value
  const videos = (featured_videos || []).map((item) => {
    return {
      id: item.id,
      title: item.title,
      intro: item.preamble,
      caption: item.caption,
      label: 'Play video',
      posterUrl: item.cover_image && item.cover_image.url,
      videoUrl: item.video_url,
      isHero: data.value.key_video == item.id ? true : false,
    }
  })
  const headingVideo = {
    id: key_video.id,
    title: key_video.title,
    caption: key_video.caption,
    intro: key_video.preamble,
    label: 'Play video',
    posterUrl: key_video.cover_image && key_video.cover_image.url,
    videoUrl: key_video.video_url,
    followOnYoutube: {
      icon: 'youtube',
      label: '- follow us on YouTube',
      link: '#',
    },
  }
  return {
    data: data,
    headingVideo: headingVideo,
    videos: videos,
    videoViewMoreLink: {
      url: call_to_action_link
        ? call_to_action_link.path
        : call_to_acttion_url
        ? call_to_acttion_url
        : null,
      label: call_to_action_text || '...View more',
    },
    cta_link: call_to_action_link || null,
    cta_text: call_to_action_text || '...View more',
    cta_url: call_to_acttion_url,
  }
}
export default BarryplantTVBlock
