import styled from 'styled-components'
import { COLORS } from '@styles/colors'

export const SpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 242, 235, 0.25);
`

export const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 5px solid ${COLORS.white};
  border-bottom-color: ${COLORS.secondary};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
