import styled from 'styled-components'
import { DEVICES } from '@constants/global'

export const NewsList = styled.div`
  //margin-bottom: -48px;

  > a {
    display: none;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 0;

    > a {
      display: inline-block;
    }
  }
`

export const NewsListLinks = styled.div`
  text-align: right;
  display: none;

  @media ${DEVICES.mobile} {
    display: block;
  }
`

export const NewsListHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  @media ${DEVICES.mobile} {
    margin-bottom: 36px;

    a {
      display: none;
    }
  }
`
