const IconCloseCircle = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle
        cx='16'
        cy='16'
        r='15.5556'
        stroke='currentColor'
        strokeWidth='0.888889'
      />
      <path
        d='M20.7385 10.4004L15.9999 15.139L11.2613 10.4004L10.3999 11.2618L15.1385 16.0004L10.3999 20.739L11.2613 21.6004L15.9999 16.8618L20.7385 21.6004L21.5999 20.739L16.8613 16.0004L21.5999 11.2618L20.7385 10.4004Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconCloseCircle
