import CareerIntroType from './CareerIntroType'

const CareersInformation = ({ types, scrollToForm }) => (
  <div className='o-wrapper c-careers-intro-block__list'>
    {types &&
      types.length > 0 &&
      types.map((type, index) => (
        <CareerIntroType key={index} {...type} scrollToForm={scrollToForm} />
      ))}
  </div>
)

CareersInformation.ApiBlockToProps = data => {
  const { career_sections, scrollToForm } = data.value

  let types = []
  career_sections &&
    career_sections.length &&
    career_sections.map(type => {
      types.push({
        title: type.title,
        intro: type.subtitle,
        image: type.image,
        content: type.content,
        register: {
          link: type.register_call_to_action_link,
          label: type.register_call_to_action_text
        },
        vacancies: {
          link: type.view_call_to_action_link,
          label: type.view_call_to_action_text
        }
      })
    })

  return {
    types,
    scrollToForm: scrollToForm || null
  }
}

export default CareersInformation
