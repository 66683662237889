import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, text3XL, textSM } from '@styles/fonts'
import styled from 'styled-components'

export const Col1 = styled.div`
  flex: 1;
  padding-right: 84px;
`

export const Col2 = styled.div`
  flex: 0 0 35%;
`

export const TwoColumnText = styled.div`
  display: flex;

  @media ${DEVICES.tabletMini} {
    padding-right: 42px;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;

    ${Col1} {
      padding-right: 0;
      margin-bottom: 60px;
    }

    ${Col2} {
      flex: 1;
    }
  }
`

export const Col1Title = styled.h2`
  ${text3XL};
  font-family: ${FONTS.medium};
  text-transform: none;
  margin-bottom: 40px;

  @media ${DEVICES.mobile} {
    ${text2XL};
    margin-bottom: 32px;
  }
`

export const Col1Content = styled.div`
  color: ${COLORS.navi};
`

export const Col2Title = styled.h4`
  ${textSM};
  margin-bottom: 22px;
  line-height: 20px;
  letter-spacing: 1px;
`

export const Col2Links = styled.ul`
  background-color: ${COLORS.nature[95]};
  padding: 20px 16px;
  list-style-type: none;
`

export const Col2LinksItem = styled.li`
  margin-bottom: 8px;
  padding-left: 13px;
  position: relative;
  color: ${COLORS.navi};

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '•';
    position: absolute;
    top: 0;
    left: 0;
  }

  a {
    color: ${COLORS.navi};

    &:hover {
      color: ${COLORS.primary};
    }
  }

  @media ${DEVICES.mobile} {
    color: ${COLORS.secondary};

    a {
      color: ${COLORS.secondary};
    }
  }
`
