const IconMinus = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.6884 8.53346H2.13281V7.4668H13.6884V8.53346Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconMinus
