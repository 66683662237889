import styled from 'styled-components'
import { FONTS, text2XL, textBase, textLG } from '@styles/fonts'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

export const BlogDropQuoteWrap = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${COLORS.nature[80]};
  border-bottom: 1px solid ${COLORS.nature[80]};
`

export const BlogDropQuoteContent = styled.div`
  ${text2XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};

  @media ${DEVICES.mobile} {
    ${textLG};
  }
`

export const BlogDropQuoteAuthor = styled.div`
  margin-top: 24px;
`

export const BlogDropQuoteAuthorName = styled.h5`
  ${textBase};
  text-transform: none;
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
`

export const BlogDropQuoteAuthorTitle = styled.span`
  ${textBase};
  color: ${COLORS.navi};
`
