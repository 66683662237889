import Image from '@global/Image'

const AdBanner = ({ value }) => {
  return (
    <div>
      {value.leaderboard_image && value.leaderboard_image.length > 0 ? (
        <div className='u-show@tablet u-margin-bottom'>
          <a href={value.link_url} target='_blank'>
            <Image src={value.leaderboard_image} alt='' />
          </a>
        </div>
      ) : null}
      {value.mobile_banner_image && value.mobile_banner_image.length > 0 ? (
        <div className='u-hide@tablet u-margin-bottom'>
          <a href={value.link_url} target='_blank'>
            <Image src={value.mobile_banner_image} alt='' />
          </a>
        </div>
      ) : (
        <div className='u-hide@tablet u-margin-bottom'>
          <a href={value.link_url} target='_blank'>
            <Image src={value.leaderboard_image} alt='' />
          </a>
        </div>
      )}
    </div>
  )
}

AdBanner.defaultProps = {
  title: '',
  text: 'Find Out More',
}

export default AdBanner
