import IconPhone from '@icons/Phone'
import IconEmail from '@icons/Email'
import { Link } from '@routes'
import FavouriteHeart from '@global/FavouriteHeart'
import Head from 'next/head'
import Image from '@global/Image'
import Button from '@global/Button'
import IconArrowRight from '@icons/ArrowRight'
import * as S from './AgentCard.styled'

const AgentCard = ({ agent, isLazyLoad, onClickEnquire }) => {
  const parseArrayToString = (arrData) => {
    return arrData.join()
  }

  return (
    <>
      <Head>
        {isLazyLoad === false && agent.photo && (
          <link rel='preload' href={agent.photo} as='image' />
        )}
      </Head>
      <S.AgentCard
        data-gtm-agent-id={agent.id}
        data-gtm-agent-name={agent.name}
        data-gtm-office-name={
          (agent?.office_names?.length > 0 &&
            parseArrayToString(agent?.office_names)) ||
          'Barry Plant'
        }
        data-gtm-office-id={
          (agent?.office_ids?.length > 0 &&
            parseArrayToString(agent.office_ids)) ||
          ''
        }
      >
        <S.AgentCardImage>
          <Image src={agent.photo} alt={agent.name} />
          <FavouriteHeart type='agent' id={agent.id} />
        </S.AgentCardImage>

        <S.AgentCardHeading>
          <S.AgentName>
            <Link route={agent.url} passHref>
              {agent.name}
            </Link>
          </S.AgentName>
          <S.AgentTitle>{agent.job_title}</S.AgentTitle>
        </S.AgentCardHeading>

        <S.AgentCardInfo>
          <div>
            <S.AgentCardContact>
              {agent.phone ? (
                <>
                  <IconPhone />
                  <a href={`tel:${agent.phone}`}>{agent.phone}</a>
                </>
              ) : (
                <>&nbsp;</>
              )}
            </S.AgentCardContact>
            {agent.show_send_enquiry && (
              <S.AgentCardContact
                onClick={() => onClickEnquire && onClickEnquire(agent)}
              >
                <IconEmail />
                <span>Enquire</span>
              </S.AgentCardContact>
            )}
          </div>
          <Button circle>
            <IconArrowRight />
          </Button>
        </S.AgentCardInfo>
        <Link route={agent.url} passHref></Link>
      </S.AgentCard>
    </>
  )
}

AgentCard.ApiDataToProps = (data) => {
  return {
    agent: {
      ...data,
      name: data.title,
      photo: data.profile_image_thumbnail
        ? data.profile_image_thumbnail.url
        : data.profile_image
        ? data.profile_image.url
        : null,
      office_names: data.office_names || null,
    },
  }
}

export default AgentCard
