import { COLORS } from "@styles/colors";
import { FONTS, textBase } from "@styles/fonts";
import styled from "styled-components";

export const BlogParagraphWrap = styled.div`
  ${textBase};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};

  ul {
    padding-bottom: 16px;
    li {
      margin-left: 40px;
    }
  }
  
  p {
    padding-bottom: 16px;
  }
 
`
