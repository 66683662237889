import dynamic from 'next/dynamic'
import * as S from './Field.styled'
import IconInfo from '@icons/Info'

const Input = dynamic(() => import('../Input'), { ssr: false })
const Select = dynamic(() => import('../Select'), { ssr: false })
const Checkbox = dynamic(() => import('../Checkbox'), { ssr: false })
const Radio = dynamic(() => import('../Radio'), { ssr: false })

const Field = ({
  label,
  prefix,
  type = 'text',
  error,
  info,
  helpText,
  showErrorMsg,
  ...inputProps
}) => {
  return type === 'checkbox' ? (
    <S.Field error={error}>
      <Checkbox
        label={label}
        {...inputProps}
        error={error}
        showErrorMsg={showErrorMsg}
      />
    </S.Field>
  ) : type === 'password' ? (
    <S.Field error={error}>
      {label && (
        <S.FieldLabel>
          {label} {inputProps.required && <span>(required)</span>}
        </S.FieldLabel>
      )}
      <Input type='password' {...inputProps} />
      {error && <S.Error>{error}</S.Error>}
      {helpText && <S.HelpText>{helpText}</S.HelpText>}
    </S.Field>
  ) : type === 'radio' ? (
    <S.Field>
      <Radio label={label} {...inputProps} />
    </S.Field>
  ) : (
    <S.Field error={error}>
      {label && (
        <S.FieldLabel>
          {label} {inputProps.required && <span>(required)</span>}{' '}
          {info && (
            <IconInfo
              data-tooltip-id='bp-tooltip'
              data-tooltip-content={info}
            />
          )}
        </S.FieldLabel>
      )}
      {type === 'textarea' || type === 'text_area' ? (
        <textarea {...inputProps} />
      ) : type === 'select' ? (
        <Select
          {...inputProps}
          value={
            inputProps.multiple
              ? inputProps.options?.filter((it) =>
                  (inputProps.value || []).includes(it.value)
                )
              : inputProps.options?.find(
                  (it) => it.value == (inputProps?.value ?? '')
                ) ?? null
          }
          onChange={(option) => {
            if (inputProps.multiple) {
              inputProps.onChange &&
                inputProps.onChange(option.map((it) => it.value))
            } else {
              inputProps.onChange && inputProps.onChange(option?.value || null)
            }
          }}
        />
      ) : (
        <S.InputWrap $prefix={prefix}>
          {prefix && <S.Prefix>{prefix}</S.Prefix>}
          <Input type={type} {...inputProps} />
        </S.InputWrap>
      )}
      {error && <S.Error>{error}</S.Error>}
      {helpText && <S.HelpText>{helpText}</S.HelpText>}
    </S.Field>
  )
}

export default Field
