import { Container } from '@styles/global.styled'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'
import Loading from '@global/Loading'

import * as S from './IframeContainer.styled'

function extractJavaScript(html) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const scripts = doc.querySelectorAll('script')
  let content = ''

  scripts.forEach((script) => {
    content += script.textContent
  })

  return content
}

const IframeWrapper = ({ iframeData }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView && iframeData) {
      const script = extractJavaScript(iframeData)
      if (script) {
        const executeScript = new Function(script)
        executeScript()
      }
    }
  }, [inView, iframeData])

  const onLoad = () => {
    setIsLoading(false)
  }

  return (
    <Container style={{ padding: 40 }} ref={ref}>
      {inView ? (
        iframeData.startsWith('http') ? (
          <S.IframeContainer>
            {isLoading && <Loading inline />}
            <iframe
              src={iframeData}
              style={{
                width: '100%',
                height: '100%',
                minHeight: '800px',
                border: 'none',
                opacity: isLoading ? 0 : 1,
              }}
              onLoad={onLoad}
            />
          </S.IframeContainer>
        ) : (
          <S.IframeContainer dangerouslySetInnerHTML={{ __html: iframeData }} />
        )
      ) : (
        <Loading inline />
      )}
    </Container>
  )
}

IframeWrapper.defaultProps = {
  iframeData: '',
}

IframeWrapper.ApiBlockToProps = (data) => {
  let iframeCode = data.value.iframe_code
  return {
    iframeData: iframeCode,
    isFirstBlock: data.isFirstBlock || false,
  }
}

export default IframeWrapper
