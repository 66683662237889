const IconSliderNext = () => {
  return (
    <svg
      className='c-svgicon c-svgicon--icon-slide-previous c-slide-arrow-left__svg'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 46 22'
    >
      <polygon
        fill='currentColor'
        className='st0'
        points='35.4,0 35.1,0.4 44.7,10 0.2,10 0.2,10.5 44.6,10.5 35.1,20 35.4,20.4 45.6,10.2 '
      />
    </svg>
  )
}

export default IconSliderNext
