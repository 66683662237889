const IconInstagramFilled = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.6049 0C0.718538 0 0 0.718537 0 1.6049V14.3951C0 15.2815 0.718537 16 1.6049 16H14.3951C15.2815 16 16 15.2815 16 14.3951V1.6049C16 0.718538 15.2815 0 14.3951 0H1.6049ZM10.4899 2H5.37242C3.45063 2 1.87891 3.55208 1.87891 5.44816V10.5518C1.87891 12.4484 3.45065 14 5.37242 14H10.4899C12.4117 14 13.9832 12.4484 13.9832 10.5518V5.44816C13.9832 3.55208 12.4117 2 10.4899 2ZM11.2817 8.1427C11.2817 6.27521 9.76627 4.75977 7.89878 4.75977C6.03084 4.75977 4.51562 6.27521 4.51562 8.1427C4.51562 10.0104 6.03084 11.5256 7.89878 11.5256C9.76627 11.5256 11.2817 10.0104 11.2817 8.1427ZM10.1391 8.14266C10.1391 6.90579 9.13542 5.90234 7.89875 5.90234C6.66165 5.90234 5.6582 6.90579 5.6582 8.14266C5.6582 9.37956 6.66165 10.3832 7.89875 10.3832C9.1354 10.3832 10.1391 9.37954 10.1391 8.14266ZM11.721 4.8401C11.721 4.53696 11.475 4.29102 11.1716 4.29102C10.8685 4.29102 10.6226 4.53696 10.6226 4.8401C10.6226 5.14346 10.8685 5.38941 11.1716 5.38941C11.475 5.38941 11.721 5.14346 11.721 4.8401ZM10.0721 2.97998H5.79043C4.18273 2.97998 2.86768 4.28834 2.86768 5.8871V10.1902C2.86768 11.7889 4.18273 13.0971 5.79043 13.0971H10.0721C11.6798 13.0971 12.9946 11.7889 12.9946 10.1902V5.88714C12.9946 4.28834 11.6798 2.97998 10.0721 2.97998Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconInstagramFilled
