const IconSearch = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21.1064 20.0486L16.3064 15.2486C17.9386 13.2423 18.4214 10.5376 17.5851 8.09083C16.7489 5.64403 14.7116 3.79987 12.1935 3.21211C9.67543 2.6243 7.03159 3.37523 5.19871 5.19866C3.37526 7.03147 2.62423 9.67514 3.21216 12.1935C3.79997 14.7115 5.64408 16.7487 8.09088 17.5851C10.5377 18.4213 13.2425 17.9385 15.2486 16.3063L20.0486 21.1063C20.3458 21.3613 20.7902 21.3444 21.0668 21.0669C21.3443 20.7904 21.3612 20.346 21.1061 20.0488L21.1064 20.0486ZM6.25637 14.746C5.13043 13.621 4.49762 12.0947 4.49762 10.5028C4.49762 8.91189 5.12949 7.38571 6.2545 6.25963C7.3795 5.13463 8.90578 4.50182 10.4977 4.50182C12.0896 4.50182 13.6158 5.13463 14.7409 6.25963C15.8659 7.38556 16.4978 8.91187 16.4978 10.5028C16.4978 12.0947 15.865 13.6209 14.739 14.746C13.6187 15.8795 12.0916 16.5179 10.4977 16.5179C8.9039 16.5179 7.37678 15.8795 6.25646 14.746H6.25637Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconSearch
