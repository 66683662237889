import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'
import { text2XL, FONTS, text3XL } from '@styles/fonts'

const mobilePannelHeight = 342;

// Common styles for both CarouselNext and CarouselPrev
const carouselButtonStyles = css`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.secondary};
  background-color: ${COLORS.white};
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 50%;

  &:hover {
    opacity: 1;
    color: ${COLORS.secondary};
    background-color: ${COLORS.white};
  }

  &.slick-disabled {
    display: none;
  }

  &:before {
    display: none;
  }

  svg {
    position: relative;
  }

  @media ${DEVICES.mobile} {
    top: ${mobilePannelHeight / 2 - 30}px;
    width: 32px;
    height: 32px;
  }
`

export const CarouselNext = styled.div`
  ${carouselButtonStyles}

  &.slick-next {
    opacity: 0.6;
    right: -18px;

    @media ${DEVICES.mobile} {
      right: 10px;
    }
  }
`

export const CarouselPrev = styled.div`
  ${carouselButtonStyles}

  &.slick-prev {
    opacity: 0.6;
    left: -20px;

    @media ${DEVICES.mobile} {
      left: 10px;
    }
  }
`

export const Title = styled.h3`
    ${text3XL};
  font-family: ${FONTS.bold};
  color: ${COLORS.secondary};

  @media ${DEVICES.mobile} {
    ${text2XL};
    padding-bottom: 13px;
  }
`

export const Wrapper = styled.div`
  .slick-slide.slick-active {
    z-index: 9;
  }
`


export const ViewMoreLink = styled.div`
  
  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const ViewMoreLinkMobile = styled.div`
  display: none;
  @media ${DEVICES.mobile} {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 15px;
  }
`

