import Button from '@global/Button'
import { Col, Container, Row } from '@styles/global.styled'
import Image from '@global/Image'
import { defaultImages } from '@lib/variables'
import IconArrowRight from '@icons/ArrowRight'
import React from 'react'
import { Link } from '@routes'
import * as S from './ArticlesPanel.styled'

const ArticlesPanel = (props) => {
  const { link, articles } = props
  const mainArticle = articles && articles.shift()

  return (
    <Container>
      {articles && articles.length > 0 && (
        <S.ArticlesPanelWrap>
          {link && (
            <S.ArticlesPanelHeadingWrap>
              <S.ArticlesPanelHeading>Latest News</S.ArticlesPanelHeading>
              <div>
                <S.ViewMoreLink href={link.url} passHref>
                  <Button
                    border
                    color='secondary'
                    endIcon={<IconArrowRight />}
                    size='lg'
                  >
                    {link.text}
                  </Button>
                </S.ViewMoreLink>
              </div>
            </S.ArticlesPanelHeadingWrap>
          )}
          <Row gutter={15}>
            <Col md={7}>
              {mainArticle && (
                <S.MainArticleWrap>
                  <Link route={mainArticle.link || '#'} passHref>
                    <Image
                      src={mainArticle.image.url}
                      alt={mainArticle.image.alt}
                    />
                    <S.MainArticleTitle>{mainArticle.title}</S.MainArticleTitle>
                  </Link>
                </S.MainArticleWrap>
              )}
            </Col>

            <Col md={5} className='articles-panel-col-right'>
              {articles &&
                articles.map((article, key) => (
                  <Link
                    key={`article-${key}`}
                    route={article.link || '#'}
                    passHref
                  >
                    <S.SubArticleWrap>
                      <Image
                        src={article.image.url}
                        alt={article.image.alt}
                        fallbackImage={defaultImages.smallNavy}
                      />
                      <S.SubArticleTitle>{article.title}</S.SubArticleTitle>
                    </S.SubArticleWrap>
                  </Link>
                ))}

              {link && (
                <S.ViewMoreLinkMobile href={link.url} passHref>
                  <Button
                    border
                    color='secondary'
                    endIcon={<IconArrowRight />}
                    size='lg'
                  >
                    {link.text}
                  </Button>
                </S.ViewMoreLinkMobile>
              )}
            </Col>
          </Row>
        </S.ArticlesPanelWrap>
      )}
    </Container>
  )
}

ArticlesPanel.ApiBlockToProps = (data) => {
  const rawData = data?.value
  const newResults = rawData.articles.map((item) => {
    return {
      id: item.id,
      title: item.title,
      preamble: item.preamble,
      image: {
        url: item.header_image_results.url,
        full_url: item.header_image_results.full_url,
        width: item.header_image_results.width,
        height: item.header_image_results.height,
        alt: item.header_image_results.alt,
      },
      link: item.meta.path,
    }
  })
  return {
    articles: newResults,
    link: {
      url: rawData.call_to_action_link?.path || '',
      text: rawData.call_to_action_text,
    },
  }
}

export default ArticlesPanel
