import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  &::before {
    position: fixed;
    content: '';
    width: calc((100% - 1920px) / 2);
    height: 100vh;
    left: 0;
    top: 0;
    background-color: ${COLORS.nature[95]};
    z-index: 10;
  }

  @media ${DEVICES.tablet} {
    display: block;
  }
`

export const Main = styled.main`
  width: calc(100vw - 352px);
  max-width: 1568px;
  position: relative;
  z-index: 2;
  background-color: ${COLORS.white};

  @media ${DEVICES.tablet} {
    padding-left: 0;
    width: 100%;
  }
`
