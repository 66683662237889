import React from 'react'

export const AccountContext = React.createContext({
  token: null,
  userProfile: null,
  properties: null,
  agents: null,
  offices: null,
  alerts: null,
  posts: null,
  setAccount: () => {}
})
