import Footer from './Footer'
import Sidebar from './Sidebar'
import * as S from './Layout.styled'

const Layout = ({ children, isHomePage, menuData }) => {
  return (
    <>
      <S.Layout className='layout'>
        <Sidebar menuData={menuData} />
        <S.Main>{children}</S.Main>
      </S.Layout>
      <Footer isHomePage={isHomePage} menuData={menuData} />
    </>
  )
}

export default Layout
