import { useFormikContext } from 'formik'
import LandSizeFields from './LandSizeFields'
import PropertyFeaturesFields from './PropertyFeaturesFields'
import PriceRangeFields from './PriceRangeFields'
import PropertyTypeField from './PropertyTypeField'
import FilterGroup from './FilterGroup'
import Field from '@global/FormInput/Field'
import Autocompete from '../Autocompete'
import Button from '@global/Button'
import PropertyClassField from './PropertyClassField'
import IconCloseCircle from '@icons/CloseCircle'
import NoOfCarspacesField from './NoOfCarspacesField'
import FloorSizeField from './FloorSizeField'
import IconClose from '@icons/Close'
import { PROPERTY_SEARCH, PROPERTY_CLASS } from '@constants/property'
import * as S from './FiltersForm.styled'
import useBodyClass from '@hooks/useBodyClass'

const PropertyFiltersForm = ({
  activeTab,
  setActiveTab,
  open,
  toggleFilters,
  listingType,
}) => {
  const { values, setFieldValue, setValues } = useFormikContext()
  useBodyClass('modal-open', open)
  useBodyClass('filter-open', open)

  return (
    <S.FiltersFormWrap open={open}>
      <S.FiltersForm>
        <S.FiltersFormMobileHeading>
          <span>Search</span>
          <S.CloseButton onClick={toggleFilters}>
            Close <IconClose />
          </S.CloseButton>
        </S.FiltersFormMobileHeading>
        <S.CloseButton onClick={toggleFilters}>
          Close <IconCloseCircle />
        </S.CloseButton>
        <S.FiltersFormTabs>
          {Object.keys(PROPERTY_SEARCH.LISTING_TYPE_FIELD).map((key) => (
            <S.FiltersFormTab
              key={key}
              active={activeTab === key}
              onClick={() => {
                if (activeTab !== key) {
                  setActiveTab(key)
                  setFieldValue('listing_type', key)
                  setFieldValue('price__gte', null)
                  setFieldValue('price__lte', null)
                  setFieldValue('rent__gte', null)
                  setFieldValue('rent__lte', null)
                }
              }}
            >
              <span>{PROPERTY_SEARCH.LISTING_TYPE_FIELD[key].desktop}</span>
              <span>{PROPERTY_SEARCH.LISTING_TYPE_FIELD[key].mobile}</span>
            </S.FiltersFormTab>
          ))}
        </S.FiltersFormTabs>
        <S.FiltersFormBody>
          <PropertyClassField />
          <Autocompete
            size='big'
            listingType={listingType}
            status={activeTab}
            onChange={({
              address_region,
              address_suburb,
              address_postcode,
            }) => {
              setFieldValue('address_region', address_region)
              setFieldValue('address_suburb', address_suburb)
              setFieldValue('address_postcode', address_postcode)
            }}
          />
          <FilterGroup
            style={{
              border: 'none',
              paddingTop: 16,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Field
              type='checkbox'
              label='Include surrounding suburbs'
              checked={values.surrounding}
              onChange={({ target }) => {
                setFieldValue('surrounding', target.checked)
              }}
            />
          </FilterGroup>

          <PropertyTypeField />
          <PriceRangeFields />
          {values.property_class === PROPERTY_CLASS.RESIDENTIAL && (
            <PropertyFeaturesFields />
          )}
          {values.property_class === PROPERTY_CLASS.COMMERCIAL && (
            <FloorSizeField />
          )}
          <LandSizeFields />
          {values.property_class === PROPERTY_CLASS.COMMERCIAL && (
            <NoOfCarspacesField />
          )}
          <FilterGroup style={{ border: 'none' }}>
            {values.listing_type !== 'sold' && (
              <Field
                type='checkbox'
                label='Hide properties Under Offer'
                checked={values.hide_under_offer}
                onChange={({ target }) =>
                  setFieldValue('hide_under_offer', target.checked)
                }
              />
            )}
          </FilterGroup>
          <S.FiltersFormActions>
            <S.ClearFilters
              onClick={() =>
                setValues({
                  property_class: PROPERTY_CLASS.RESIDENTIAL,
                  listing_type: 'sale',
                  surrounding: true,
                  surrounds: true,
                })
              }
            >
              Clear Filters
            </S.ClearFilters>
            <Button color='primary' size='big' type='submit'>
              Search
            </Button>
          </S.FiltersFormActions>
        </S.FiltersFormBody>
      </S.FiltersForm>
    </S.FiltersFormWrap>
  )
}

export default PropertyFiltersForm
