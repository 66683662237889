import PropertySearchFrom from '@global/PropertySearchFrom'
import Hero from '../Hero'

const HeroSearch = ({
  title,
  bgImageUrl,
  description,
  submitTo,
  searchType,
  bgVideoUrl,
}) => {
  return (
    <Hero
      title={title}
      bgImageUrl={bgImageUrl}
      bgVideoUrl={bgVideoUrl}
      description={description}
      searchForm={
        <PropertySearchFrom searchPages={searchType} submitTo={submitTo} />
      }
    />
  )
}

HeroSearch.ApiBlockToProps = (data) => {
  const rawData = data.value
  return {
    bgImageUrl: rawData.background_image,
    title: rawData.heading,
    submitTo: rawData.submits_to,
    searchType: rawData.search_type,
    description: rawData.sub_heading,
    bgVideoUrl: rawData.background_video,
  }
}

export default HeroSearch
