import format from 'date-fns/format'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'

export const formatDate = (date, formatString, tz) => {
  if (date) {
    if (tz) {
      return formatInTimeZone(new Date(date), tz, formatString)
    }
    return format(new Date(date), formatString)
  }
  return ''
}
