import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const sendPropertyEnquiry = async (formData, token) => {
  return await apiCall(apis.PROPERTY_ENQUIRY, formData, 'post', false, token)
}

export const sendCareersApplication = async (formData, token) => {
  return await apiCall(
    apis.CAREERS_APPLICATION, 
    formData, 
    'post', 
    true, // has file(s)
    token
    )
}

export const sendProjectEnquiry = async (formData, token) => {
  return await apiCall(apis.PROJECT_ENQUIRY, formData, 'post', false, token)
}

export const sendMaintenanceRequestForm = async (data, token) => {
  return await apiCall(apis.MAINTENANCE_REQUEST, data, 'post', false, token)
}

export const getCMSForms = async slug => {
  let params = {}
  if (slug) {
    params.slug = slug
  }
  return await apiCall(UrlBuilder(apis.CMS_FORMS, params))
}

export const getFormsFields = async (slug, fieldClass, submitClass) => {
  let params = {}
  if (slug) {
    params.slug = slug
  }
  return await apiCall(UrlBuilder(apis.CMS_FORMS, params)).then(res => {
    let formFields = {}
    if (res.ok && res.results[0]) {
      formFields.form =
        res.results[0].fields &&
        res.results[0].fields.map(field => {
          return {
            ...field,
            classes: fieldClass
          }
        })

      formFields.submit = {
        type: 'submit',
        label: res.results[0].submit_button_text,
        classes: submitClass
      }

      formFields.slug = res.results[0].slug
      formFields.title = res.results[0].title
      formFields.success_message = res.results[0].success_message
      formFields.error_message = res.results[0].error_message
      formFields.post_redirect_page = res.results[0].post_redirect_page
    }
    return formFields
  })
}

export const submitCMSForm = async (slug, formData, token, files) => {
  // Expects a form "slug" as per `getCMSForms` and an object containing
  // the form data (per Formik `values`)

  // setup data to send
  let data = {
    slug: slug,
    fields: files ? JSON.stringify(formData) : formData
  }

  return await apiCall(apis.CMS_FORMS, data, 'post', files ? true : false, token)
}
