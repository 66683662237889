import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, textLG } from '@styles/fonts'
import styled from 'styled-components'

export const AccordionsWrap = styled.div`
  padding: 44px 24px 35px 24px;
  color: ${COLORS.secondary};
  background-color: ${COLORS.nature[95]};

  @media ${DEVICES.mobile} {
    padding: 32px 20px;
  }
`

export const Accordions = styled.div``

export const Accordion = styled.div``

export const AccordionTitle = styled.h4`
  ${textLG};
  text-transform: none;
  font-family: ${FONTS.medium};
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLORS.nature[80]};
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: auto;
    flex: 0 0 20px;
  }

  span {
    margin-right: 20px;
  }
`

export const AccordionDes = styled.div`
  color: ${COLORS.navi};
  margin-bottom: 44px;
  
  p {
    padding-bottom: 16px;
  }
  
  ul {
    margin-bottom: 16px;
    padding: 0;

    li {
      margin-left: 24px;
    }
  }
`
