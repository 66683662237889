import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import IconPlay from '@global/Icons/Play'
import * as S from './Video.styled'
import Image from '@global/Image'
import IconPlayHover from '@icons/PlayHover'
import { defaultImages } from '@lib/variables'
import { useInView } from 'react-intersection-observer'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

const Video = ({
  videoUrl,
  videoTitle,
  videoThumbnail = defaultImages.mediumNavy,
  videoHeight = 320,
  border = false,
  ratio = 56.4,
  borderAll = false
}) => {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [playing, setPlaying] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const togglePlay = () => {
    setPlaying((prev) => !prev)
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <S.VideoWrapper border={border} ratio={ratio} borderAll={borderAll}  ref={ref}>
      {!playing && (
        <>
          <Image src={videoThumbnail} alt={videoTitle} />
          <S.PlayButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={togglePlay}
          >
            {isHovered ? <IconPlayHover /> : <IconPlay />}
          </S.PlayButton>
        </>
      )}

      {inView && (
        <ReactPlayer
          url={videoUrl}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          height={videoHeight}
          playing={playing}
          onEnded={togglePlay}
          className={'video-player'}
          controls={true}
        />
      )}
    </S.VideoWrapper>
  )
}

Video.ApiBlockToProps = (data) => {
  const { video_image, video_url, placeholder_image } = data.value

  return {
    videoUrl: video_url,
    videoThumbnail: placeholder_image || video_image, //video_image use for block has type featured_video
  }
}

export default Video
