export const DEVICES = {
  mobile: '(max-width: 767px)',
  tablet: '(max-width: 1199px)',
  tabletMini: '(max-width: 1024px)',
  smallDesktop: '(max-width: 1366px)',
  mediumDesktop: '(max-width: 1560px)',
  extremeSmall: '(min-width: 480px)',
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
}
