import { Col, Container, Row } from '@styles/global.styled'
import IconArrowRightCircle from '@icons/ArrowRightCircle'
import htmr from 'htmr'
import { getCTALink, stripTags } from '@lib/helpers'
import { Link } from '@routes'
import { useEffect, useRef, useState } from 'react'
import useWindowSize from '@hooks/useWindowSize'
import Image from '@global/Image'
import * as S from './TwinEqualCTA.styled'

export const TwinCard = ({ title, description, image, url }) => {
  const [bodyHeight, setBodyHeight] = useState(null)
  const [titleHeight, setTitleHeight] = useState(null)
  const windowSize = useWindowSize()
  const bodyRef = useRef(null)
  const titleRef = useRef(null)

  useEffect(() => {
    setBodyHeight(bodyRef.current?.scrollHeight)
    setTitleHeight(titleRef.current?.scrollHeight)
  }, [windowSize.width])

  return (
    <S.Card>
      <S.CardImage>
        <Image src={image} alt={title} />
      </S.CardImage>
      <S.CardBody height={bodyHeight} ref={bodyRef} titleHeight={titleHeight}>
        <S.CardTitle ref={titleRef}>{title}</S.CardTitle>
        <S.CardContent>
          <S.Description>{description}</S.Description>
          <IconArrowRightCircle />
        </S.CardContent>
      </S.CardBody>
      <Link route={url} passHref />
    </S.Card>
  )
}

// titleStyle - uppercase / normal
const TwinEqualCTA = ({ items, title, titleStyle }) => {
  return (
    <S.TwinEqualCTA>
      <Container>
        {title && <S.Title titleStyle={titleStyle}>{title}</S.Title>}
        {items?.length > 0 && (
          <Row gutter={15}>
            {items.map((it, index) => (
              <Col md={6} key={`panel-${index}`}>
                <TwinCard {...it} />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </S.TwinEqualCTA>
  )
}

TwinEqualCTA.ApiBlockToProps = (data) => {
  const { title } = data.value

  const itemsData = data.value.panels || data.value.lifestyles

  const items = itemsData?.map((it) => ({
    title: it.heading || it.title,
    image: it.image || it.hero_image_thumb.url,
    description: it.hover_text || htmr(stripTags(it.content)),
    url: getCTALink(it)?.href || it.get_url,
  }))

  return {
    title,
    items,
  }
}

export default TwinEqualCTA
