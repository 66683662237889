import { useState } from 'react'
import Image from '@global/Image'
import ImageInfo from '@global/Gallery/ImageInfo'
import ShareButton from '@global/ShareButton'
import { defaultImages } from '@lib/variables'
import dynamic from 'next/dynamic'

import * as S from './FeaturedImage.styled'

const Gallery = dynamic(() => import('../../Gallery'))

const FeaturedImage = (props) => {
  const { image, caption, title } = props
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(null)

  const items = [
    {
      link_type: 'image',
      href: image,
    },
  ]

  return (
    <>
      <S.FeaturedImageWrapper>
        <Image
          src={image}
          alt={title}
          onClick={() => setSelectedGalleryItem(0)}
        />
        {caption && <S.FeaturedImageCaption>{caption}</S.FeaturedImageCaption>}
      </S.FeaturedImageWrapper>

      {selectedGalleryItem !== null && (
        <Gallery
          items={items}
          initialSlide={selectedGalleryItem}
          onClose={() => setSelectedGalleryItem(null)}
          shareInfo={
            <ShareButton
              showInfo
              heading={'Share this image'}
              shareInfo={{
                quote: title,
                media: image,
                image: image,
              }}
            />
          }
          propertyInfo={
            <>
              <ImageInfo title={title} caption={caption} />
            </>
          }
        />
      )}
    </>
  )
}

FeaturedImage.defaultProps = {
  image: defaultImages.largeDefault,
  caption: '',
  title: '',
}

FeaturedImage.ApiBlockToProps = (data) => {
  return {
    ...data.value,
    title: data.title,
  }
}
export default FeaturedImage
