import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'
import { CarouselButtonStyle } from '@global/Carousel/Carousel.styled'

export const CarouselWrapper = styled.div``

export const CarouselWrapperInner = styled.div`
  .slick-list {
    margin-right: -24px;
    overflow: visible;
  }

  .slick-slide > div {
    margin-right: 24px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slider .slick-prev {
    left: -18px;
  }

  .slick-slider .slick-next {
    right: -18px;
  }

  @media ${DEVICES.mobile} {
    .slick-slide > div {
      margin-right: 0;
    }

    .slick-slider .slick-prev {
      left: 16px;
    }

    .slick-slider .slick-next {
      right: 16px;
    }
  }

  .slick-dots {
    display: flex;
    align-items: center;
    background-color: ${COLORS.nature.base};
    height: 2px;
    margin-top: 54px;
    position: relative;
    bottom: auto;

    @media ${DEVICES.mobile} {
      margin-top: 20px;
    }

    > li {
      border-radius: 10px;
      height: 2px;
      transition: all 0.25s;
      margin: 0;
      flex: 1;
      width: 100%;

      button {
        height: 2px;
        transition: all 0.25s;
        width: 100%;

        &::before {
          display: none;
        }
      }

      &:hover,
      &.slick-active {
        background-color: ${COLORS.primary};
        cursor: pointer;
      }
    }
  }

  .slick-prev,
  .slick-next {
    ${CarouselButtonStyle};
  }

  .slick-next {
    svg {
      right: -1px;
    }
  }

  .slick-prev {
    svg {
      left: -1px;
    }
  }
`

export const CarouselIndicators = styled.ul``
export const CarouselNext = styled.div``
export const CarouselPev = styled.div``

export const LinkPanelLabel = styled.span`
  background-color: ${COLORS.nature[95]};
  color: ${COLORS.secondary};
  text-transform: uppercase;
  font-family: ${FONTS.medium};
  padding: 10px 16px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  text-align: center;
  transition: all 0.25s ease-in;
`

export const LinkPanel = styled.div`
  position: relative;
  display: inline-block;

  img {
    width: 100%;
  }

  &:hover {
    ${LinkPanelLabel} {
      background-color: ${COLORS.secondary};
      color: ${COLORS.white};
    }
  }
`

export const LinkPanels = styled.div`
  h2 {
    margin-bottom: 40px;
  }
`
