import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

export const FavouriteHeart = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
  line-height: 0;
  z-index: 2;
  cursor: pointer;
  svg path {
    transition: all 0.25s;
  }

  ${({ inline }) =>
    inline &&
    css`
      display: inline-flex;
      align-items: center;
      position: relative;
      top: auto;
      right: auto;

      span {
        margin-right: 4px;
        transition: all 0.25s;
      }

      &:hover span {
        color: ${COLORS.primary};
      }

      .icon-favourite {
        path:last-child {
          fill: ${COLORS.secondary};
        }
      }
    `}

  &:hover {
    svg {
      path:last-child {
        fill-opacity: 1;
      }
    }
  }
`
