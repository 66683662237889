import useBodyClass from '@hooks/useBodyClass'
import { createContext, useContext, useState } from 'react'

export const defaultEnquiryFormContext = {
  isShowEnquiryForm: false,
  heading: '',
}

export const EnquiryFormContext = createContext(defaultEnquiryFormContext)

export const useEnquiryFormContext = () => useContext(EnquiryFormContext)

export const EnquiryFormProvider = ({ children }) => {
  const [heading, setHeading] = useState('')
  const [isShowEnquiryForm, setShowEnquiryForm] = useState(false)

  useBodyClass('modal-open', isShowEnquiryForm)

  return (
    <EnquiryFormContext.Provider
      value={{ heading, setHeading, isShowEnquiryForm, setShowEnquiryForm }}
    >
      {children}
    </EnquiryFormContext.Provider>
  )
}
