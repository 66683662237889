import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textBase } from '@styles/fonts'

export const ImageInfoWrap = styled.div`
`

export const ImageInfoTitle = styled.h1`
    ${text2XL};
    color: ${COLORS.secondary};
    font-family: ${FONTS.bold};
`

export const ImageInfoCaption = styled.div`
  ${textBase};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};
  margin-top: 14px;
`
