import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { text3XL, text4XL, textBase, textLG } from '@styles/fonts'

export const StatisticsCircle = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: ${COLORS.secondary};
  color: ${COLORS.white};
  position: relative;
  padding-bottom: 100%;

  @media ${DEVICES.mobile} {
    margin-bottom: 40px;
  }
`

export const StatisticsCircleInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  padding: 12px 24px;
  text-align: center;
`

export const StatisticsCircleValue = styled.div`
  ${text4XL};
  margin-bottom: 12px;

  @media ${DEVICES.mobile} {
    ${text3XL};
    margin-bottom: 8px;
  }
`

export const StatisticsCircleLabel = styled.span`
  ${textLG};

  @media ${DEVICES.mobile} {
    ${textBase};
  }
`
