import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'

export const Footer = styled.footer`
  padding: 96px 43px;
  margin: 0 auto;
  background-color: ${COLORS.navi};
  color: ${COLORS.white};
  position: relative;
  z-index: 99;

  @media ${DEVICES.tablet} {
    padding: 80px 16px 60px;
    z-index: 11;
  }

  @media ${DEVICES.mobile} {
    padding: 60px 16px;
  }
`

export const FooterInner = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const FooterBody = styled.div`
  padding-bottom: 28px;

  @media ${DEVICES.tablet} {
    padding-bottom: 20px;
  }

  @media ${DEVICES.mobile} {
    padding-bottom: 64px;
  }
`

export const FooterHeading = styled.h4`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${FONTS.medium};
  color: ${COLORS.white};

  svg {
    display: none;
    cursor: pointer;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 28px;
    cursor: pointer;

    svg {
      display: block;
    }
  }
`

export const FooterMenu = styled.ul`
  list-style: none;
  margin-bottom: 64px;

  @media ${DEVICES.mobile} {
    margin-bottom: 34px;
    display: none;

    ${({ $active }) =>
      $active &&
      css`
        display: block;
      `}
  }
`

export const FooterMenuItem = styled.li`
  margin-bottom: 10px;
  line-height: 22px;

  a {
    color: ${COLORS.white};

    &:hover {
      color: ${COLORS.primary};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const FooterBottom = styled.div`
  font-size: 12px;
  line-height: 18px;
`

export const FooterBottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  flex-wrap: wrap;

  > a {
    font-size: 0;
    line-height: 0;
  }

  &:first-child {
    border-bottom: 1px solid rgba(0, 110, 255, 0.4);
    padding-bottom: 20px;
    margin-bottom: 24px;
  }

  @media ${DEVICES.tablet} {
    &:last-child {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  @media ${DEVICES.mobile} {
    &:first-child {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`

export const FooterLogo = styled.img`
  @media ${DEVICES.mobile} {
    margin-bottom: 24px;
  }
`

export const Copyright = styled.p`
  flex: 1;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.4);

  span {
    display: block;
  }

  @media ${DEVICES.tablet} {
    margin-bottom: 64px;
    color: ${COLORS.white};
  }
`

export const FooterBottomLinks = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  flex-wrap: wrap;

  a {
    color: rgba(255, 255, 255, 0.4);

    &:hover {
      text-decoration: none;
      color: ${COLORS.primary};
      opacity: 1;
    }
  }

  @media ${DEVICES.tablet} {
    justify-content: flex-start;
    margin-left: 0;
    width: 100%;

    a {
      color: ${COLORS.white};
    }
  }
`

export const FooterBottomLink = styled.div`
  margin-left: 35px;
  display: inline-block;

  @media ${DEVICES.tablet} {
    margin: 0;
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 8px;
  }
`
