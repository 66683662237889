import ShareButton from '@global/ShareButton'
import ImageInfo from '@global/Gallery/ImageInfo'
import { useState } from 'react'
import GalleryMain from '@global/Gallery/GalleryMain'
import dynamic from 'next/dynamic'

import * as S from './ImageGallery.styled'

const Gallery = dynamic(() => import('@global/Gallery'))

const ImageGallery = ({ carouselItems, title, intro }) => {
  const [selectedGalleryItem, setSelectedGalleryItem] = useState(null)
  const [index, setIndex] = useState(0)

  const items = carouselItems.map((item) => item.image)
  const getCaption = () => {
    return items[index]?.caption || ''
  }

  return (
    <>
      <S.ImageGallery>
        <S.MainPhoto fullwidth>
          <GalleryMain
            className='gallery-main'
            ratio={70}
            fullwidth
            items={items}
            initialSlide={0}
            onImageClick={(key) => setSelectedGalleryItem(key)}
            onIndexChange={(val) => {
              setIndex(val)
            }}
          />
        </S.MainPhoto>
        <S.ImageInfo>
          <S.ImageCount>
            In images{' '}
            <strong>
              {index + 1}/{items.length}
            </strong>
          </S.ImageCount>
          <S.ImageCaption>{getCaption()}</S.ImageCaption>
        </S.ImageInfo>
      </S.ImageGallery>

      {selectedGalleryItem !== null && (
        <Gallery
          items={items}
          initialSlide={selectedGalleryItem}
          onClose={() => setSelectedGalleryItem(null)}
          shareInfo={
            <ShareButton
              showInfo
              heading={'Share this image'}
              shareInfo={{
                quote: title,
                media: items[0].href,
                image: items[0].href,
              }}
            />
          }
          propertyInfo={
            <>
              <ImageInfo title={title} caption={intro} />
            </>
          }
        />
      )}
    </>
  )
}

ImageGallery.defaultProps = {
  carouselItems: [],
}
ImageGallery.ApiBlockToProps = (data) => {
  return {
    title: data?.title || '',
    intro: data?.intro || '',
    carouselItems:
      data.value &&
      data.value.map((img) => {
        return {
          image: {
            href: img.image,
            alt: img.caption,
            caption: img?.caption || '',
            link_type: 'image',
          },
        }
      }),
  }
}
export default ImageGallery
