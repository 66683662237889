const IconDownload = (props) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.8857 8.55255L8.74288 13.2998L3.60002 8.55255L4.3002 7.79402L8.23071 11.4222L8.23071 1.79976L9.263 1.79976L9.263 11.4148L13.1856 7.79402L13.8857 8.55255Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8999 15.1283L4.61963 15.1283L4.61963 13.1997H3.61963L3.61963 15.1283H3.61426V16.1997H13.9V15.1283H13.8999V13.1997H12.8999V15.1283Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconDownload
