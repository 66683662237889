import React from 'react'
import * as S from './OfficeGroupGird.styled'
import ContactCard from '@global/ContactCard'
import { Col, Container, Row } from '@styles/global.styled'

const OfficeGroupGird = ({ title, offices }) => {
  const titleSegments = title.split(',').join(',<br />');

  return (
    <Container>
      <S.OfficeGroupGirdWrap>
        <S.OfficeGroupGirdTitle dangerouslySetInnerHTML={{ __html: titleSegments }}/>
        <Row gutter={15} mbGutter={0}>
          {offices.map((office, key) => (
            <Col key={`office-${key}`} md={6} className='col'>
              <ContactCard
                key={`agent-${key}`}
                name={
                  <>
                    Barry Plant
                    <br />
                    {office.title}
                  </>
                }
                image={office.featured_image?.url}
                phone={office.phone}
                link={office.url}
              />
            </Col>
          ))}
        </Row>

      </S.OfficeGroupGirdWrap>
    </Container>
  )
}

OfficeGroupGird.defaultProps = {
  items: [],
}

OfficeGroupGird.ApiBlockToProps = (data) => {
  return { ...data.value }
}

export default OfficeGroupGird
