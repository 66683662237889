import React from 'react'
import AgentCard from '@global/AgentCard'
import { useState } from 'react'
import { Col, Container, Row } from '@styles/global.styled'
import Button from '@global/Button'
import IconArrowRight from '@icons/ArrowRight'
import Select from '@global/FormInput/Select'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import dynamic from 'next/dynamic'
import * as S from './OurTeam.styled'

const EnquiryForm = dynamic(() => import('@global/Form/EnquiryForm'), {
  ssr: false,
})

const OurTeam = ({ title, staffData, link }) => {
  const { setShowEnquiryForm } = useEnquiryFormContext()
  const [selectedAgent, setSelectedAgent] = useState(null)

  const onClickEnquire = (agent) => {
    setSelectedAgent(agent)
    setShowEnquiryForm(true)
  }

  const [selectedItem, setSelectedItem] = useState(
    Object.keys(staffData)?.[0] || null
  )

  const options = Object.keys(staffData).map((key) => ({
    label: staffData[key].text || key,
    value: key,
  }))

  if (staffData && selectedItem === null) {
    setSelectedItem(Object.keys(staffData)[0])
  }

  return (
    <Container>
      <S.BlockListHeader>
        <h3>{title}</h3>
        <div>
          {Object.keys(staffData).length > 1 && (
            <Select
              defaultValue={options[0]}
              options={options}
              onChange={({ value }) => setSelectedItem(value)}
            />
          )}

          {link?.url && (
            <S.ViewMoreLink route={link.url} passHref>
              <Button
                border
                color='secondary'
                endIcon={<IconArrowRight />}
                size='lg'
              >
                {link?.label}
              </Button>
            </S.ViewMoreLink>
          )}
        </div>
      </S.BlockListHeader>
      <Row gutter={15} style={{ marginBottom: -40 }}>
        {staffData &&
          staffData[selectedItem]?.length > 0 &&
          staffData[selectedItem]?.map((staffMember, index) => (
            <Col sm={6} md={4} key={`team-member-${index}`}>
              <AgentCard {...staffMember} onClickEnquire={onClickEnquire} />
            </Col>
          ))}
      </Row>

      {/* Mobile Link */}
      {link?.url && (
        <S.ViewMoreLinkWrap>
          <S.ViewMoreLink href={link?.url} passHref>
            <Button border color='secondary' endIcon={<IconArrowRight />}>
              {link?.label}
            </Button>
          </S.ViewMoreLink>
        </S.ViewMoreLinkWrap>
      )}

      {selectedAgent && (
        <EnquiryForm
          type='agent'
          agentName={selectedAgent.name}
          agentSlug={selectedAgent.slug}
          headerInfo={{
            image: selectedAgent.photo,
            title: selectedAgent.name,
            description: selectedAgent.job_title,
            id: selectedAgent.id,
          }}
        />
      )}
    </Container>
  )
}

OurTeam.defaultProps = {
  title: 'Our Team',
  staffData: {},
}

OurTeam.ApiBlockToProps = (data) => {
  const { departments, agents, view_all_link, heading } = data.value

  let totalAgents = 0
  let componentProps = {
    title: heading || 'Our Team',
    staffData: {},
  }

  if (agents?.length) {
    const cleanedAgents = agents.map((agentData) =>
      AgentCard.ApiDataToProps(agentData)
    )

    componentProps.staffData = {
      0: cleanedAgents,
    }

    totalAgents = agents?.length
  } else {
    if (departments) {
      Object.keys(departments).map((key) => {
        let cleanedAgents = []
        departments[key].length > 0 &&
          departments[key].map((agentData) => {
            cleanedAgents.push(AgentCard.ApiDataToProps(agentData))
          })
        componentProps.staffData[key] = cleanedAgents
      })

      totalAgents = Object.values(componentProps.staffData).reduce(
        (acc, curr) => acc + curr.length,
        0
      )
    }
  }

  if (view_all_link) {
    componentProps.link = {
      url: view_all_link.path,
      label: 'View More',
    }
  }

  return componentProps
}

export default OurTeam
