import * as Yup from 'yup'

async function checkValue(isRequired, value) {
  let checkedValue = false
  if (!value) {
    if (isRequired) {
      return false
    } else return true
  }
  for await (const [key, value] of Object.entries(value)) {
    checkedValue = checkedValue || value
  }
  if (isRequired) {
    return checkedValue
  } else {
    return true
  }
}

export function dynamicValidation(form) {
  if (!form || !form.length) return false

  // wagtail field types = text, input, url, select, radio, checkbox, hidden, file, email, number

  Yup.addMethod(Yup.object, 'checkObject', function (isRequired, msg) {
    return this.test({
      name: 'checkObject',
      message: msg || `${this.path} is required`,
      test: function (value) {
        return checkValue(isRequired, value)
      },
    })
  })
  // loop through fields
  let fields = {}
  form.map((field) => {
    fields[field.name] =
      field.type == 'email'
        ? Yup.string().email('Enter a valid email address')
        : field.type == 'number'
        ? Yup.number()
        : field.type == 'url'
        ? Yup.string().url('Enter a valid URL')
        : field.type == 'checkbox-styled'
        ? Yup.bool()
            .oneOf(
              [true],
              `${field.validationLabel || field.label || 'Field'} is required`
            )
            .nullable()
        : field.type == 'file'
        ? Yup.mixed().nullable()
        : field.type == 'multi-check'
        ? Yup.object().checkObject(
            field.required,
            `${field.validationLabel || field.name} is required`
          )
        : field.type !== 'multi-check'
        ? Yup.string()
        : null
    // add required
    if (field.required)
      fields[field.name] = fields[field.name].required(
        `${field.validationLabel || field.label || 'Field'} is required`
      )
  })

  // generate the Yup object
  return Yup.object().shape(fields)
}

export const resetFormFields = (formFields) => {
  let result = {}
  formFields &&
    formFields.length &&
    formFields.map((item) => {
      switch (item.type) {
        case 'input':
        case 'select':
        case 'textarea':
        case 'radios': {
          result = { ...result, ...{ [item.name]: '' } }
          break
        }
        case 'multi-check': {
          result = { ...result, ...{ [item.name]: {} } }
          break
        }
        case 'checkbox-styled':
        case 'file': {
          result = { ...result, ...{ [item.name]: null } }
          break
        }
        default: {
          result = { ...result, ...{ [item.name]: '' } }
          break
        }
      }
    })
  return result
}

export const getCheckForCheckbox = (field, values) => {
  if (field.type === 'checkbox-styled' || field.type === 'radios') {
    return { checked: values[field.name] }
  } else return {}
}
