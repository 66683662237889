import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { Link } from '@routes'
import { COLORS } from '@styles/colors'
import { FONTS, text3XL, textBase, textLG, textSM } from '@styles/fonts'

export const LatestVideoWrap = styled.div``

export const ViewMoreLink = styled(Link)`
  margin-left: 20px;
  display: none;

  @media ${DEVICES.mobile} {
    display: inline-block;
    margin-left: 0;
  }
`

export const LatestVideoHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  > div {
    margin-left: auto;

    ${ViewMoreLink} {
      display: inline-block;
    }
  }

  @media ${DEVICES.mobile} {
    > div {
      ${ViewMoreLink} {
        display: none;
      }
    }
  }
`

export const LatestVideoTitle = styled.h3`
  ${text3XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold}
`

export const VideoCardTitle = styled.div`
  ${textSM};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  text-transform: uppercase;
  margin-top: 20px;
`

export const VideoCardHeading = styled.div`
  ${textLG};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
`

export const VideoCardDescription = styled.div`
  ${textBase};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
`

export const VideoCardWrap = styled.div`

`
