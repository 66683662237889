import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, textSM } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const Field = styled.div`
  margin-bottom: 18px;

  textarea {
    border: 1px solid ${COLORS.grey[40]};
    border-radius: 4px;
    padding: 9px 16px;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    min-height: 160px;

    &::placeholder {
      color: ${COLORS.grey[40]};
    }

    &:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ error }) =>
    error &&
    css`
      input,
      textarea {
        border-color: ${COLORS.error};
      }
    `}
`

export const FieldLabel = styled.label`
  font-family: ${FONTS.regular};
  font-weight: normal;
  color: ${COLORS.navi};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    cursor: pointer;
  }

  @media ${DEVICES.mobile} {
    ${textSM};
  }
`

export const Error = styled.div`
  ${textSM};
  color: ${COLORS.error};
  display: flex;
  align-items: center;
`

export const InputWrap = styled.div`
  position: relative;

  ${({ $prefix }) => $prefix && css`
    input {
      padding-left: 20px;
    }
  `}
`

export const Prefix = styled.span`
  ${textSM};
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
`

export const HelpText = styled.span`
  ${textSM};
  color: ${COLORS.grey[60]};
  display: flex;
  align-items: center;
  margin-top: 10px;
`
