import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getOffices = async (params) => {
  return await apiCall(UrlBuilder(apis.OFFICES_API_BASE, params))
}

export const getOffice = async (id) => {
  return apiCall(`${apis.OFFICES_API_BASE}${id}/`)
}

export const officeEnquiryForm = (data) => {
  return apiCall(apis.OFFICE_ENQUIRY, data, 'POST', false)
}
