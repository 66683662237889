import Field from '@global/FormInput/Field'
import FilterGroup from '../FilterGroup'
import { Col, Row } from '@styles/global.styled'
import { useFormikContext } from 'formik'
import { maxLandSize, minLandSize } from '@lib/searchFields'

const FloorSizeField = () => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <FilterGroup title='Floor Size' noBorder>
      <Row gutter={18}>
        <Col xs={6} sm={6}>
          <Field
            name={minLandSize.name}
            label='Min. Size'
            type='select'
            options={minLandSize.options}
            value={values[minLandSize.name] ?? minLandSize.options[0].value}
            onChange={(value) => setFieldValue(minLandSize.name, value)}
          />
        </Col>
        <Col xs={6} sm={6}>
          <Field
            name={maxLandSize.name}
            label='Max. Size'
            type='select'
            options={maxLandSize.options.filter(
              (it) =>
                it.value > (values[minLandSize.name] ?? 0) || it.value === ''
            )}
            value={values[maxLandSize.name] || maxLandSize.options[0].value}
            onChange={(value) => setFieldValue(maxLandSize.name, value)}
          />
        </Col>
      </Row>
    </FilterGroup>
  )
}

export default FloorSizeField
