import { COLORS } from '@styles/colors'
import styled from 'styled-components'
import { FONTS, text2XL, textBase } from '@styles/fonts'
import { DEVICES } from '@constants/global'


export const ImageWrap = styled.div`
  flex: 1;

  > div {
    height: 100%;
  }
  
  img {
    height: 100%;
    object-fit: cover;
  }

  @media ${DEVICES.mobile} {
    height: 0;
    padding-top: 70%;
    position: relative;
    flex: unset;
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Content = styled.div`
  display: flex;
  padding: 44px 32px 44px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 57%;

  @media ${DEVICES.mobile} {
    flex: 1;
    
    padding: 24px 16px 28px 16px;
    align-self: stretch;
  }
`

export const TestimonialWrap = styled.div`
  display: flex;
  gap: 44px;
  border-radius: 0 0 60px 0;
  background: #F5F2EB;
  
  height: 366px;
  max-height: 366px;


  @media ${DEVICES.mobile} {
    flex-direction: column;
    gap: 0;
    max-height: 690px;
    height: 690px;

    ${Content} {
      width: 100%;
      max-height: unset;
    }
  }
`

export const Heading = styled.div`
  ${text2XL};
  font-family: ${FONTS.regular};
  color: ${COLORS.secondary};
  align-self: stretch;

  @media ${DEVICES.mobile} {
    ${textBase};
    color: ${COLORS.navi};
  }
`

export const AuthorWrap = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 20px;
  margin-top: auto;
  
  flex-basis: 80px;
`

export const AuthorAvatar = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: auto;

  > div {
    width: 100%;
    height: 100%;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const AuthorInfo = styled.div`
  ${textBase};
  color: ${COLORS.secondary};
  display: flex;
  flex-direction: column;

  label {
    align-self: stretch;
  }
`

export const AuthorName = styled.label`
  font-family: ${FONTS.medium};
  margin-bottom: 4px;
`

export const AuthorBusiness = styled.label`
  font-weight: ${FONTS.regular};;
`

export const ReadMore = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  font-size: 14px;
  text-transform: uppercase;
  
  flex-basis: 30px;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 6px;
  }
`

export const HTMLText = styled.div`
  position: relative;
  
  flex: 1;
  overflow: auto;
  
  p {
    margin-bottom: 16px
  }

  p:first-child::before {
    content: "“";
  }

  p:last-child::after {
    content: "”";
  }
`
