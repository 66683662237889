const MapMarker = (props) => {
  return (
    <svg
      width='57'
      height='66'
      viewBox='0 0 57 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g filter='url(#filter0_d_3789_15464)'>
        <path
          d='M28.0004 5.11133C18.5127 5.11133 10.8887 12.7324 10.8887 22.2231C10.8887 31.7108 28.0004 54.8891 28.0004 54.8891C28.0004 54.8891 45.1122 31.7108 45.1122 22.2231C45.1122 12.7324 37.4882 5.11133 28.0004 5.11133Z'
          fill='#F92044'
        />
        <path
          d='M20.3965 14.0977C29.5617 14.0977 36.9887 21.5247 36.9887 30.6899H20.3965V14.0977Z'
          fill='#FAF8F3'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_3789_15464'
          x='5.88867'
          y='0.111328'
          width='50.2227'
          height='65.7773'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='3' dy='3' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.375544 0 0 0 0 0.375544 0 0 0 0 0.375544 0 0 0 0.2 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3789_15464'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3789_15464'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}

export default MapMarker
