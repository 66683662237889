import { Link } from '@routes'
import Button from '@global/Button'
import IconArrowRight from '@icons/ArrowRight'
import MapMarker from '@icons/Marker'
import * as S from './JobCard.styled'

const JobCard = ({ job }) => {
  const { name, location, link, description } = job
  return (
    <S.JobCard>
      <S.JobCardInfo>
        <S.JobCardInfoHeading>
          <S.JobName>{name}</S.JobName>
          <S.JobLocation>
            <MapMarker width={28} height={35} />
            {location}
          </S.JobLocation>
        </S.JobCardInfoHeading>
        <S.JobDescription dangerouslySetInnerHTML={{ __html: description }} />
      </S.JobCardInfo>
      <S.CardBottom>
        <S.CardBottomButtonWrapper>
          <Button circle>
            <IconArrowRight />
          </Button>
        </S.CardBottomButtonWrapper>
      </S.CardBottom>
      <Link route={link || '/'} passHref></Link>
    </S.JobCard>
  )
}

export default JobCard
