import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const FilterOptions = styled.div`
  background-color: ${COLORS.nature[95]};
  display: flex;
`

export const FilterOptionItem = styled.div`
  padding: 12px 24px 10px;
  text-transform: uppercase;
  color: ${COLORS.secondary};
  letter-spacing: 1.4px;
  font-family: ${FONTS.bold};
  border-bottom: 2px solid transparent;
  cursor: pointer;

  span:last-child {
    display: none;
  }

  @media ${DEVICES.mobile} {
    flex: 1;
    display: flex;
    justify-content: center;

    span:first-child {
      display: none;
    }

    span:last-child {
      display: block;
    }
  }

  ${({ active }) =>
    active &&
    css`
      color: ${COLORS.primary};

      @media ${DEVICES.mobile} {
        border-color: ${COLORS.primary};
      }
    `}
`

export const SelectedFilters = styled.div`
  display: flex;
  align-items: center;
  margin: 0 28px;
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  cursor: pointer;

  svg {
    margin-left: 8px;
  }
`

export const FormGroup = styled.div`
  padding: 16px 24px;
  background-color: ${COLORS.nature[95]};
  display: flex;
  align-items: center;
  border-radius: 0 0 30px 0;

  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const FormGroupMobile = styled(FormGroup)`
  display: none;
  @media ${DEVICES.mobile} {
    display: flex;
    align-items: center;
    
    .search-input {
      width: 100%;
    }
  }
`

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${COLORS.secondary};
  flex: 1;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding: 14px 20px 14px 56px;
    border: 1px solid #dbd7cd;
    border-radius: 4px;
    width: 100%;

    &::placeholder {
      color: ${COLORS.secondary};
    }
  }
`

export const PropertySearchFrom = styled.form`
  padding: 140px 0 40px;
  position: relative;
  width: 100%;
  max-width: 830px;
  z-index: 9;

  @media ${DEVICES.mediumDesktop} {
    padding: 80px 0 0;
  }

  @media ${DEVICES.smallDesktop} {
    /* margin-top: -46px;
    padding: 0 16px; */
    padding: 46px 0 0;
  }

  @media ${DEVICES.mobile} {
    ${FormGroup} {
      padding: 16px;
    }

    ${FilterOptions} {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    ${SelectedFilters} {
      margin-right: 0;
      margin-left: 16px;
    }

    button.btn-search {
      display: none;
    }
  }
`
