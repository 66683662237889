import { Link } from '@routes'
import Button from '@global/Button'
import IconArrowRight from '@global/Icons/ArrowRight'
import { Col, Container, Row } from '@styles/global.styled'
import ArticleCard from '@global/ArticleCard'
import * as S from './NewsList.styled'

const NewsList = ({ link, title, articles, number_of_posts, number_of_rows }) => {
  return (
    <S.NewsList>
      <Container>
        <S.NewsListHeading>
          {title && <h3>{title}</h3>}
          {link?.url && (
            <Link route={link.url} passHref>
              <Button
                endIcon={<IconArrowRight />}
                border
                color='secondary'
                size='lg'
              >
                {link.text || 'View More'}
              </Button>
            </Link>
          )}
        </S.NewsListHeading>
        <Row gutter={15}>
          {articles.slice(0, number_of_rows * 3).map((article, index) => (
            <Col md={4} key={index}>
              <ArticleCard key={index} article={article} />
            </Col>
          ))}
        </Row>
        <S.NewsListLinks>
          {link?.url && (
            <Link route={link.url} passHref>
              <Button endIcon={<IconArrowRight />} border color='secondary'>
                {link.text || 'View More'}
              </Button>
            </Link>
          )}
        </S.NewsListLinks>
      </Container>
    </S.NewsList>
  )
}

NewsList.ApiBlockToProps = (data) => {
  const articles = (data.value.latest_news || []).map((item) => {
    const article = item?.post || item
    let link = article?.path || article?.meta?.path
    return {
      ...article,
      link: {
        url: link,
        label: 'read article',
      },
      imgUrl: article.header_image_results?.url,
    }
  })

  return {
    articles,
    title: data.value.title,
    number_of_posts: data.value.number_of_posts || 3,
    number_of_rows: data.value.number_of_rows || 1,
    link: {
      label: data.value.call_to_action_text || null,
      url: data.value.call_to_action_link?.path || null,
    },
  }
}

export default NewsList
