import { listSearchPageType } from '@lib/variables'
import { formatDate } from '@lib/date'
import * as S from './ListingSaleInfo.styled'

const ListingSaleInfo = ({ listing, showType, offmarket, token }) => {
  const offmarketStatus = listing.offmarket_status || ''
  // offmarket
  if (offmarket && !token) {
    return (
      <S.ListingSaleInfo>
        <strong>
          {offmarketStatus === 'current'
            ? 'For Sale - Unlock to View'
            : offmarketStatus === 'sold'
            ? `SOLD OFF MARKET ${
                listing.soldDetails_date
                  ? `- ${formatDate(listing.soldDetails_date, 'EEE do MMMM yyyy')}`
                  : ''
              }`
            : ''}
        </strong>
      </S.ListingSaleInfo>
    )
  }

  //under offer
  if (listing.status != 'sold' && listing.underOffer) {
    return (
      <S.ListingSaleInfo>
        <strong>Under offer</strong>
      </S.ListingSaleInfo>
    )
  }
  // sold
  const { timezone } = listing
  if (showType) {
    switch (showType) {
      case listSearchPageType.SEARCH_TYPE_AUCTION: {
        if (listing.auction_date) {
          return (
            <S.ListingSaleInfo>
              <strong>{listing.onlineauctions && 'Online '}Auction</strong>
              {formatDate(listing.auction_date, 'EEEE do MMMM, h:mmaaa')}
            </S.ListingSaleInfo>
          )
        }
        break
      }
      case listSearchPageType.SEARCH_TYPE_INSPECTION: {
        break
      }
      case listSearchPageType.SEARCH_TYPE_SALEDATE: {
        if (listing.salebySetDate) {
          return (
            <S.ListingSaleInfo>
              <strong>
                Sale by SET DATE<sup>&reg;</sup>
              </strong>
              {formatDate(listing.salebySetDate, 'EEEE do MMMM, h:mmaaa')}
            </S.ListingSaleInfo>
          )
        }
        break
      }
      default: {
        break
      }
    }
  }
  if (listing.status == 'sold') {
    return (
      <S.ListingSaleInfo>
        <strong>SOLD</strong>
        {listing.soldDetails_date && (
          <>
            {formatDate(listing.soldDetails_date, 'EEEE do MMMM yyyy')}
            <br />
          </>
        )}
        <div className='sold-price'>
          for
          {listing.soldDetails_price_display ? (
            <>
              {' $'}
              {new Intl.NumberFormat('en-au', {
                style: 'decimal',
                currency: 'AUD',
              }).format(listing.soldDetails_price)}
            </>
          ) : (
            <> an undisclosed price</>
          )}
          <br />
        </div>
      </S.ListingSaleInfo>
    )
  }
  // leased
  if (listing.status == 'leased') {
    return (
      <S.ListingSaleInfo>
        <strong>LEASED</strong>
      </S.ListingSaleInfo>
    )
  }

  // auctions
  if (listing.auction_date) {
    return (
      <S.ListingSaleInfo>
        <strong>{listing.onlineauctions && 'Online '}Auction</strong>
        {formatDate(listing.auction_date, 'EEEE do MMMM, h:mmaaa', timezone)}
      </S.ListingSaleInfo>
    )
  }

  // sale by set date
  if (listing.listingType == 'sale' && listing.salebySetDate) {
    return (
      <S.ListingSaleInfo>
        <strong>
          Sale by SET DATE<sup>&reg;</sup>
        </strong>
        {formatDate(listing.salebySetDate, 'EEEE do MMMM, h:mmaaa', timezone)}
      </S.ListingSaleInfo>
    )
  }

  // normal sale
  if (listing.listingType == 'sale') {
    return (
      <S.ListingSaleInfo>
        <strong>For sale</strong>
      </S.ListingSaleInfo>
    )
  }

  // lease available date
  if (
    listing.listingType == 'lease' &&
    listing.dateAvailable &&
    listing.status != 'leased'
  ) {
    return (
      <S.ListingSaleInfo>
        <strong>For lease</strong> {'Available '}
        {listing.dateAvailable === 'Now'
          ? listing.dateAvailable
          : formatDate(listing.dateAvailable, 'EEEE do MMMM', timezone)}
      </S.ListingSaleInfo>
    )
  }

  // normal lease
  if (listing.listingType == 'lease') {
    return (
      <S.ListingSaleInfo>
        <strong>For lease</strong>
      </S.ListingSaleInfo>
    )
  }

  // else return empty
  return null
}

export default ListingSaleInfo
