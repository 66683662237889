const IconStarHalf = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <defs>
        <linearGradient id="half-fill-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="50%" stopColor="#F92044" />
          <stop offset="50%" stopColor="#FFFFFF" />
        </linearGradient>
      </defs>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.87446 1.28333L10.7055 5.89303L15.6566 6.21008H15.656C15.7481 6.21542 15.827 6.27658 15.8555 6.36445C15.884 6.45173 15.8561 6.5479 15.7849 6.60609L11.9666 9.77245L13.1956 14.5787C13.2194 14.6707 13.1826 14.7681 13.1036 14.8209C13.0246 14.8738 12.9207 14.8714 12.8447 14.8144L8.66669 12.1694L4.47497 14.8234C4.39719 14.8721 4.29803 14.8685 4.22442 14.8145C4.1502 14.7604 4.11636 14.6672 4.13892 14.5787L5.36794 9.77251L1.54845 6.60676C1.47602 6.5468 1.44811 6.44882 1.47899 6.36036C1.50927 6.2719 1.5918 6.21193 1.68561 6.20956L6.62849 5.8931L8.45897 1.2834C8.49281 1.1979 8.57534 1.14209 8.66678 1.14209C8.75821 1.14209 8.84061 1.19783 8.87446 1.28333Z'
        fill='url(#half-fill-gradient)'
      />
    </svg>
  )
}

export default IconStarHalf
