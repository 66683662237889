const IconBath = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.9057 14.2751H7.08825C4.2469 14.2751 1.94379 11.972 1.94379 9.13061V9.01465H18.0501V9.13061C18.0495 11.972 15.7464 14.2751 12.9057 14.2751Z'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M6.37844 7.30882C6.37844 6.82822 5.989 6.43945 5.50907 6.43945C5.02848 6.43945 4.63971 6.82889 4.63971 7.30882V9.18027'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M14.8107 9.24414H10.986V11.0037H14.8107V9.24414Z'
        stroke='currentColor'
        strokeWidth='0.536231'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconBath
