import Head from 'next/head'
import * as S from './Hero.styled'

const BG_POSITION = {
  center: 'center center',
  bottom: 'bottom center',
  top: 'top center',
}

const Hero = ({
  title,
  tag,
  description,
  bgVideoUrl,
  bgImageUrl,
  searchForm,
  fullscreen,
  office,
  carrer,
  titlePosition, // top | bottom - default top
  maxHeight,
  noGradientBg,
  bgPosition,
  subTitle,
}) => {
  return (
    <>
      <Head>
        {!!bgImageUrl && <link rel='preload' href={bgImageUrl} as='image' />}
        {!!bgVideoUrl && (
          <link rel='preload' href={bgVideoUrl} as='video/mp4' />
        )}
      </Head>
      <S.HeroWrapper
        className='bp-hero'
        office={office}
        carrer={carrer}
        titlePosition={titlePosition}
        noGradientBg={noGradientBg}
        maxHeight={maxHeight}
      >
        <S.Hero
          bgImage={bgImageUrl}
          bgPosition={BG_POSITION[bgPosition]}
          fullscreen={fullscreen}
          maxHeight={maxHeight}
        >
          {bgVideoUrl && (
            <S.VideoBg>
              <S.Video
                src={bgVideoUrl}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                autoPlay
                muted
                loop
                playsInline
              />
            </S.VideoBg>
          )}
          <S.HeroContent>
            {tag && <S.TagWrap>{tag}</S.TagWrap>}
            {title && <S.HeroTitle>{title}</S.HeroTitle>}
            {subTitle && <S.HeroSubTitle>{subTitle}</S.HeroSubTitle>}
            {description && (
              <S.HeroDescription>{description}</S.HeroDescription>
            )}
            {searchForm}
          </S.HeroContent>
        </S.Hero>
      </S.HeroWrapper>
    </>
  )
}

Hero.ApiBlockToProps = (data) => {
  const rawData = data.value

  return {
    bgVideoUrl: rawData?.header_video_url,
    bgImageUrl: rawData?.background_image,
    title: rawData?.heading,
    subTitle: rawData?.sub_heading,
    description: rawData?.header_description,
    titlePosition: 'bottom',
    bgPosition: rawData?.image_position,
  }
}

export default Hero
