import { useFormikContext } from 'formik'
import * as S from './PropertyClassField.styled'

const PROPERTY_CLASSES = [
  {
    label: 'Residential',
    value: 'residential',
  },
  {
    label: 'Commercial',
    value: 'commercial',
  },
]

const PropertyClassField = () => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <S.PropertyClassField>
      {PROPERTY_CLASSES.map((it) => (
        <S.PropertyClass
          active={values.property_class === it.value}
          key={it.value}
          onClick={() => {
            setFieldValue('property_class', it.value)
            setFieldValue('categories', [])
          }}
        >
          {it.label}
        </S.PropertyClass>
      ))}
    </S.PropertyClassField>
  )
}

export default PropertyClassField
