import styled, { css } from 'styled-components'
import { DEVICES } from '../constants/global'
import { FONTS } from './fonts'
import { COLORS } from './colors'

const getColWidth = (number) => {
  return (number / 12) * 100
}

export const Container = styled.div`
  padding: 0 42px;

  @media ${DEVICES.tablet} {
    padding: 0 16px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ itemsCenter }) =>
    itemsCenter &&
    css`
      align-items: center;

      > ${Col} {
        height: 100%;
      }
    `}

  ${({ gutter }) =>
    gutter &&
    css`
      margin: 0 -${gutter}px;

      > ${Col} {
        padding: 0 ${gutter}px;
      }

      @media ${DEVICES.mobile} {
        margin: 0 -15px;

        > ${Col} {
          padding: 0 15px;
        }
      }
    `}

  ${({ mbGutter }) =>
    mbGutter &&
    css`
      @media ${DEVICES.mobile} {
        margin: 0 -${mbGutter}px;

        > ${Col} {
          padding: 0 ${mbGutter}px;
        }
      }
    `}

  ${({ rowReverse }) =>
    rowReverse &&
    css`
      flex-direction: row-reverse;
    `}
`

export const Col = styled.div`
  width: 100%;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `}

  ${({ xs }) =>
    xs &&
    css`
      @media ${DEVICES.xs} {
        width: ${getColWidth(xs)}%;
        flex: 0 0 ${getColWidth(xs)}%;
      }
    `}

  ${({ sm }) =>
    sm &&
    css`
      @media ${DEVICES.sm} {
        width: ${getColWidth(sm)}%;
        flex: 0 0 ${getColWidth(sm)}%;
      }
    `}
    
  ${({ md }) =>
    md &&
    css`
      @media ${DEVICES.md} {
        width: ${getColWidth(md)}%;
        flex: 0 0 ${getColWidth(md)}%;
      }
    `}

  ${({ lg }) =>
    lg &&
    css`
      @media ${DEVICES.lg} {
        width: ${getColWidth(lg)}%;
        flex: 0 0 ${getColWidth(lg)}%;
      }
    `}

  ${({ xl }) =>
    xl &&
    css`
      @media ${DEVICES.xl} {
        width: ${getColWidth(xl)}%;
        flex: 0 0 ${getColWidth(xl)}%;
      }
    `}
`

export const Section = styled.section`
  padding-top: 100px;

  @media ${DEVICES.mobile} {
    padding-top: 72px;
  }

  ${({ withPaddingBottom }) =>
    withPaddingBottom &&
    css`
      padding-bottom: 100px;

      @media ${DEVICES.mobile} {
        padding-bottom: 72px;
      }
    `}

  ${({ paddingSmall, withPaddingBottom, isFirstSection }) =>
    paddingSmall &&
    css`
      padding-top: 48px;

      ${isFirstSection &&
      css`
        padding-top: 0;

        @media ${DEVICES.mobile} {
          padding-top: 32px;
        }
      `}

      @media ${DEVICES.mobile} {
        padding-bottom: 0;
      }

      ${withPaddingBottom &&
      css`
        padding-bottom: 48px;

        @media ${DEVICES.mobile} {
          padding-bottom: 40px;
        }
      `}
    `}

    ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;

      @media ${DEVICES.mobile} {
        padding: 0;
      }
    `}
`

export const SectionTitle = styled.h2`
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 48px;
`

/*
  Title/Content
*/
export const TitleContent = styled.div`
  padding-right: 86px;

  @media ${DEVICES.tabletMini} {
    padding-right: 0;
    padding-bottom: 60px;
  }
`

export const Title = styled.h2`
  margin-bottom: 64px;
`

export const Content = styled.div`
  color: ${COLORS.navi};

  p {
    padding-bottom: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${FONTS.medium};
    padding-bottom: 16px;
  }
`
