import { COLORS } from '@styles/colors'
import styled from 'styled-components'

export const ShareLabel = styled.div`
  margin-right: 30px;
`

export const Share = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.secondary};
  cursor: pointer;
  position: relative;
  transition: all 0.25s ease-in;

  svg {
    margin-right: 7px;
  }

  &:hover {
    text-decoration: underline;
    color: ${COLORS.primary};

    ${ShareLabel} {
      opacity: 0.8;
    }
  }
`
