// import { UrlBuilder } from "../helpers";
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'
import { UrlBuilder } from '../helpers'

export const login = async (email, password) => {
  return await apiCall(apis.LOGIN, { email: email, password: password }, 'post')
}

export const logout = async () => {
  return await apiCall(apis.LOGOUT)
}

export const register = async data => {
  return await apiCall(apis.REGISTER, data, 'post')
}

export const resetPassword = async email => {
  return await apiCall(apis.RESET_PASSWORD, { email: email }, 'post')
}

export const passwordChange = async data => {
  return await apiCall(apis.PASSWORD_CHANGE, data, 'post')
}

export const updateProfile = async (token, data) => {
  return await apiCall(apis.USER, data, 'PATCH', false, token)
}

export const userDetails = async token => {
  return await apiCall(apis.USER, undefined, 'get', false, token)
}

export const userProperties = async (token, params) => {
  const url = UrlBuilder(apis.SUBSCRIBER_PROPERTIES, params)
  return await apiCall(url, undefined, 'get', false, token)
}

export const userOffices = async token => {
  return await apiCall(apis.SUBSCRIBER_OFFICES, undefined, 'get', false, token)
}

export const userAgents = async token => {
  return await apiCall(apis.SUBSCRIBER_AGENTS, undefined, 'get', false, token)
}

export const userAlerts = async token => {
  return await apiCall(apis.SUBSCRIBER_ALERTS, undefined, 'get', false, token)
}

export const userPosts = async token => {
  return await apiCall(apis.SUBSCRIBER_POSTS, undefined, 'get', false, token)
}

export const officeMapLocations = async token => {
  return await apiCall(
    apis.OFFICES_MAP_LOCATIONS,
    undefined,
    'get',
    false,
    token
  )
}

export const createAlert = async (token, data) => {
  return await apiCall(apis.ALERTS, data, 'post', false, token)
}

export const updateAlert = async (token, data) => {
  if (!data.id) return false
  else
    return await apiCall(
      `${apis.ALERTS + data.id}/`,
      data,
      'PATCH',
      false,
      token
    )
}

export const deleteAlert = async (token, id) => {
  return await apiCall(
    apis.ALERTS + id + '/',
    undefined,
    'delete',
    false,
    token
  )
}

export const createFavourite = async (token, type, id) => {
  return await apiCall(
    apis.SUBSCRIBER_CREATE_FAVOURITE,
    { type: type, id: id },
    'post',
    false,
    token
  )
}

export const deleteFavourite = async (token, type, id) => {
  return await apiCall(
    apis.SUBSCRIBER_CREATE_FAVOURITE + `${id}`,
    { type: type, id: id },
    'delete',
    false,
    token
  )
}

export const loginFacebook = async facebookToken => {
  return await apiCall(
    apis.FACEBOOK_LOGIN,
    { access_token: facebookToken },
    'post'
  )
}

export const loginGoogle = async googleToken => {
  return await apiCall(apis.GOOGLE_LOGIN, { access_token: googleToken }, 'post')
}
