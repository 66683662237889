import * as S from './BlogDropQuote.styled'

const BlogDropQuote = ({ quote = '', name = '', title = '' }) => {
  if (!name && !quote && !title) return null
  return (
    <S.BlogDropQuoteWrap>
      <S.BlogDropQuoteContent>“{quote}”</S.BlogDropQuoteContent>
      {name && (
        <S.BlogDropQuoteAuthor>
          <S.BlogDropQuoteAuthorName>{name}</S.BlogDropQuoteAuthorName>
          {title && (
            <S.BlogDropQuoteAuthorTitle>{title}</S.BlogDropQuoteAuthorTitle>
          )}
        </S.BlogDropQuoteAuthor>
      )}
    </S.BlogDropQuoteWrap>
  )
}

BlogDropQuote.ApiBlockToProps = ({ value = {} }) => ({
  quote: value.quote,
  name: value.name,
  title: value.title,
})

export default BlogDropQuote
