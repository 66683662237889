import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getWagtailPageBySlug = async (slug, urlParams) => {
  const pageData = await apiCall(
    UrlBuilder(apis.WAGTAIL_SLUG_FIND, {
      html_path: slug,
      ...urlParams,
    })
  )

  const wagtailPageType =
    pageData.meta && pageData.meta.type && pageData.meta.type.split('.')[1]
  return {
    pageData,
    wagtailPageType,
  }
}

export const getWagtailPreview = async (contentType, previewToken) => {
  const pageData = await apiCall(
    UrlBuilder(apis.WAGTAIL_PREVIEW, {
      content_type: contentType,
      token: previewToken,
    })
  )

  const wagtailPageType =
    pageData.meta && pageData.meta.type && pageData.meta.type.split('.')[1]
  return {
    pageData,
    wagtailPageType,
  }
}

export const getMainMenu = async () => {
  // Gets the main menu from the API.
  const menuData = await apiCall(apis.WAGTAIL_MAIN_MENU)
  if (menuData.items && menuData.items.length > 0) {
    return menuData.items[0]
  }
  return []
}

export const getFlatMenus = async (handle) => {
  // Gets flat/secondary menus from the API, optionally filtered by
  // the menu "handle"
  let CMS_FOOTER_MENUS = apis.CMS_FOOTER_MENUS
  if (handle) {
    CMS_FOOTER_MENUS += `?handle=${handle}`
  }
  return await apiCall(CMS_FOOTER_MENUS)
}

export const getMainMenus = async () => {
  return await apiCall(apis.CMS_MAIN_MENU)
}

export const getOfficeMenus = async (officeId) => {
  return await apiCall(`${apis.CMS_OFFICE_MENU}${officeId}/`)
}

export const getChildPages = async (type, fields, otherOptions = {}) => {
  let options = {
    type: type,
    fields: fields || '*',
    ...otherOptions,
  }
  return await apiCall(UrlBuilder(apis.WAGTAIL_API_BASE, options))
}

export const getSiteSettings = async () => {
  return await apiCall(apis.CMS_SETTINGS)
}
