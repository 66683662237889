import styled, { css } from 'styled-components'
import { FONTS, text2XL, text3XL, text5XL } from '@styles/fonts'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'

export const Hero = styled.div`
  position: relative;
  border-radius: 0px 0px 140px 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-position: top center;
  background-size: cover;
  height: 100vh;
  max-height: 800px;

  ${({ bgImage }) =>
    bgImage &&
    css`
      background-image: url(${bgImage});
    `}

  ${({ bgPosition }) =>
    bgPosition &&
    css`
      background-position: ${bgPosition};
    `}

  @media ${DEVICES.tablet} {
    height: calc(100vh - 62px);
    max-height: unset;
  }

  @media ${DEVICES.mobile} {
    border-radius: 0px 0px 56px 0px;
  }

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      @media ${DEVICES.tabletMini} {
        border-radius: unset;
      }

      @media ${DEVICES.mobile} {
        max-height: 438px;
        height: calc(100vh - 62px);
        border-radius: unset;
      }
    `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

  &:after {
    position: absolute;
    content: '';
    display: block;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 23, 135, 0.4) 8.07%,
      rgba(0, 0, 0, 0) 50%
    );

    @media ${DEVICES.tablet} {
      background: linear-gradient(
        0deg,
        rgba(0, 23, 135, 0.4) 8.26%,
        rgba(0, 0, 0, 0) 63.4%
      );
    }
  }
`

export const VideoBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const HeroContent = styled.div`
  ${({ position }) =>
    position &&
    position === 'bottom' &&
    css`
      justify-content: flex-end;
    `}
  color: ${COLORS.white};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 132px 42px 0;
  position: relative;
  z-index: 4;

  @media ${DEVICES.tablet} {
    padding: 80px 42px 0;
  }

  @media ${DEVICES.tabletMini} {
    padding: 0 16px 78px;
    justify-content: flex-end;
  }

  @media ${DEVICES.mobile} {
    justify-content: center;
    padding: 0 16px 16px;
  }
`

export const HeroTitle = styled.h1`
  ${text5XL};
  font-family: ${FONTS.medium};
  text-transform: uppercase;
  max-width: 470px;

  @media ${DEVICES.tabletMini} {
    max-width: 65%;
  }

  @media ${DEVICES.mobile} {
    ${text3XL};
  }
`

export const HeroSubTitle = styled.p`
  max-width: 314px;
  font-family: ${FONTS.regular};
  padding-top: 10px;

  @media ${DEVICES.mobile} {
    padding-top: 4px;
  }
`

export const HeroDescription = styled.p`
  line-height: 22px;
  max-width: 314px;
  margin-top: 28px;

  @media ${DEVICES.tabletMini} {
    display: none;
    margin-top: 0;
  }
`

export const TagWrap = styled.div`
  margin-bottom: 12px;
`

export const HeroWrapper = styled.div`
  border-radius: 0px 0px 140px 0px;

  @media ${DEVICES.tabletMini} {
    border-radius: unset;
  }

  ${({ office }) =>
    office &&
    css`
      margin-top: -112px;

      @media ${DEVICES.tablet} {
        margin-top: 0;
      }
    `}

  ${({ titlePosition }) =>
    titlePosition === 'bottom' &&
    css`
      max-height: 600px;

      ${HeroDescription} {
        margin-top: 10px;
        text-transform: uppercase;
      }

      ${HeroContent} {
        padding: 0 42px 42px;
        justify-content: flex-end;
      }

      ${Hero} {
        max-height: 600px;

        &:after {
          background: linear-gradient(
            0deg,
            #060e36 -18.11%,
            rgba(23, 36, 121, 0) 76.21%
          );
        }
      }

      @media ${DEVICES.tabletMini} {
        ${Hero} {
          max-height: 460px;
        }
      }

      @media ${DEVICES.mobile} {
        ${Hero} {
          max-height: 330px;
        }

        ${HeroContent} {
          padding: 0 16px 28px;
          justify-content: flex-end;
        }
      }
    `}

    ${({ noGradientBg, maxHeight }) =>
    noGradientBg &&
    maxHeight &&
    css`
      ${Hero} {
        max-height: ${maxHeight};

        &:after {
          background: none;
        }

        @media ${DEVICES.tabletMini} {
          max-height: 460px;
        }

        @media ${DEVICES.mobile} {
          max-height: 330px;
        }
      }
    `}

  ${({ carrer }) =>
    carrer &&
    css`
      ${HeroTitle} {
        ${text2XL};
      }
    `}
`
