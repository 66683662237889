const IconCar = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.3005 13.2686H6.88586'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M3.86416 13.2683H1.90289V12.0149C1.90289 10.3579 3.20392 9.01465 4.80792 9.01465H13.3635C15.9723 9.01465 18.087 10.5744 18.087 13.2683H16.1955'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M5.31132 14.2232C6.1809 14.2232 6.88583 13.5183 6.88583 12.6487C6.88583 11.7792 6.1809 11.0742 5.31132 11.0742C4.44175 11.0742 3.73682 11.7792 3.73682 12.6487C3.73682 13.5183 4.44175 14.2232 5.31132 14.2232Z'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M14.7477 14.2232C15.6172 14.2232 16.3222 13.5183 16.3222 12.6487C16.3222 11.7792 15.6172 11.0742 14.7477 11.0742C13.8781 11.0742 13.1732 11.7792 13.1732 12.6487C13.1732 13.5183 13.8781 14.2232 14.7477 14.2232Z'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M13.7603 9.08467L11.1134 6.15617C10.8392 5.84248 10.425 5.66016 9.98662 5.66016H6.46425C5.88579 5.66016 5.36297 5.9772 5.13507 6.46584L4.24023 9.24487'
        stroke='currentColor'
        strokeWidth='0.804347'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M7.82825 5.86133V8.74626'
        stroke='currentColor'
        strokeWidth='0.536231'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconCar
