import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import CarouselNextArrow from '@icons/CarouselNextArrow'
import CarouselPrevArrow from '@icons/CarouselPrevArrow'
import { Container } from '@styles/global.styled'
import { Link } from '@routes'
import { getCTALink } from '@lib/helpers'
import Image from '@global/Image'
import * as S from './CarouselTwinEqualCTA.styled'

const NextArrow = ({ onClick, className }) => {
  return (
    <S.CarouselNext onClick={onClick} className={className}>
      <CarouselNextArrow />
    </S.CarouselNext>
  )
}

const PrevArrow = ({ onClick, className }) => {
  return (
    <S.CarouselPev onClick={onClick} className={className}>
      <CarouselPrevArrow />
    </S.CarouselPev>
  )
}

const CarouselTwinEqualCTA = ({ value }) => {
  const { title, panels } = value

  if (panels.length === 0) return null

  const settings = {
    infinite: false,
    speed: 600,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    lazyLoad: true,
    focusOnSelect: false,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: { slidesToShow: 3, slidesToScroll: 3 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
    appendDots: (dots) => <S.CarouselIndicators>{dots}</S.CarouselIndicators>,
  }

  return (
    <S.LinkPanels>
      <Container>
        <h2>{title}</h2>
        <S.CarouselWrapper>
          <S.CarouselWrapperInner>
            <Slider {...settings}>
              {panels.map((linkPanel, key) =>
                getCTALink(linkPanel)?.href ? (
                  <S.LinkPanel key={`linkPanel-${key}`}>
                    <Link route={getCTALink(linkPanel).href} passHref>
                      <Image src={linkPanel.image} alt={linkPanel.heading} />
                    </Link>
                    <Link route={getCTALink(linkPanel).href} passHref>
                      <S.LinkPanelLabel>{linkPanel.heading}</S.LinkPanelLabel>
                    </Link>
                  </S.LinkPanel>
                ) : (
                  <S.LinkPanel key={`linkPanel-${key}`}>
                    <Image src={linkPanel.image} alt={linkPanel.heading} />
                    <S.LinkPanelLabel>{linkPanel.heading}</S.LinkPanelLabel>
                  </S.LinkPanel>
                )
              )}
            </Slider>
          </S.CarouselWrapperInner>
        </S.CarouselWrapper>
      </Container>
    </S.LinkPanels>
  )
}

export default CarouselTwinEqualCTA
