import { Link } from '../../../routes'
import { useContext } from 'react'
import { UserContext } from '@context/UserContext'
import Button from '@global/Button'
import Modal from '@global/Modal'
import * as S from './FavouriteModal.styled'

const FavouriteModal = () => {
  const { isOpenFavouriteModal, toggleFavouriteModal, returnto, setReturnto } =
    useContext(UserContext)

  const toggleModal = () => {
    toggleFavouriteModal()
    setReturnto({
      path: '',
      name: '',
    })
  }

  return isOpenFavouriteModal ? (
    <Modal
      className='timeable-modal'
      open={isOpenFavouriteModal}
      toggleModal={toggleModal}
      title='Sign in to save'
    >
      <S.FavouriteModal>
        <S.FavouriteModalContent>
          Save homes, preferred searches, news and more in your personalised
          Barry Plant account.
        </S.FavouriteModalContent>
        <S.FavouriteModalButtons>
          <Link
            route={`/create-account/?redirect=${returnto.path}`}
            passHref
            onClick={toggleModal}
          >
            <Button color='primary'>Join</Button>
          </Link>
          <Link
            route={`/login/?redirect=${returnto.path}`}
            passHref
            onClick={toggleModal}
          >
            <Button border color='primary'>
              Sign in
            </Button>
          </Link>
        </S.FavouriteModalButtons>
      </S.FavouriteModal>
    </Modal>
  ) : null
}

export default FavouriteModal
