import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, textSM } from '@styles/fonts'

export const ContactCardInfo = styled.div`
  padding: 10px 20px;
`

export const ContactCardInfoTitle = styled.h4`
  text-transform: uppercase;
  color: ${COLORS.secondary};
  margin-bottom: 0 !important;
  transition: all 0.25s ease-in;
`

export const ContactCardInfoSubTitle = styled.span`
  ${textSM};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  padding-top: 3px;
`

export const ContactCardInfoPhone = styled.a`
  ${textSM};
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
  line-height: 22px;
  position: relative;
  z-index: 2;
`

export const ContactCard = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.nature[95]};
  margin-bottom: 12px;
  position: relative;
  transition: all 0.25s ease-in;

  &:last-child {
    margin-bottom: 0;
  }

  > a:not(.url-full-info) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  > div:first-child {
    width: 100px;
    height: 100px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }

  &:hover {
    background-color: ${COLORS.secondary};

    a,
    ${ContactCardInfoTitle},
      ${ContactCardInfoSubTitle},
      ${ContactCardInfoPhone} {
      color: ${COLORS.white};
    }
  }
`
