import IconShare from '@icons/Share'
import { useState } from 'react'
import dynamic from 'next/dynamic'
import * as S from './ShareButton.styled'
import Button from '@global/Button'

const ShareModal = dynamic(() => import('./ShareModal'), {
  ssr: false,
})

const ShareButton = ({
  shareInfo,
  title,
  modalPosition,
  noIcon,
  listing,
  showInfo,
  offmarketUnlocked,
  heading,
  button, // button style
}) => {
  const [isVisible, setVisible] = useState(false)

  return (
    <>
      <S.Share className='share-button' onClick={() => setVisible(!isVisible)}>
        {button ? (
          <Button
            startIcon={!noIcon && <IconShare />}
            color='secondary'
            border
            block
          >
            {title || 'Share'}
          </Button>
        ) : (
          <>
            {!noIcon && <IconShare />}
            <S.ShareLabel>{title || 'Share'}</S.ShareLabel>
          </>
        )}
      </S.Share>
      {isVisible && (
        <ShareModal
          isVisible={isVisible}
          setVisible={setVisible}
          shareInfo={shareInfo}
          listing={listing}
          showInfo={showInfo}
          heading={heading}
          offmarketUnlocked={offmarketUnlocked}
          modalPosition={modalPosition || null}
        />
      )}
    </>
  )
}

export default ShareButton
