import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textBase, textLG, textSM, textXS } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const PropertyInfo = styled.div`
  ${textLG};
  font-family: ${FONTS.medium};
  color: ${COLORS.white};
  position: absolute;
  right: 52px;
  bottom: 32px;
  z-index: 9;
  display: none;

  span {
    text-transform: uppercase;
    display: block;
    letter-spacing: 1.4px;
    ${textBase};
  }
`

export const ProjectImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      #191d31 0.24%,
      rgba(17, 28, 98, 0) 34.93%
    );
  }

  ${PropertyInfo} {
    display: inline-block;
  }

  @media ${DEVICES.mobile} {
    border-radius: 0px 0px 48px 0px;
    margin-bottom: 20px;
  }
`

export const ProjectName = styled.h3`
  ${text2XL}
  color: ${COLORS.white};
  font-family: ${FONTS.regular};
  position: relative;

  a {
    color: ${COLORS.white};
  }
`

export const ProjectAddress = styled.h3`
  ${text2XL}
  color: ${COLORS.white};
  font-family: ${FONTS.regular};

  strong {
    font-family: ${FONTS.bold};
  }
`

export const ProjectStatus = styled.span`
  ${textXS};
  font-family: ${FONTS.bold};
  letter-spacing: 0.8px;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 20px;
  background-color: ${COLORS.nature[95]};
  color: ${COLORS.secondary};
  text-transform: uppercase;
  margin-bottom: 12px;
`

export const ProjectTag = styled.span`
  position: absolute;
  color: ${COLORS.nature.base};
  background-color: ${COLORS.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.bold};
  border-radius: 50%;
  text-transform: uppercase;
  width: 140px;
  height: 140px;
  font-size: 38px;
  line-height: 58px;
  letter-spacing: 2px;
  right: 24px;
  bottom: 28px;
  z-index: 9;

  @media ${DEVICES.mobile} {
    width: 72px;
    height: 72px;
    right: 12px;
    bottom: 12px;
    font-size: 20px;
    letter-spacing: 1.2px;
  }
`

export const ProjectContent = styled.div`
  position: absolute;
  z-index: 9;
  bottom: 28px;
  left: 28px;
  width: calc(100% - 56px);
`

export const ProjectCard = styled.div`
  position: relative;
  margin-bottom: 40px;
  border-radius: 0px 0px 100px 0px;
  overflow: hidden;
  height: 0;
  padding-top: 54.3%;

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 9;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: -22px;
    height: auto;
    padding-top: 0;

    ${ProjectImage} {
      border-radius: 0px 0px 48px 0px;
      overflow: hidden;
      position: relative;

      ${PropertyInfo} {
        display: none;
      }
    }

    ${ProjectContent} {
      position: relative;
      bottom: 58px;
      left: 0;
    }

    ${PropertyInfo} {
      position: relative;
      display: block;
      right: auto;
      bottom: auto;
      color: ${COLORS.primary};
      font-family: ${FONTS.regular};
      ${textSM};

      span {
        display: inline-block;
        margin-right: 10px;
        font-family: ${FONTS.medium};
      }
    }

    ${ProjectAddress} {
      margin-bottom: 8px;
    }

    ${ProjectAddress},
    ${ProjectName},
    ${ProjectName} a {
      ${textLG};
      color: ${COLORS.secondary};
    }

    ${ProjectStatus} {
      margin-bottom: 32px;
      margin-left: 10px;
    }
  }

  ${({ mapResults }) =>
    mapResults &&
    css`
      width: 315px;
      margin-bottom: -58px !important;
      height: auto;
      padding-top: 0;

      ${ProjectImage} {
        border-radius: 0;
        overflow: hidden;
        position: relative;
        margin-bottom: 0 !important;

        ${PropertyInfo} {
          display: none;
        }
      }

      ${ProjectContent} {
        position: relative;
        bottom: 58px;
        left: 0;
        padding: 16px;
        width: 100%;
      }

      ${PropertyInfo} {
        position: relative;
        display: block;
        right: auto;
        bottom: auto;
        color: ${COLORS.primary};
        font-family: ${FONTS.regular};
        ${textSM};

        span {
          display: inline-block;
          margin-right: 10px;
          font-family: ${FONTS.medium};
        }
      }

      ${ProjectAddress} {
        margin-bottom: 8px;
      }

      ${ProjectAddress},
      ${ProjectName},
      ${ProjectName} a {
        ${textLG};
        color: ${COLORS.secondary};
      }

      ${ProjectStatus} {
        margin-bottom: 32px;
        margin-left: 0;
      }
    `}
`
