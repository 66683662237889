const IconPadlock = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.1938 6.13975H12.595V4.49543C12.595 2.23735 10.7582 0.400391 8.49999 0.400391C6.24175 0.400391 4.40495 2.23719 4.40495 4.49543V6.13975H3.80682C3.06869 6.13975 2.46869 6.73788 2.46869 7.47601V14.2616C2.46869 15.0016 3.06869 15.5997 3.80682 15.5997H13.1937C13.9318 15.5997 14.5318 15.0016 14.5318 14.2616V7.47665C14.5318 6.73852 13.9319 6.13975 13.1938 6.13975ZM8.93874 13.0479H8.06123C7.82687 13.0479 7.64311 12.8467 7.66435 12.6135L7.78748 11.246C7.58122 11.056 7.45185 10.7823 7.45185 10.4798C7.45185 9.89978 7.92185 9.43103 8.5006 9.43103C9.07935 9.43103 9.54935 9.89978 9.54935 10.4798C9.54935 10.7823 9.41998 11.056 9.21372 11.246L9.33684 12.6135C9.35684 12.8467 9.17312 13.0479 8.93874 13.0479ZM11.0207 6.13975H5.97938V4.49543C5.97938 3.10668 7.10938 1.97479 8.50002 1.97479C9.89066 1.97479 11.0207 3.10666 11.0207 4.49543V6.13975Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconPadlock
