import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { textLG, textSM, textBase, FONTS } from '@styles/fonts'

export const JobCardInfo = styled.div`
  display: flex;
  padding: 28px 24px;
  flex-direction: column;
  gap: 24px;
`

export const JobCardInfoHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const JobName = styled.h3`
  ${textLG};
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.25s ease-in;

  @media ${DEVICES.mobile} {
    white-space: pre-wrap;
  }
`

export const JobLocation = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${COLORS.secondary};
  ${textSM};
  font-family: ${FONTS.medium};
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.25s ease-in;
`

export const JobDescription = styled.div`
  ${textBase};
  color: ${COLORS.navi};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.25s ease-in;
`

export const CardBottom = styled.div`
  display: flex;
  padding: 12px 16px 16px 16px;
  flex-direction: column;
  gap: 28px;
  border-top: 1px solid ${COLORS.sand};
  transition: all 0.25s ease-in;

  svg {
    color: ${COLORS.secondary};
    transition: all 0.25s ease-in;
  }
`

export const CardBottomButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
`

export const JobCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${COLORS.nature[95]};
  transition: all 0.25s ease-in;
  border-radius: 0px 0px 36px 0px;
  margin-bottom: 40px;
  width: 100%;

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: ${COLORS.navi};

    ${JobName},
    ${JobLocation},
    ${JobDescription} {
      color: ${COLORS.white};
    }

    ${CardBottom} {
      border-color: ${COLORS.white};
    }
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 28px;
  }
`
