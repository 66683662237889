import React from 'react'
import { Link } from '@routes'
import Button from '@global/Button'
import IconArrowRight from '@global/Icons/ArrowRight'
import Video from '@global/Video'
import * as S from './VideoFeature.styled'

const VideoFeature = ({ value }) => {
  const theme = value?.layout_theme

  const renderVideo = () => (
    <S.VideoWrap>
      <Video
        videoUrl={value.video_url}
        videoTitle={value.play_video_label}
        videoThumbnail={value.placeholder_image || value.image}
        ratio={56.3}
      />
    </S.VideoWrap>
  )

  const renderVideoContent = () => (
    <S.VideoContent>
      {value?.subtitle && <S.Subtitle>{value.subtitle}</S.Subtitle>}
      <div>
        <S.VideoTitle>{value.heading}</S.VideoTitle>
        <S.VideoDes>{value.description || value.content}</S.VideoDes>
      </div>
      <S.ButtonWrap>
      {(value.call_to_action_url ||
          value.call_to_action_link?.path ||
          value.call_to_action_url ||
          value.call_to_action_external_link) &&
        value.call_to_action_text && (
          <Link
            route={
              value.call_to_action_url ||
              value.call_to_action_link?.path ||
              value.call_to_action_url ||
              value.call_to_action_external_link
            }
          >
            <Button color="secondary" endIcon={<IconArrowRight />}>
              <span>{value.call_to_action_text}</span>
            </Button>
          </Link>
        )}
      </S.ButtonWrap>
    </S.VideoContent>
  )

  return value ? (
    <S.VideoFeature theme={theme}>
      <S.VideoFeatureInner>
        {renderVideoContent()}
        {renderVideo()}
      </S.VideoFeatureInner>
    </S.VideoFeature>
  ) : null
}

export default VideoFeature
