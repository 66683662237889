import { Container } from '@styles/global.styled'
import ProjectCard from '../../ProjectCard'
import dynamic from 'next/dynamic'

const Carousel = dynamic(() => import('@global/Carousel'))

const ProjectPreview = ({ title, items, viewMoreLink }) => {
  return (
    <>
      <Container>
        <header>{title && <span>{title}</span>}</header>
        <Carousel link={viewMoreLink}>
          {items?.length > 0 &&
            items.map((item, index) => (
              <ProjectCard {...item} key={`project-${index}`} />
            ))}
        </Carousel>
      </Container>
    </>
  )
}

ProjectPreview.defaultProps = {
  title: 'Current development projects',
  items: [],
  viewMoreLink: {
    label: '...View more',
    url: '/projects-search',
  },
}

ProjectPreview.ApiBlockToProps = (data) => {
  // Converts API data into the component props
  let items =
      data.value.projects &&
      data.value.projects.map((item) => {
        return {
          id: item.id,
          link:
            item.meta && item.meta.path
              ? item.meta.path
              : item.slug
              ? item.slug
              : '',
          title: item.title,
          image_url: item.images && item.images[0] && item.images[0].thumbnail,
          url:
            item.meta && item.meta.path
              ? item.meta.path
              : item.slug
              ? `/projects/${item.slug}/`
              : '',
          address_suburb: item.address_suburb,
          address_postcode: item.address_postcode,
          address_state: item.address_state,
        }
      }),
    titlePrefix = '',
    viewMoreLink = {
      label: '...View more',
      url: `/projects-search?status=!Sold`,
    }
  switch (data.value.status) {
    case 'current': {
      titlePrefix = 'Current development'
      break
    }
    case 'Sold': {
      titlePrefix = 'Sold'
      viewMoreLink.url = `/projects-search?status=Sold`
      break
    }
    default: {
      titlePrefix = 'Current development'
    }
  }

  return {
    items: items,
    title: `${titlePrefix} projects`,
    viewMoreLink: viewMoreLink,
  }
}

export default ProjectPreview
