import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const ImageAndTextTitle = styled.h2`
  margin-bottom: 20px;
  text-transform: unset;
  font-family: ${FONTS.medium};
`

export const ImageAndTextContent = styled.div`
  color: ${COLORS.navi};

  button {
    margin-top: 42px;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 44px;
  }
`

export const ImageWrap = styled.div`
  width: 57%;
  margin-bottom: 0;
`

export const ImageAndTextContentCenter = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 43%;
    padding-left: 64px;
  }

  @media ${DEVICES.tabletMini} {
    > div {
      padding-left: 32px;
    }
  }

  @media ${DEVICES.mobile} {
    display: block;
    > div,
    img {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
`

export const ImageAndText = styled.div`
  a {
    margin-top: 42px;
    display: inline-block;

    button {
      margin-top: 0;
    }
  }
  
  img {
    width: 100%;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: -38px;

    img {
      margin-bottom: 38px;
    }

    a {
      margin-top: 28px;
    }
  }

  ${({ imageLocation }) =>
    imageLocation === 'R' &&
    css`
      ${ImageAndTextContentCenter} {
        > div {
          padding-right: 64px;
          padding-left: 0;
        }
      }

      @media ${DEVICES.tabletMini} {
        ${ImageAndTextContentCenter} {
          > div {
            padding-right: 32px;
            padding-left: 0;
          }
        }
      }

      @media ${DEVICES.mobile} {
        ${ImageAndTextContentCenter} {
          flex-direction: column-reverse;
        }

        > div {
          flex-direction: column-reverse;
        }
      }
    `}
  
  ${({ theme}) =>
    theme &&
    css`
      padding: 100px 0;
      @media ${DEVICES.mobile} {
        padding: 60px 0;
      }

      ${theme === 'transparent' &&
      css`
        background-color: transparent;
        padding-bottom: 0;
      `}

      ${theme === 'sand' &&
      css`
        background-color: ${COLORS.sand};
      `}

      ${theme === 'blue' &&
      css`
        background-color: ${COLORS.navi};

        ${ImageAndTextTitle},
        ${ImageAndTextContent} {
          color: ${COLORS.white};
        }

        button {
          background-color: ${COLORS.white};
          color: ${COLORS.secondary};
        }
      `}
    `}
`
