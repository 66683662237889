import { Link } from '@routes'
import { Col, Row } from '@styles/global.styled'
import { useEffect, useState } from 'react'
import { getOffices } from '@lib/api-services/officesService'
import * as S from './OfficeList.styled'

const getOfficeGroups = (offices) => {
  const result = []
  const partSize = Math.ceil(offices.length / 4)

  for (let i = 0; i < offices.length; i += partSize) {
    result.push(offices.slice(i, i + partSize))
  }

  return result
}

const OfficesList = () => {
  const [officeGroups, setOfficeGroups] = useState([])

  useEffect(() => {
    const getOfficeList = async () => {
      const data = await getOffices({ fields: 'title,path', per_page: 500 })
      if (data.results) {
        const officeGroups = getOfficeGroups(data.results)
        setOfficeGroups(officeGroups)
      } else {
        setOfficeGroups([])
      }
    }

    getOfficeList()
  }, [])

  return officeGroups.length > 0 ? (
    <S.OfficesList>
      <S.OfficesListInner>
        <S.OfficesListTitle>Our Locations</S.OfficesListTitle>
        <S.OfficesListLinks>
          <Row gutter={10}>
            {officeGroups.map((offices, index) => (
              <Col key={`offices-${index}`} md={6} lg={3}>
                {offices.map((office) => (
                  <div key={office.path}>
                    <Link route={office.path} passHref>
                      Barry Plant {office.title}
                    </Link>
                  </div>
                ))}
              </Col>
            ))}
          </Row>
        </S.OfficesListLinks>
      </S.OfficesListInner>
    </S.OfficesList>
  ) : null
}

export default OfficesList
