import { useState } from 'react'
import IconPlusBig from '@icons/PlusBig'
import IconMinus from '@icons/Minus'
import { Col, Container, Row, SectionTitle } from '@styles/global.styled'
import * as S from './Accordions.styled'

const splitArrayInHalf = (array) => {
  const middleIndex = Math.ceil(array.length / 2)
  const firstHalf = array.slice(0, middleIndex)
  const secondHalf = array.slice(middleIndex)

  return [firstHalf, secondHalf]
}

const Accordion = ({ question, isAllOpen }) => {
  const [isOpen, setOpen] = useState(isAllOpen)
  const toggleAnswer = () => {
    setOpen(!isOpen)
  }
  return (
    <S.Accordion>
      <S.AccordionTitle onClick={toggleAnswer} open={isOpen}>
        <span>{question.title || question.heading}</span>
        {isOpen ? <IconMinus /> : <IconPlusBig />}
      </S.AccordionTitle>
      <S.AccordionDes
        style={{
          display: isOpen ? 'block' : 'none',
        }}
        dangerouslySetInnerHTML={{ __html: question.content || question.text }}
      />
    </S.Accordion>
  )
}

const Accordions = ({ title, accordionGroups = [], isAllOpen }) => {
  return (
    <S.Accordions>
      <Container>
        <SectionTitle>{title}</SectionTitle>
        <S.AccordionsWrap>
          <Row gutter={20}>
            {accordionGroups.map((group, index) => (
              <Col md={6} key={`accordion-group-${index}`}>
                {group.map((question, index) => {
                  return (
                    <Accordion key={`accordion-${index}`} question={question} isAllOpen={isAllOpen} />
                  )
                })}
              </Col>
            ))}
          </Row>
        </S.AccordionsWrap>
      </Container>
    </S.Accordions>
  )
}

Accordions.ApiBlockToProps = (data) => {
  const [firstHalf, secondHalf] = splitArrayInHalf(data.value.sections ?? [])

  return {
    title: data.value.title,
    accordionGroups: [firstHalf, secondHalf],
    isAllOpen: data.value.display_sections_open,
  }
}

export default Accordions
