import { Container } from '@styles/global.styled'
import Image from '@global/Image'
import Button from '@global/Button'
import { Link } from '@routes'
import IconArrowRight from '@icons/ArrowRight'
import * as S from './FeaturedArticleHero.styled'

const ImageView = ({ image }) => (
  <S.BorderedImageWrap>
    <Image alt={image?.alt} src={image} />
  </S.BorderedImageWrap>
)

const FeaturedArticleHero = ({
  image,
  heading,
  description,
  link,
  onClick,
}) => {
  return (
    <Container>
      <S.FeaturedArticleHero>
        <S.FeatureArticleContent>
          <div>
            {!!heading && (
              <S.FeaturedArticleTitle>{heading}</S.FeaturedArticleTitle>
            )}
            <S.FeaturedArticleDescription
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {link && link.url ? (
            <Link route={link.url} target={link.target}>
              <Button endIcon={<IconArrowRight />} color='secondary'>
                {link.text != null ? link.text : null}
              </Button>
            </Link>
          ) : link ? (
            <Button
              endIcon={<IconArrowRight />}
              color='secondary'
              onClick={onClick}
            >
              {link.text != null ? link.text : null}
            </Button>
          ) : null}
        </S.FeatureArticleContent>
        <ImageView image={image} />
      </S.FeaturedArticleHero>
    </Container>
  )
}

FeaturedArticleHero.defaultProps = {}

FeaturedArticleHero.ApiBlockToProps = (data) => {
  const { value } = data

  return {
    image: {
      url: value.articles?.header_image,
      alt: value.articles?.title,
    },
    heading: value.articles?.title,
    description: value.articles?.preamble,
    link: {
      text: value.call_to_action_text || 'View More',
      url:
        value.articles?.url ||
        value.call_to_action_link?.path ||
        value.call_to_action_url,
    },
    onClick: () => {},
  }
}

export default FeaturedArticleHero
