import { getAlignmentClass, getYoutubeThumbnail } from '@lib/helpers'
import { Container } from '@styles/global.styled'
import * as S from './Text.styled'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

const Video = dynamic(
  () => import('@global/Video'),
  { ssr: false }
)

const Text = ({ title, alignment, content }) => {

  const [clientContent, setClientContent] = useState(content);

  const renderContent = () => {
    const videoRegex = /<iframe.*?src="(.*?youtube\.com\/embed\/.*?)".*?<\/iframe>/g
    const parts = []
    let lastIndex = 0
    let match

    while ((match = videoRegex.exec(content)) !== null) {
      const textBeforeIframe = content.slice(lastIndex, match.index)
      if (textBeforeIframe.trim() !== '') {
        parts.push(<S.TextContent key={parts.length} dangerouslySetInnerHTML={{ __html: textBeforeIframe }} />)
      }
      const thumbnailUrl = getYoutubeThumbnail(match[1]);
      parts.push(<Video key={parts.length} videoUrl={match[1]} videoThumbnail={thumbnailUrl} />)
      lastIndex = videoRegex.lastIndex
    }

    const remainingText = content.slice(lastIndex)
    if (remainingText.trim() !== '') {
      parts.push(<S.TextContent key={parts.length} dangerouslySetInnerHTML={{ __html: remainingText }} />)
    }

    return parts
  }

  useEffect(() => {
    setClientContent(renderContent())
  }, [content])

  return (
    <Container>
      <S.Text className={`${getAlignmentClass(alignment)}`}>
        {title && <S.TextTitle>{title}</S.TextTitle>}
        {clientContent && <div>{clientContent}</div>}
      </S.Text>
    </Container>
  )
}

Text.ApiBlockToProps = (data) => {
  let componentProps = {
    title: data.value.heading,
    alignment: data.value.text_alignment,
    content: data.value.text,
  }
  return componentProps
}
export default Text
