import { COLORS } from '@styles/colors'
import { FONTS, textLG } from '@styles/fonts'
import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'

export const CardImage = styled.div`
  width: 100%;
  display: block;
  position: relative;
  padding-top: 66.5%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const CardBody = styled.div`
  background-color: ${COLORS.nature[95]};
  position: absolute;
  width: 100%;
  transition: all 0.25s ease-out;

  ${({ height }) =>
    height &&
    css`
      bottom: ${-height + 72}px;
    `}

  @media ${DEVICES.mobile} {
    position: relative;
    bottom: 0;
  }
`

export const CardTitleWrap = styled.div`
  padding: 10px 20px;
`

export const CardTitle = styled.h4`
  ${textLG};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  text-transform: unset;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 52px;

  @media ${DEVICES.mobile} {
    height: auto;
    max-height: 52px;
  }
`

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px 22px;
  background-color: ${COLORS.nature[95]};
`

export const Description = styled.p`
  color: ${COLORS.navi};
  margin-right: 20px;
  line-height: 22px;
  opacity: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICES.mobile} {
    opacity: 1;
  }
`

export const Card = styled.div`
  position: relative;
  background-color: ${COLORS.nature[95]};
  margin-bottom: 28px;
  overflow: hidden;
  padding-bottom: 74px;

  @media ${DEVICES.mobile} {
    padding-bottom: 0;
  }

  &:hover {
    ${CardBody} {
      bottom: 0;
    }

    ${Description} {
      opacity: 1;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`
