import { useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import { submitCMSForm } from '@lib/api-services/formsService'
import { formSlugs } from '@lib/variables'
import { logEvent } from '@lib/analyticsService'
import { Col, Row } from '@styles/global.styled'
import Field from '@global/FormInput/Field'
import Button from '@global/Button'
import IconArrowRight from '@icons/ArrowRight'
import Image from '@global/Image'
import * as yup from 'yup'
import IconCheckFilled from '@icons/CheckFilled'
import * as S from './SignUpBanner.styled'

const SignUpBanner = ({
  title,
  copy,
  image,
  submit,
  noImage = false,
  smallMode = false,
}) => {
  const [message, setMessage] = useState(null)
  const [leftImage, setLeftImage] = useState(image)
  const [heading, setHeading] = useState(title)
  const [description, setDescription] = useState(copy)
  const [isSuccess, setIsSuccess] = useState(false)

  const formik = useFormikContext()

  const schema = yup.object().shape({
    'first-name': yup.string().required('First name is required'),
    'last-name': yup.string().required('Last name is required'),
    'email-address': yup
      .string()
      .required('Email Address is required')
      .email('Please enter a valid email address'),
    privacy: yup
      .boolean()
      .oneOf([true], 'You are required to read our privacy statement')
      .required('You are required to read our privacy statement'),
  })

  const initialValues = {
    'email-address': '',
    'first-name': 'Mock',
    'last-name': 'Data',
    privacy: false,
  }

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    setSubmitting(true)
    try {
      const response = await submitCMSForm(formSlugs.emailSignup, values)

      if (response.success_message) {
        setMessage(response.success_message)

        // GA: log form submission in google analytics
        logEvent('Forms', 'Submit email signup', window.location.pathname)

        // Reset the form
        resetForm()
        // Reset the 'privacy' checkbox manually
        // setFieldValue('privacy', false)

        // Handle after submit
        setIsSuccess(true)
        setLeftImage('/imgs/newsletter-sucssess.png')
        setHeading('Success! YOU’RE SUBSCRIBED.')
        setDescription(
          'Thanks for subscribing to our mailing list - you’ll get all the latest from us, including updates launched exclusively first to our subscribers!'
        )
      } else if (response.form?.error_message) {
        setMessage(response.form.error_message)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      {smallMode === true ? (
        <S.SignUpBannerWrapperSmall>
          <Row gutter={10}>
            <Col xs={12}>
              <S.SignUpBannerContent>
                {smallMode && isSuccess && (
                  <S.IconWrap>
                    <IconCheckFilled />
                  </S.IconWrap>
                )}

                <S.Title>{heading}</S.Title>
                <S.Description>{description}</S.Description>
                {!isSuccess && (
                  <Formik
                    {...formik}
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      getFieldProps,
                    }) => (
                      <S.Form noValidate onSubmit={handleSubmit}>
                        <S.FormGroup>
                          <Field
                            required
                            {...getFieldProps('email-address')}
                            error={
                              touched['email-address'] &&
                              errors['email-address']
                            }
                          />
                          <Button
                            endIcon={<IconArrowRight />}
                            color='primary'
                            type={submit.type}
                            disabled={isSubmitting}
                          >
                            {submit.label}
                          </Button>
                        </S.FormGroup>
                        <S.PrivacyWrap>
                          <Field
                            className={'privacy'}
                            type={'checkbox'}
                            required
                            name={'privacy'}
                            label={
                              <S.LabelPrivacy>
                                I have read and accepted Barry Plant&apos;s{' '}
                                <br />{' '}
                                <a href='/terms-conditions/' target='_blank'>
                                  <strong>Terms & Conditions</strong>
                                </a>{' '}
                                &{' '}
                                <a href='/privacy-statement/' target='_blank'>
                                  <strong>Privacy Policy</strong>
                                </a>
                              </S.LabelPrivacy>
                            }
                            {...getFieldProps('privacy')}
                            error={touched['privacy'] && errors['privacy']}
                          />
                        </S.PrivacyWrap>
                      </S.Form>
                    )}
                  </Formik>
                )}
              </S.SignUpBannerContent>
            </Col>
          </Row>
        </S.SignUpBannerWrapperSmall>
      ) : (
        <S.SignUpBannerWrapper>
          <Row gutter={10} className={'row-sign-up'}>
            {!noImage && (
              <Col xs={12} md={5} flex style={{ position: 'relative' }}>
                <S.SignUpBannerImage>
                  <Image src={leftImage} alt={''} />
                </S.SignUpBannerImage>
              </Col>
            )}
            <Col xs={12} md={noImage ? 12 : 7}>
              <S.SignUpBannerContent noImage={noImage}>
                <S.Title>{heading}</S.Title>
                <S.Description>{description}</S.Description>
                {!isSuccess && (
                  <Formik
                    {...formik}
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      isSubmitting,
                      getFieldProps,
                    }) => (
                      <S.Form noValidate onSubmit={handleSubmit}>
                        <S.FormGroup>
                          <Field
                            required
                            {...getFieldProps('email-address')}
                            error={
                              touched['email-address'] &&
                              errors['email-address']
                            }
                          />
                          <Button
                            endIcon={<IconArrowRight />}
                            color='primary'
                            type={submit.type}
                            disabled={isSubmitting}
                          >
                            {submit.label}
                          </Button>
                        </S.FormGroup>
                        <S.PrivacyWrap>
                          <Field
                            type={'checkbox'}
                            required
                            name={'privacy'}
                            label={
                              <S.LabelPrivacy>
                                I have read and accepted Barry Plant&apos;s{' '}
                                <br />{' '}
                                <a href='/terms-conditions/' target='_blank'>
                                  <strong>Terms & Conditions</strong>
                                </a>{' '}
                                &{' '}
                                <a href='/privacy-statement/' target='_blank'>
                                  <strong>Privacy Policy</strong>
                                </a>
                              </S.LabelPrivacy>
                            }
                            {...getFieldProps('privacy')}
                            error={touched['privacy'] && errors['privacy']}
                          />
                        </S.PrivacyWrap>
                      </S.Form>
                    )}
                  </Formik>
                )}
              </S.SignUpBannerContent>
            </Col>
          </Row>
        </S.SignUpBannerWrapper>
      )}
    </>
  )
}

SignUpBanner.defaultProps = {
  title: (
    <span>
      Get more from Barry Plant. <S.BreakLine /> Sign up for our newsletter
    </span>
  ),
  copy: "Sign up now to stay informed about market trends, investment opportunities, and exclusive property listings. Don't miss out on valuable insights - join our community today!",
  image: '/imgs/news-subscribe.png',
  form: [
    {
      type: 'input',
      name: 'email-address',
      label: 'Email address',
      required: true,
    },
  ],
  submit: {
    type: 'submit',
    label: 'subscribe',
  },
}

SignUpBanner.ApiBlockToProps = (data) => {
  if (!data.value) {
    return
  }

  return {
    title: data.value.heading,
    copy: data.value.content,
    image: data.value.image,
    form: [
      // {
      //   type: 'input',
      //   label: 'First Name',
      //   name: 'first-name',
      //   required: true,
      // },
      // {
      //   type: 'input',
      //   label: 'Last Name',
      //   name: 'last-name',
      //   required: true,
      // },
      {
        type: 'input',
        name: 'email-address',
        label: 'Email address',
        required: true,
      },
    ],
    submit: {
      type: 'submit',
      label: `${data.value.call_to_action_text || 'subscribe'}`,
    },
  }
}

export default SignUpBanner
