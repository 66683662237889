import Field from '@global/FormInput/Field'
import FilterGroup from '../FilterGroup'
import { Col, Row } from '@styles/global.styled'
import { useFormikContext } from 'formik'
import { maxLandSize, minLandSize } from '@lib/searchFields'

const LandSizeFields = () => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <FilterGroup title='Land Size'>
      <Row gutter={18}>
        <Col xs={6} sm={6}>
          <Field
            name='land_size_min'
            label='Min. Size'
            type='select'
            options={minLandSize.options}
            value={values.land_size_min || minLandSize.options[0].value}
            onChange={(value) => setFieldValue('land_size_min', value)}
          />
        </Col>
        <Col xs={6} sm={6}>
          <Field
            name='land_size_max'
            label='Max. Size'
            type='select'
            options={maxLandSize.options.filter(
              (it) => it.value > (values.land_size_min ?? 0) || it.value === ''
            )}
            value={values.land_size_max || maxLandSize.options[0].value}
            onChange={(value) => setFieldValue('land_size_max', value)}
          />
        </Col>
      </Row>
    </FilterGroup>
  )
}

export default LandSizeFields
