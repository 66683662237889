import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, text3XL } from '@styles/fonts'
import styled from 'styled-components'

export const Office = styled.h4`
  ${text3XL};
  margin-bottom: 20px;
`

export const GoogleLink = styled.a`
  text-decoration: underline;
  margin-top: 8px;
  margin-bottom: 20px;
  display: inline-block;
  font-family: ${FONTS.medium};

  &:hover {
    text-decoration: underline;
  }
`

export const ServicedByImage = styled.div`
  flex: 0 0 50%;
`

export const ServicedByActions = styled.div`
  margin-top: 64px;

  button {
    margin-right: 28px;
  }
`

export const OfficeInfo = styled.div``

export const ServicedByInfo = styled.div`
  flex: 0 0 50%;
  padding: 50px 36px;
  color: ${COLORS.secondary};

  ul {
    list-style: none;
    margin-top: 20px;
    margin-bottom: 32px;

    li:first-child {
      color: ${COLORS.navi};
    }
  }
`

export const ServicedBy = styled.div`
  display: flex;
  border-radius: 0 0 80px 0;
  background-color: ${COLORS.nature[95]};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 80px 0;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;

    ${ServicedByInfo} {
      flex: 0 0 100%;
      padding: 32px 20px;
      color: ${COLORS.navi};

      li a,
      > div > a {
        color: ${COLORS.navi};
      }
    }

    ${OfficeInfo} {
      a {
        color: ${COLORS.navi};
      }
    }

    ${Office} {
      ${text2XL};
      margin-bottom: 16px;
    }

    ${GoogleLink} {
      margin-bottom: 16px;
    }

    ${ServicedByActions} {
      margin-top: 32px;
    }
  }
`
