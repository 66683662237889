const IconPhone = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.7445 11.5636L11.3485 9.6608C11.0598 9.4232 10.635 9.49089 10.414 9.7796L10.0742 10.2382C9.92157 10.442 9.6322 10.4592 9.44571 10.2893L5.72447 6.58523C5.5373 6.39806 5.57182 6.10936 5.77557 5.95672L6.23418 5.61691C6.54014 5.3959 6.59125 4.97114 6.35298 4.68244L4.43357 2.25263C4.19598 1.96393 3.7878 1.91283 3.48184 2.15043C3.24424 2.33759 3.00597 2.57519 2.78496 2.81278C1.35804 4.24103 1.66398 6.68745 5.48677 10.5103C9.32695 14.3331 11.7559 14.6391 13.2001 13.2114C13.4377 12.9738 13.6759 12.7356 13.8624 12.498C14.0841 12.2093 14.033 11.7845 13.7443 11.5635L13.7445 11.5636Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconPhone
