import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 50;

  ${({ $inline }) =>
    $inline &&
    css`
      position: relative;
      width: auto;
      height: auto;
    `}
`

export const LoadingIcon = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  border: 4px solid ${COLORS.greyLight};
  position: relative;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top-color: ${COLORS.secondary};
  animation: spin 1s linear infinite;
`
