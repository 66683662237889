import { DEVICES } from '@constants/global'
import { text3XL } from '@styles/fonts'
import { Col } from '@styles/global.styled'
import styled, { css } from 'styled-components'

export const GridItemsHeading = styled.h2`
  ${text3XL}
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  margin-bottom: 48px;
  justify-content: space-between;

  button {
    margin-left: 20px;
  }

  @media ${DEVICES.mobile} {
    button {
      display: none;
    }
  }
`

export const GridItemsList = styled.div`
  ${({ itemMarginBottom }) =>
    itemMarginBottom &&
    css`
      /* row */
      > div {
        margin-bottom: -${itemMarginBottom}px;
        /* col */
        > div {
          margin-bottom: ${itemMarginBottom}px;
        }
      }
    `}

  @media ${DEVICES.mobile} {
    > div {
      margin-bottom: 0;

      > div {
        margin-bottom: 32px;
      }
    }
  }
`

export const ItemWrap = styled(Col)`
  display: none;

  ${({ isVisibleDesktop }) =>
    isVisibleDesktop &&
    css`
      display: block;
    `}

  @media ${DEVICES.mobile} {
    display: none;

    ${({ isVisibleMobile }) =>
      isVisibleMobile &&
      css`
        display: block;
      `}
  }
`

export const ButtonWrap = styled.div`
  display: none;
  text-align: right;

  @media ${DEVICES.mobile} {
    display: block;
  }
`

export const GridItems = styled.div`
  position: relative;

  @media ${DEVICES.mobile} {
    ${GridItemsHeading} {
      font-size: 22px;
      margin-bottom: 36px;
    }
  }
`
