import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const cmsSettings = {
  searchPages: 'searchsettings',
  socialMedia: 'socialmediasettings',
  robotsTxt: 'robotstxtsettings',
  seo: 'seosettings',
  content: 'contentsettings'
}

export const getSettings = setting => {
  let url = apis.SETTINGS
  if (setting) {
    url += `${setting}/`
  }
  return apiCall(UrlBuilder(url))
}
