import Button from '@global/Button'
import { getAlignmentClass } from '@lib/helpers'
import { Container } from '@styles/global.styled'
import { Link } from '@routes'
import * as S from './FullWidthText.styled'

const FullWidthText = ({ title, alignment, para, link, center }) => {
  return (
    <Container>
      <S.FullWidthText className={`${getAlignmentClass(alignment)}`}>
        {title && <S.FullWidthTextTitle>{title}</S.FullWidthTextTitle>}
        {para && (
          <S.FullWidthTextDescription
            dangerouslySetInnerHTML={{ __html: para }}
          />
        )}
        {link && (
          <S.FullWidthTextAction>
            <Link route={link.url} passHref>
              <Button color='secondary'>{link.label}</Button>
            </Link>
          </S.FullWidthTextAction>
        )}
      </S.FullWidthText>
    </Container>
  )
}

FullWidthText.defaultProps = {
  title: 'A brief history of Barry Plant',
  para: '',
  alignment: '',
}

FullWidthText.ApiBlockToProps = (data) => {
  let componentProps = {
    title: data.value.heading,
    alignment: data.value.text_alignment,
    para: data.value.content ? data.value.content : data.value.text,
    color: data.value.text_colour || null,
    link: null,
  }
  if (data.value.call_to_action_external_link) {
    componentProps.link = {
      label: data.value.call_to_action_text,
      url: data.value.call_to_action_external_link,
    }
  }
  if (data.value.call_to_action_link) {
    componentProps.link = {
      label: data.value.call_to_action_text,
      url: data.value.call_to_action_link.path,
    }
  }

  return componentProps
}
export default FullWidthText
