import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, text2XL, text3XL, textBase, textLG } from '@styles/fonts'
import { Link } from '@routes'

export const ArticlesPanelWrap = styled.div`
  
  .articles-panel-col-right{
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 360px;
    overflow-x: auto;
  }
  @media ${DEVICES.mobile} {
    .articles-panel-col-right{
      max-height: unset;
      overflow-x: unset;
      margin-top: 40px;
    }
  }
`

export const ViewMoreLink = styled(Link)`
  margin-left: 20px;

  @media ${DEVICES.mobile} {
    display: none;
    margin-left: 0;
  }
`

export const ViewMoreLinkMobile = styled(Link)`
  display: none;
  margin-left: auto;

  @media ${DEVICES.mobile} {
    display: block;
  }
`

export const ArticlesPanelHeadingWrap = styled.div`
  display: flex;
  margin-bottom: 48px;

  > div {
    margin-left: auto;

    ${ViewMoreLink} {
      display: inline-block;
    }
  }

  @media ${DEVICES.mobile} {
    > div {
      ${ViewMoreLink} {
        display: none;
      }
    }
  }
`

export const ArticlesPanelHeading = styled.h3`
  ${text3XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  text-transform: uppercase;
`



export const MainArticleTitle = styled.h4`
  ${text2XL};
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  text-transform: unset;

  @media ${DEVICES.mobile} {
    ${textLG};
  }
`


export const SubArticleTitle = styled.div`
  ${textBase};
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
  padding: 16px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: stretch;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 64px;
`

export const SubArticleWrap = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.nature[95]};
  //margin-bottom: 12px;
  position: relative;
  transition: all 0.25s ease-in;
  max-height: 76px;

  img {
    width: 141px;
    height: 76px;
    object-fit: cover;
    min-width: 141px;
  }

  
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:hover {
    background-color: ${COLORS.secondary};
    
    a,
    ${SubArticleTitle} {
      color: ${COLORS.white};
      cursor: pointer;
    }
  }

  @media ${DEVICES.mobile} {
    img {
      width: 110px;
      min-width: 110px;
    }
  }
`

export const MainArticleWrap = styled.div`
  img {
    height: 360px;
    width: 100%;
    object-fit: cover;
    border-radius: 0 0 100px 0;
  }
`
