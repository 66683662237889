import IconFacebookFilled from '@icons/FacebookFilled'
import IconInstagramFilled from '@icons/InstagramFilled'
import IconLinkedinFilled from '@icons/LinkedinFilled'
import IconTiktokFilled from '@icons/TiktokFilled'
import IconTwitter from '@icons/Twitter'
import IconWechat from '@icons/Wechat'
import IconWhatsapp from '@icons/Whatsapp'
import IconYoutube from '@icons/YoutubeFilled'

const getSocialLinksFromPageData = (pageData) => {
  let socialLinks = []
  if (pageData.facebook_url) {
    socialLinks.push({
      icon: <IconFacebookFilled />,
      url: pageData.facebook_url,
    })
  }
  if (pageData.instagram_url) {
    socialLinks.push({
      icon: <IconInstagramFilled />,
      url: pageData.instagram_url,
    })
  }
  if (pageData.youtube_url) {
    socialLinks.push({
      icon: <IconYoutube />,
      url: pageData.youtube_url,
    })
  }
  if (pageData.twitter_handle) {
    socialLinks.push({
      icon: <IconTwitter />,
      url: `https://twitter.com/${pageData.twitter_handle}/`,
    })
  }
  if (pageData.linkedin_url) {
    socialLinks.push({
      icon: <IconLinkedinFilled />,
      url: pageData.linkedin_url,
    })
  }
  if (pageData.tiktok_url) {
    socialLinks.push({
      icon: <IconTiktokFilled />,
      url: pageData.tiktok_url,
    })
  }
  if (pageData.wechat_url) {
    socialLinks.push({
      icon: <IconWechat />,
      url: pageData.wechat_url,
    })
  }
  if (pageData.whatsapp_url) {
    socialLinks.push({
      icon: <IconWhatsapp />,
      url: pageData.whatsapp_url,
    })
  }

  return socialLinks
}

export default getSocialLinksFromPageData
