import SVGIcon from '../../icon/svgIcon'

const ListingAuction = ({
  isDetailPage,
  liveAuctionUrl,
  liveAuctionRegisterUrl
}) => {
  return isDetailPage ? (
    <>
      {liveAuctionUrl && (
        <a href={liveAuctionUrl} target='_blank'>
          View Live Auction
        </a>
      )}
      {liveAuctionRegisterUrl && (
        <a href={liveAuctionRegisterUrl} target='_blank'>
          Register to Bid
        </a>
      )}
    </>
  ) : (
    <div className='c-listing-card__live-auction'>
      {liveAuctionUrl && (
        <a
          className='c-listing-card__live-auction__url'
          href={liveAuctionUrl}
          target='_blank'
        >
          <SVGIcon name='icon-play' />
          <span>View Live Auction</span>
        </a>
      )}
      {liveAuctionRegisterUrl && (
        <a
          className='c-listing-card__live-auction__register c-btn c-btn--primary c-btn--animated c-btn--small'
          href={liveAuctionRegisterUrl}
          data-text='Register to Bid'
          target='_blank'
        >
          <span>Register to Bid</span>
        </a>
      )}
    </div>
  )
}

ListingAuction.defaultProps = {
  isDetailPage: false
}

export default ListingAuction
