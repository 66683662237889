import { newlineBr } from '@lib/helpers'
import { Col, Container, Row } from '@styles/global.styled'
import Image from '@global/Image'
import Button from '@global/Button'
import { Link } from '@routes'
import IconArrowRight from '@icons/ArrowRight'
import * as S from './ImageAndText.styled'

const ImageAndText = ({
  image,
  heading,
  description,
  link,
  imageLocation,
  imageSize,
  contentSize,
  theme,
  isCenter,
}) => {
  const renderContent = () => (
    <div>
      <S.ImageAndTextTitle>
        {heading != null ? heading : null}
      </S.ImageAndTextTitle>
      <S.ImageAndTextContent>
        <p>{description}</p>
        {link.url ? (
          <Link route={link.url} target={link.target}>
            <Button endIcon={<IconArrowRight />} color='secondary'>
              {link.text != null ? link.text : null}
            </Button>
          </Link>
        ) : link.text ? (
          <Button
            endIcon={<IconArrowRight />}
            color='secondary'
            onClick={link.onClick}
          >
            {link.text}
          </Button>
        ) : null}
      </S.ImageAndTextContent>
    </div>
  )

  const renderImage = () =>
    isCenter ? (
      <S.ImageWrap>
        <Image alt={image?.alt} src={image?.url} />
      </S.ImageWrap>
    ) : (
      <Col md={imageSize}>
        <Image alt={image?.alt} src={image?.url} />
      </Col>
    )

  // If theme is not set, set it to transparent
  if (theme === '' || theme === undefined) {
    theme = 'transparent'
  }

  return (
    <S.ImageAndText imageLocation={imageLocation} theme={theme}>
      <Container>
        {isCenter ? (
          <S.ImageAndTextContentCenter>
            {imageLocation === 'L' && renderImage()}
            {renderContent()}
            {imageLocation === 'R' && renderImage()}
          </S.ImageAndTextContentCenter>
        ) : (
          <Row gutter={32}>
            {imageLocation === 'L' && renderImage()}
            <Col md={contentSize}>{renderContent()}</Col>
            {imageLocation === 'R' && renderImage()}
          </Row>
        )}
      </Container>
    </S.ImageAndText>
  )
}

ImageAndText.defaultProps = {
  image: {
    url: '/static/img/why_barry_plant.jpg',
    alt: 'Why Barry Plant Image',
  },
  heading: 'Why Barry Plant.',
  description:
    'For over 60 years, Barry Plant has helped buyers and sellers secure their ideal outcomes across all kinds of markets. With an experienced team of over 1000 people working together across a network of 75 zoffices our reach and local expertise is unmatched, earning us the top spot in numbers of properties sold for 10 years running...',
  link: {
    text: 'Find Out More',
    url: '#',
  },
  onClick: () => {},
}

const imagSizeMap = {
  '1/4': 3,
  '1/3': 4,
  '2/3': 8,
  '1/2': 6,
  '3/4': 9,
}

const imgContentSizeMap = {
  4: 8,
  3: 9,
  6: 6,
  9: 3,
  8: 4,
}

ImageAndText.ApiBlockToProps = (data) => {
  const { value, type } = data

  const imageSize = value.image_size ? imagSizeMap[value.image_size] : 8
  const contentSize = imgContentSizeMap[imageSize]
  const description = value.content ? newlineBr(value.content) : ''
  const isCenter = type === 'centered_image_and_text'

  let link = null
  let link_target = '_self'

  if (value.call_to_action_link && value.call_to_action_link.path) {
    link = value.call_to_action_link.path
  } else if (value.call_to_action_external_link) {
    link = value.call_to_action_external_link
    link_target = '_blank'
  }

  const componentProps = {
    data: data,
    heading: value.heading,
    description: description,
    image: {
      url: value.image,
      alt: value.heading,
    },
    link: {
      text: value.call_to_action_text,
      url: link,
      target: link_target,
      onClick: value.call_to_action_click,
    },
    imageLocation: value.image_location || 'R',
    imageSize,
    contentSize,
    theme: value.theme || value.background_color,
    isCenter: isCenter,
  }
  return componentProps
}

export default ImageAndText
