const IconTiktokFilled = (props) => {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.156494' width='18' height='18' rx='1.6' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.86 2.71387C12.0675 4.49781 13.0632 5.56139 14.7943 5.67453V7.681C13.7911 7.77906 12.9123 7.45094 11.8902 6.8324V10.5851C11.8902 15.3523 6.69301 16.8421 4.60357 13.4251C3.2609 11.2263 4.0831 7.36796 8.39021 7.21333V9.32917C8.06209 9.38197 7.71133 9.46494 7.39075 9.57432C6.43278 9.89867 5.88967 10.5059 6.04054 11.577C6.33094 13.6287 10.095 14.236 9.78191 10.2268V2.71764H11.86V2.71387Z'
        fill='white'
      />
    </svg>
  )
}

export default IconTiktokFilled
