import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'
import { FONTS, text2XL, text3XL, text4XL, textLG, textBase, textSM } from '@styles/fonts'
import { COLORS } from '@styles/colors'

export const SignUpBannerWrapper = styled.div`
  background: ${COLORS.nature[95]};
  border-radius: 0 0 80px 0;
  overflow: hidden;

  @media ${DEVICES.mobile} {
    max-height: unset;
  }
`

export const SignUpBannerImage = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 20px;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
    min-height: 396px;
  }

  @media ${DEVICES.mobile} {
    padding-right: 0;
    > img {
      min-height: unset;
    }
  }
`

export const SignUpBannerContent = styled.div`
  padding: 40px 40px 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;

  height: 100%;
  justify-content: center;

  > div:last-child {
    margin-bottom: 0;

    > div {
      margin-bottom: 0;
    }
  }

  ${({ noImage }) =>
    noImage &&
    css`
      padding-left: 40px;
    `}

  @media ${DEVICES.mobile} {
    padding: 32px 16px;
  }

  @media ${DEVICES.tablet} {
    padding: 32px 16px;
  }
`

export const Title = styled.h3`
  ${text3XL};
  overflow: hidden;
  color: ${COLORS.secondary};
  text-overflow: ellipsis;
  font-family: ${FONTS.bold};

  @media ${DEVICES.mobile} {
    ${text2XL};
  }

  @media ${DEVICES.smallDesktop} {
    ${text2XL};
  }
`

export const Description = styled.span`
  ${textBase};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};
  font-weight: 390;
  letter-spacing: 0.1px;
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-bottom: 28px;
  align-items: baseline;
  
  > div {
    width: 100%;
    margin-bottom: 0;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const BreakLine = styled.br`
  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const Form = styled.form`
  width: 100%;
`

export const LabelPrivacy = styled.span`
  ${textSM};
  color: ${COLORS.secondary};
  font-family: ${FONTS.regular};
  font-weight: 390;
  letter-spacing: 0.1px;
`

export const PrivacyWrap = styled.div`
    div{
      margin-bottom: 0;
    }
`


// Small Mode
export const SignUpBannerWrapperSmall = styled.div`
  background: ${COLORS.nature[95]};
  border-radius: 0 0 80px 0;
  overflow: hidden;

  ${BreakLine}{
    display: none;
  }

  ${SignUpBannerContent}{
    padding: 28px 24px;
  }

  ${Title}{
    ${textLG};
  }
  
  ${FormGroup}{
    flex-direction: column;
    gap: 16px;
  }
  
  :hover{
    background: ${COLORS.navi};
    cursor: pointer;

    input.privacy ~ span:before,
    input.privacy ~ span:after {
      border-color: ${COLORS.white};
    }

    ${Title},
    ${Description}, 
    ${LabelPrivacy}
    {
      color: ${COLORS.white};
      
      a {
        color: ${COLORS.white};
      }
    }
    
  }
`

export const IconWrap = styled.div`

`
