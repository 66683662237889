import SVGIcon from '../icon/svgIcon'

const SmallButtonsLarge = ({ title, blocks, blockColor }) => {
  return (
    <div className='c-small-buttons'>
      <div className='o-wrapper'>
        <h3 className='c-small-buttons__title u-h4'>{title}</h3>
        <div className='o-layout o-module c-small-buttons__items'>
          {blocks &&
            blocks.length > 0 &&
            blocks.map(block => (
              <>
                {block.large ? (
                  <div
                    className={`o-layout__item o-module__item u-1/2@desktop u-margin-bottom c-small-button c-small-button--large c-small-button--${blockColor}`}
                  >
                    <div className='c-small-button__inner o-module__content'>
                      <div className='c-small-button__icon'>
                        <SVGIcon name={block.icon} />
                      </div>
                      <h4
                        className='c-small-button__title u-title'
                        dangerouslySetInnerHTML={{ __html: block.title }}
                      />
                      <div className='c-small-button__content'>
                        <p className='c-small-button__copy1'>{block.copy1}</p>
                        <p className='c-small-button__copy2'>{block.copy2}</p>
                        <a
                          href={block.link}
                          className='c-link c-link--brand c-small-button__link'
                        >
                          {block.linkLabel}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`o-layout__item o-module__item u-1/2 u-1/4@desktop u-margin-bottom c-small-button c-small-button--with-copy c-small-button--${blockColor}`}
                  >
                    {block.copy ? (
                      <div className='c-small-button__inner o-module__content'>
                        <div className='c-small-button__icon'>
                          <SVGIcon name={block.icon} />
                        </div>
                        <h4
                          className='c-small-button__title u-title'
                          dangerouslySetInnerHTML={{ __html: block.title }}
                        />
                        <div className='c-small-button__content'>
                          <p className='c-small-button__copy'>{block.copy}</p>
                          <a href={block.link} className='c-small-button__link'>
                            {block.linkLabel}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <a
                        href={block.link}
                        className='c-small-button__inner o-module__content'
                      >
                        <div className='c-small-button__icon'>
                          <SVGIcon name={block.icon} />
                        </div>
                        <h4 className='c-small-button__title u-title'>{block.title}</h4>
                      </a>
                    )}
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  )
}

SmallButtonsLarge.defaultProps = {
  title: 'Buyer information',
  blockColor: 'blue',
  blocks: []
}
SmallButtonsLarge.ApiBlockToProps = (data, type) => {
  let rawData = data.value
  let blocks = [],
    title = '',
    blockColor = 'blue'

  switch (type) {
    case 'link_panels': {
      title = rawData.category_name || rawData.title
      blocks = rawData.panels.map(item => {
        let buttonData = {
          title: item.title,
          icon: item.icon,
          linkLabel: item.call_to_action_text,
          link: item.call_to_action_link
            ? item.call_to_action_link.path
            : item.call_to_acttion_url
            ? item.call_to_acttion_url
            : '#'
        }
        if (item.hover_text) {
          buttonData.copy = item.hover_text
        }
        return buttonData
      })
      break
    }
    case 'calculators_section': {
      blockColor = 'red'
      title = rawData.category_name || rawData.title
      blocks = rawData.calculators.map(item => {
        let buttonData = {
          title: item.calculator && item.calculator.title,
          icon: item.icon,
          linkLabel: item.call_to_action_text,
          link: item.calculator ? item.calculator.path : '#'
        }
        if (item.hover_text) {
          buttonData.copy = item.hover_text
        }
        return buttonData
      })
      break
    }
  }

  return {
    title: title,
    blocks: blocks,
    blockColor: blockColor
  }
}
export default SmallButtonsLarge
