import HTML from '../global/HTML'
import Accordian from '../global/accordion/Accordion'

const CareerIntroType = ({
  title,
  intro,
  image,
  content,
  accordionGroup,
  register,
  vacancies,
  scrollToForm,
}) => {
  return (
    <div className='c-careers-intro-block__item'>
      <div className='c-careers-intro-block__item-hd'>
        <h2 className='u-h4'>{title}</h2>
      </div>
      <div className='c-careers-intro-block__item-bd'>
        <div className='o-layout'>
          <div className='o-layout__item u-4/12@tablet u-5/12@desktop'>
            <div className='c-careers-intro-block__item-intro'>
              <div className='o-layout'>
                <div className='o-layout__item'>
                  <p dangerouslySetInnerHTML={{ __html: intro }} />
                </div>
                <div className='o-layout__item'>
                  <img src={image} alt='' />
                </div>
              </div>
            </div>
          </div>

          <div className='o-layout__item u-8/12@tablet u-7/12@desktop'>
            <div className='c-careers-intro-block__item-content'>
              <HTML html={content} />
              {accordionGroup && (
                <div className='u-margin-bottom-large'>
                  <Accordian accordionGroup={accordionGroup} />
                </div>
              )}
            </div>

            <div className='c-careers-intro-block__item-cta'>
              <a
                href='javascript:void(0)'
                onClick={scrollToForm ? () => scrollToForm() : null}
                className='c-link c-link--brand'
              >
                register your interest
              </a>
              <a href={vacancies.link} className='c-link c-link--brand'>
                {vacancies.label}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerIntroType
