import Image from '@global/Image'
import * as S from './Award.styled'

const Award = ({ award }) => {
  return (
    <S.AwardCard>
      <S.AwardCardImage>
        <Image src={award?.image} alt={award?.headline} />
      </S.AwardCardImage>
      <S.AwardCardTitle>{award?.headline}</S.AwardCardTitle>
      <S.AwardCardDes>{award?.years}</S.AwardCardDes>
    </S.AwardCard>
  )
}

export default Award
