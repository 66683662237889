import { useState } from 'react'
import IconMinus from '@icons/Minus'
import IconPlus from '@icons/Plus'
import { Container, Row } from '@styles/global.styled'
import Button from '@global/Button'
import * as S from './GridItems.styled'

const ToggleButton = ({ toggleAll, isViewAll }) => (
  <Button
    onClick={toggleAll}
    color='secondary'
    border
    endIcon={isViewAll ? <IconMinus /> : <IconPlus />}
  >
    {isViewAll ? 'View Less' : 'View More'}
  </Button>
)

const GridItems = ({
  title,
  items,
  renderItem,
  minVisibleDesktopItems,
  minVisibleMobileItems,
  itemMarginBottom,
  itemSize,
  style,
}) => {
  const [isViewAll, setIsViewAll] = useState(false)
  if (!items?.length) return null

  const toggleAll = () => {
    setIsViewAll(!isViewAll)
  }

  return (
    <S.GridItems style={style}>
      <Container>
        <S.GridItemsHeading>
          {title}{' '}
          {items.length > minVisibleDesktopItems && (
            <ToggleButton toggleAll={toggleAll} isViewAll={isViewAll} />
          )}
        </S.GridItemsHeading>
        <S.GridItemsList itemMarginBottom={itemMarginBottom}>
          <Row gutter={22}>
            {items.map((item, i) => (
              <S.ItemWrap
                md={itemSize}
                key={i}
                isVisibleDesktop={i < minVisibleDesktopItems || isViewAll}
                isVisibleMobile={i < minVisibleMobileItems || isViewAll}
              >
                {renderItem(item)}
              </S.ItemWrap>
            ))}
          </Row>
        </S.GridItemsList>
        {items.length > minVisibleMobileItems && (
          <S.ButtonWrap>
            <ToggleButton toggleAll={toggleAll} isViewAll={isViewAll} />
          </S.ButtonWrap>
        )}
      </Container>
    </S.GridItems>
  )
}

export default GridItems
