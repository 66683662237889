import 'isomorphic-unfetch'

export function checkStatus(response) {
  if (!response.ok && response.statusText) {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  } else if (!response.ok && response.non_field_errors) {
    const error = new Error(response.non_field_errors)
    error.response = response
    throw error
  }
  return response
}

export function parseJSON(response) {
  return response.json().then(resJson => {
    if (typeof resJson == 'string') {
      resJson = {
        data: resJson
      }
    }
    resJson.responseStatus = response.status
    resJson.ok = response.ok
    return resJson
  })
}

export function apiCall(
  url,
  data,
  method = 'get',
  hasFile = false,
  token = null
) {
  process.env.NODE_ENV !== 'production' &&
    console.log('-------api called-----', url)
  let fetchOptions = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: method
  }
  let formattedData
  if (data) {
    if (hasFile) {
      // File uploads can't send as json - change to form
      let form_data = new FormData()
      for (var key in data) {
        form_data.append(key, data[key])
      }
      formattedData = form_data
      delete fetchOptions.headers['Content-Type']
    } else {
      formattedData = JSON.stringify(data)
    }
  }

  if (token) {
    fetchOptions.headers['Authorization'] = `JWT ${token}`
    fetchOptions.headers.cookie = `jwt=${token}`
  }

  if (formattedData) {
    fetchOptions.body = formattedData
  }
  return fetch(url, fetchOptions)
    .then(response => {
      return checkStatus(response)
    })
    .then(response => {
      let res = parseJSON(response)
      if (response.status >= 200 && response.status <= 299) {
        res.ok = true
      }

      if (response.status >= 400 && response.status <= 499) {
        res.ok = false
      }
      return res
    })
    .catch(error => {
      const response = error.response
      if (response) {
        error.status = response.status
        error.statusText = response.statusText
      }
      return error
    })
}
