import React from 'react'
import * as S from './ImageInfo.styled'

const ImageInfo = (props) => {
  const { title, caption, isShare=false } = props
  return (
    <S.ImageInfoWrap>
      <S.ImageInfoTitle>{title}</S.ImageInfoTitle>
      {!isShare && caption && (
        <S.ImageInfoCaption>{caption}</S.ImageInfoCaption>
      )}
    </S.ImageInfoWrap>
  )
}

ImageInfo.defaultProps = {
  title: "",
  caption: "",
  isShare: false,
}

export default ImageInfo
