// import Pagination from "../pagination/Pagination";
import ResourceCard from '../../global/resource-card/ResourceCard'
import { Link } from '../../../routes'
import { defaultImages } from '../../../lib/variables'

const ResourceTile = ({
  resources,
  cta_link,
  cta_text,
  cta_url,
  resources_count,
  resources_category,
}) => {
  return resources && resources.length ? (
    <div className='c-resource-tile block-padding'>
      <div className='o-wrapper'>
        <div className='c-resource-tile__header'>
          <div className='c-resource-tile__title'>
            <h4 className='u-h4'>Resources</h4>
          </div>
        </div>

        <div className='o-layout o-layout--stretch u-margin-bottom'>
          {resources &&
            resources.length > 0 &&
            resources.map((resource, key) => (
              <div
                key={`resource-${key}`}
                className='o-layout__item u-1/2@largepalm u-1/3@desktop o-island'
              >
                <ResourceCard
                  name={resource.name}
                  slug={resource.slug}
                  description={resource.description}
                  imgUrl={resource.imgUrl}
                  link={resource.link}
                  type={resource.type}
                  downloadLink={resource.downloadLink}
                />
              </div>
            ))}
        </div>

        {(cta_link && cta_link.path) || cta_url ? (
          <div className='u-margin-top u-text-right'>
            <Link
              route={cta_link.path ? cta_link.path : cta_url}
              passHref
              className='c-link c-link--brand'
            >
              ..{cta_text || 'view more'}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  ) : null
}
ResourceTile.defaultProps = {
  resources: [],
}
ResourceTile.ApiBlockToProps = (data, type) => {
  if (!data.value) return {}
  const {
    call_to_action_link,
    call_to_action_text,
    call_to_acttion_url,
    number_of_resources,
    resource_category,
    resources,
  } = data.value
  let resourcesData = (resources || []).map((item, key) => {
    return {
      slug: item.path || '',
      name: item.title || '',
      link: item.resource_path || '',
      imgUrl: item.image_results || defaultImages.mediumNavy,
      description: item.description || '',
      type: item.resource_type || '',
    }
  })
  return {
    resources: resourcesData,
    cta_link: call_to_action_link,
    cta_text: call_to_action_text,
    cta_url: call_to_acttion_url,
    resources_count: number_of_resources,
    resources_category: resource_category,
  }
}
export default ResourceTile
