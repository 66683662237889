import { Col, Container, Row } from '@styles/global.styled'
import * as S from './TwoColumnText.styled'

const TwoColumnText = ({
  column1Title,
  column1Content,
  column2Title,
  column2Links,
}) => {
  return (
    <Container>
      <S.TwoColumnText>
        <S.Col1>
          <S.Col1Title>{column1Title}</S.Col1Title>
          <S.Col1Content dangerouslySetInnerHTML={{ __html: column1Content }} />
        </S.Col1>
        <S.Col2>
          <S.Col2Title>{column2Title}</S.Col2Title>
          {column2Links?.length > 0 && (
            <S.Col2Links>
              {column2Links.map((it, index) => (
                <S.Col2LinksItem key={`column2Links-${index}`}>
                  {it.link ? (
                    <a href={it.link} target='_blank'>
                      {it.name}
                    </a>
                  ) : (
                    <span>{it.name}</span>
                  )}
                </S.Col2LinksItem>
              ))}
            </S.Col2Links>
          )}
        </S.Col2>
      </S.TwoColumnText>
    </Container>
  )
}

TwoColumnText.ApiBlockToProps = (data) => {
  const { value } = data

  return {
    column1Title: value.column_1_title,
    column1Content: value.column_1_text,
    column2Title: value.column_2_title,
    column2Links: value.column_2_links,
  }
}

export default TwoColumnText
