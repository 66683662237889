import { Col, Container, Row, SectionTitle } from '@styles/global.styled'
import { getCTALink } from '@lib/helpers'
import PostCard from '@global/PostCard'

const MultiImageAndText = ({ value }) => {
  const { panels, title } = value

  return (
    <Container>
      {title && <SectionTitle>{title}</SectionTitle>}
      <Row gutter={15}>
        {panels?.length > 0 &&
          panels.map((it, key) => {
            const link = getCTALink(it)
            return (
              <Col key={key} lg={4} md={6} flex>
                <PostCard
                  key={key}
                  heading={it.heading}
                  imgUrl={it.image}
                  link={link}
                  content={it.content}
                  call_to_action_link={it.call_to_action_link}
                />
              </Col>
            )
          })}
      </Row>
    </Container>
  )
}

export default MultiImageAndText
