const IconWechat = (props) => {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.156494' width='18' height='18' rx='1.6' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.29945 2.71387C4.22217 2.71387 1.72803 4.80482 1.72803 7.38326C1.72803 8.86999 2.56188 10.1886 3.85538 11.0422L3.12088 12.5196L5.12195 11.6576C5.55095 11.8108 5.99667 11.9368 6.47302 11.9957C6.40802 11.7108 6.37088 11.4185 6.37088 11.1188C6.37088 8.54406 8.86967 6.44938 11.9423 6.44938C12.2237 6.44938 12.4985 6.47272 12.7697 6.50634C12.2794 4.34722 10.0183 2.71387 7.29945 2.71387ZM5.44231 6.21591C5.05788 6.21591 4.74588 5.90213 4.74588 5.5155C4.74588 5.12888 5.05788 4.8151 5.44231 4.8151C5.82674 4.8151 6.13874 5.12888 6.13874 5.5155C6.13874 5.90213 5.82674 6.21591 5.44231 6.21591ZM8.46017 5.5155C8.46017 5.90213 8.77217 6.21591 9.1566 6.21591C9.54103 6.21591 9.85303 5.90213 9.85303 5.5155C9.85303 5.12888 9.54103 4.8151 9.1566 4.8151C8.77217 4.8151 8.46017 5.12888 8.46017 5.5155Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5853 11.1183C16.5853 9.05539 14.5062 7.38281 11.9424 7.38281C9.37863 7.38281 7.29956 9.05539 7.29956 11.1183C7.29956 13.1813 9.37863 14.8538 11.9424 14.8538C12.364 14.8538 12.7651 14.7941 13.1542 14.7091L15.6567 15.7877L14.7913 14.0479C15.8758 13.3643 16.5853 12.3128 16.5853 11.1183ZM10.5496 10.8849C10.1651 10.8849 9.85313 10.5711 9.85313 10.1844C9.85313 9.79782 10.1651 9.48404 10.5496 9.48404C10.934 9.48404 11.246 9.79782 11.246 10.1844C11.246 10.5711 10.934 10.8849 10.5496 10.8849ZM12.6388 10.1844C12.6388 10.5711 12.9508 10.8849 13.3353 10.8849C13.7197 10.8849 14.0317 10.5711 14.0317 10.1844C14.0317 9.79782 13.7197 9.48404 13.3353 9.48404C12.9508 9.48404 12.6388 9.79782 12.6388 10.1844Z'
        fill='white'
      />
    </svg>
  )
}

export default IconWechat
