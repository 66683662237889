import SVGIcon from '../icon/svgIcon'
import { defaultImages } from '../../../lib/variables'

const ResourceCard = ({ link, imgUrl, name, description, type, slug }) => {
  return (
    <div className='c-resource-card'>
      <div className='c-resource-card__img-wrap'>
        <a target='_blank' className='c-resource-card__img-link' href={link}>
          <img src={imgUrl || defaultImages.mediumNavy} alt='' className='c-resource-card__img' />
        </a>
      </div>
      <div className='c-resource-card__content'>
        <h3 className='c-resource-card__name'>
          <a target='_blank' href={link} className='c-resource-card__name-link'>
            {name}
          </a>
        </h3>
        <div className='c-resource-card__description'>{description}</div>
        {link && (
          <div className='c-resource-card__link-wrap'>
            <a
              target='_blank'
              href={link}
              className='c-resource-card__download-link'
            >
              -{' '}
              {type == 'link'
                ? 'Go To Link'
                : type == 'file'
                ? 'Go To File'
                : 'Find Out More'}
            </a>
            {type == 'link' || type == 'file' ? (
              <a
                target='_blank'
                href={link}
                className='c-resource-card__download-icon'
              >
                <SVGIcon
                  name={
                    type == 'link'
                      ? 'attachment'
                      : type == 'file'
                      ? 'download-doc'
                      : ''
                  }
                />
              </a>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

ResourceCard.defaultProps = {}

export default ResourceCard
