const IconClose = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      style={{ ...props.style, cursor: 'pointer' }}
    >
      <path
        d='M15.9232 3L10 8.92325L4.07677 3L3 4.07677L8.92323 10L3 15.9233L4.07677 17L10 11.0768L15.9232 17L17 15.9233L11.0768 10L17 4.07677L15.9232 3Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconClose
