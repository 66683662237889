const IconYoutube = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.6049 0C0.718538 0 0 0.718537 0 1.6049V14.3951C0 15.2815 0.718537 16 1.6049 16H14.3951C15.2815 16 16 15.2815 16 14.3951V1.6049C16 0.718538 15.2815 0 14.3951 0H1.6049ZM4.66084 12.208L11.8608 8.10396L4.66084 3.99996L4.66084 12.208Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconYoutube
