import IconGoogle from '@icons/Google'
import IconStar from '@icons/Star'
import IconStarHalf from '@icons/StarHalf'
import * as S from './Testimonial.styled'

const renderRating = (rating) => {
  const items = [];
  for (let i = 0; i < Math.floor(rating); i++) {
    items.push(<IconStar />);
  }
  if (rating % 1 !== 0) {
    items.push(<IconStarHalf />);
  }
  return items;
}

const renderReview = (testimonial_source) => {
  return (
    <S.VerifyWrap>
      {testimonial_source === 'Google Maps' ? (
        <>
          <IconGoogle /> Verified Review
        </>
      ) : (
        <>Verified Review - {testimonial_source}</>
      )}
    </S.VerifyWrap>
  )
}

const Testimonial = ({ testimonial }) => {
  return (
    <S.Testimonial>
      {testimonial.rating && (
        <S.TestimonialRating>
          {renderRating(testimonial.rating)} <span>{testimonial.rating}</span>
        </S.TestimonialRating>
      )}
      <S.TestimonialAuthor>{testimonial.name}</S.TestimonialAuthor>
      <S.TestimonialDes>{testimonial.copy}</S.TestimonialDes>
      <S.TestimonialFooter>
        {renderReview(testimonial?.testimonial_source)}
      </S.TestimonialFooter>
    </S.Testimonial>
  )
}

export default Testimonial
