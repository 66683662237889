const IconInfo = (props) => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='7.5' cy='7.5' r='7' fill='white' stroke='currentColor' />
      <path
        d='M6.72816 12V5.45455H8.27077V12H6.72816ZM7.50373 4.52557C7.25941 4.52557 7.04918 4.4446 6.87305 4.28267C6.69691 4.1179 6.60884 3.92045 6.60884 3.69034C6.60884 3.45739 6.69691 3.25994 6.87305 3.09801C7.04918 2.93324 7.25941 2.85085 7.50373 2.85085C7.75089 2.85085 7.96112 2.93324 8.13441 3.09801C8.31055 3.25994 8.39862 3.45739 8.39862 3.69034C8.39862 3.92045 8.31055 4.1179 8.13441 4.28267C7.96112 4.4446 7.75089 4.52557 7.50373 4.52557Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconInfo
