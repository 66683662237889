import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'

export const NoResults = styled.div`
  max-width: 500px;
  margin-bottom: 190px;

  h3 {
    font-family: ${FONTS.medium};
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px;
    text-transform: none;
  }

  p {
    color: ${COLORS.navi};
    margin-bottom: 36px;
    max-width: 380px;
  }
`
