import ListingCard from '@global/ListingCard'
import { listingPreviewTabs } from '@lib/variables'
import { useState } from 'react'
import { Container } from '@styles/global.styled'
import Button from '@global/Button'
import Select from '@global/FormInput/Select'
import IconArrowRight from '@icons/ArrowRight'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'
import * as S from './ListingPreview.styled'

const Carousel = dynamic(() => import('@global/Carousel'))

const ListingPreview = ({
  items,
  title,
  viewMore,
  isFeatured,
  globalViewMore,
  showSelectOptions = true,
}) => {
  if (Object.keys(items).length === 0) return null

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '200px 0px 200px 0px',
  })

  const [selectedItem, setSelectedItem] = useState(
    Object.keys(items)?.[0] || null
  )

  const options = Object.keys(items).map((key) => ({
    label: listingPreviewTabs?.[key]?.text || key,
    value: key,
  }))

  const link = viewMore?.[selectedItem] || globalViewMore

  return (
    <S.ListingPreview ref={ref}>
      {inView && (
        <Container>
          <S.ListingPreviewHeader>
            {items && title && <h3>{title}</h3>}
            <div>
              {Object.keys(items).length > 1 && showSelectOptions && (
                <Select
                  defaultValue={options[0]}
                  options={options}
                  onChange={({ value }) => setSelectedItem(value)}
                />
              )}
              {link?.url && (
                <S.ViewMoreLink href={link?.url} passHref>
                  <Button
                    border
                    color='secondary'
                    endIcon={<IconArrowRight />}
                    size='lg'
                  >
                    {link?.label}
                  </Button>
                </S.ViewMoreLink>
              )}
            </div>
          </S.ListingPreviewHeader>
          {selectedItem && items[selectedItem] && (
            <Carousel>
              {items[selectedItem].map((item, index) => {
                return (
                  <ListingCard
                    key={index}
                    quickload
                    {...ListingCard.ApiDataToProps(item)}
                    isFeatured={isFeatured}
                  />
                )
              })}
            </Carousel>
          )}
          {/* Mobile Link */}
          {link?.url && (
            <S.ViewMoreLinkWrap>
              <S.ViewMoreLink href={link?.url} passHref>
                <Button border color='secondary' endIcon={<IconArrowRight />}>
                  {link?.label}
                </Button>
              </S.ViewMoreLink>
            </S.ViewMoreLinkWrap>
          )}
        </Container>
      )}
    </S.ListingPreview>
  )
}

ListingPreview.defaultProps = {
  isFeatured: false,
  items: {
    sold: [],
  },
}

ListingPreview.ApiBlockToProps = (data, block_type) => {
  //parse raw data from api
  let props = {},
    title = null,
    viewMore = {},
    itemPerPage = 3,
    properties_set = false,
    isFeatured = false,
    globalViewMore = { url: null, label: 'View more' }

  switch (block_type) {
    case 'recent_properties': {
      if (data.value) {
        title = data.value.title || 'Property Listings'
        Object.entries(data.value).map(([key, value]) => {
          if (key == 'rent' && !data.value.sales) {
            props.rent = data.value[key]
            properties_set = true
          } else if (key == 'sales' && !data.value.rent) {
            props.curSale = data.value[key]
            properties_set = true
            if (data.value.sales_link) {
              viewMore.curSale = data.value.sales_link
            }
            // We filter out our suburbs we're filtering by as it comes pre-filtered
          } else if (
            typeof value == 'object' &&
            value &&
            value.length &&
            key != 'suburbs'
          ) {
            props[key] = data.value[key]
            properties_set = true
            if (key === 'offmarket_current' && data.value.exclusive_link) {
              viewMore.offmarket_current = data.value.exclusive_link
            } else if (key === 'auctions' && data.value.auction_link) {
              viewMore.auctions = data.value.auction_link
            } else if (data.value[`${key}_link`]) {
              viewMore[key] = data.value[`${key}_link`]
            }
          }
        })
      }
      if (properties_set) {
        globalViewMore = {
          url:
            data.value.call_to_acttion_url ||
            data.value.call_to_action_link?.path ||
            null,
          label: data.value.call_to_action_text || 'View more',
        }
      }
      break
    }
    case 'featured_properties': {
      title = data?.value?.featured_sections[0]?.label
      itemPerPage = 2
      viewMore = null
      isFeatured = true
      if (data.value) {
        data.value?.featured_sections.map((prop) => {
          props[prop.label] = prop.properties
        })
      }
      break
    }
    case 'single_type_recent_properties': {
      title = 'Property Listings'
      viewMore = {
        url:
          data.value.call_to_acttion_url ||
          data.value.call_to_action_link?.path ||
          null,
        label: data.value.call_to_action_text || 'View more',
      }
      props[data.value.status] = data.value.listings
    }
  }

  return {
    data,
    items: props,
    title,
    viewMore,
    globalViewMore,
    itemPerPage,
    isFeatured: isFeatured,
  }
}
export default ListingPreview
