import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const CloseButton = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  font-family: ${FONTS.medium};
  color: ${COLORS.white};

  svg {
    margin-left: 10px;
  }
`

export const FiltersForm = styled.div`
  width: 100%;
  max-height: calc(100vh - 6rem);
  max-width: 686px;
  box-shadow: 4px 4px 24px 0px rgba(0, 23, 135, 0.1);
  background-color: ${COLORS.white};
  margin: 4rem auto 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const FiltersFormBody = styled.div`
  padding-top: 36px;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
`

export const FiltersFormTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.nature[95]};
  width: 100%;
`

export const FiltersFormTab = styled.div`
  padding: 20px 24px 18px;
  text-transform: uppercase;
  color: ${COLORS.secondary};
  letter-spacing: 1.4px;
  font-family: ${FONTS.bold};
  border-bottom: 2px solid transparent;
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;

  span:last-child {
    display: none;
  }

  @media ${DEVICES.mobile} {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 12px 0 10px;

    span:first-child {
      display: none;
    }

    span:last-child {
      display: block;
    }
  }

  ${({ active }) =>
    active &&
    css`
      color: ${COLORS.primary};
      border-color: ${COLORS.primary};
    `}
`

export const FiltersFormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;
  border-top: 1px solid ${COLORS.grey[20]};
  background-color: ${COLORS.white};
  position: sticky;
  bottom: 0;
`

export const ClearFilters = styled.span`
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  text-decoration: underline;
  transition: all 0.25s;
  cursor: pointer;

  &:hover {
    color: ${COLORS.primary};
  }
`

export const Tags = styled.div`
  padding-top: 16px;
  padding-bottom: 18px;
  margin-bottom: -10px;

  @media ${DEVICES.mobile} {
    margin-bottom: 0;
  }

  ${({ mbXS }) =>
    mbXS &&
    css`
      @media ${DEVICES.mobile} {
        margin-bottom: ${mbXS}px;
      }
    `}
`

export const Tag = styled.span`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${COLORS.nature[95]};
  color: ${COLORS.secondary};
  padding: 6px 14px;
  border-radius: 20px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      background-color: ${COLORS.secondary};
      color: ${COLORS.white};
    `}
`

export const FiltersFormMobileHeading = styled.div`
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.nature[95]};
  padding: 17px 20px;
  color: ${COLORS.secondary};
  display: none;

  svg {
    width: 26px;
    height: 26px;
  }

  @media ${DEVICES.mobile} {
    display: flex;

    ${CloseButton} {
      position: relative;
      top: unset;
      right: unset;
      display: flex !important;
      color: ${COLORS.secondary};
    }
  }

  span {
    text-transform: uppercase;
    font-family: ${FONTS.bold};
    letter-spacing: 1.4px;
  }
`

export const FiltersFormWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  display: none;
  background: rgba(0, 0, 0, 0.6);

  ${({ open }) =>
    open &&
    css`
      display: block;
    `}

  @media ${DEVICES.mobile} {
    padding: 0;

    ${FiltersForm} {
      max-width: 100%;
      max-height: 100%;
      margin: 0;
      height: 100vh;
    }

    ${FiltersFormBody} {
      padding-top: 40px;
    }

    ${CloseButton} {
      display: none;
    }
  }
`
