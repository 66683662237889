import { Link } from '@routes'
import IconArrowRightCircle from '@global/Icons/ArrowRightCircle'
import Button from '@global/Button'
import IconArrowRight from '@global/Icons/ArrowRight'
import IconDownload from '@icons/Download'
import * as S from './ImageTextCTA.styled'

const renderCTAButton = (button) => {
  const linkPath =
    button.document_link?.url ||
    button.call_to_action_external_link ||
    button.call_to_action_link?.path

  const endIcon = button.document_link?.url ? (
    <IconDownload />
  ) : (
    <IconArrowRight />
  )

  return linkPath ? (
    <Link route={linkPath} passHref>
      <Button color='secondary' endIcon={endIcon}>
        {button.call_to_action_text}
      </Button>
    </Link>
  ) : (
    <Button color='secondary' endIcon={endIcon}>
      {button.call_to_action_text}
    </Button>
  )
}

const renderCTALinkItem = (link, index) => {
  const linkPath =
    link.document_link?.url ||
    link.call_to_action_external_link ||
    link.call_to_action_link?.path

  const endIcon = link.document_link?.url ? (
    <IconDownload />
  ) : (
    <IconArrowRightCircle />
  )

  return (
    <li key={`cta-${index}`}>
      {linkPath ? (
        <Link route={linkPath} passHref download={!!link.document_link}>
          <span>{link.call_to_action_text}</span>
          {endIcon}
        </Link>
      ) : (
        <>
          <span>{link.call_to_action_text}</span>
          {endIcon}
        </>
      )}
    </li>
  )
}

const renderCTALinks = (links) => {
  if (links?.length === 0) return null
  return <ul>{links.map((link, index) => renderCTALinkItem(link, index))}</ul>
}

const ImageTextCTA = ({ value }) => {
  const {
    heading,
    image,
    description,
    sub_heading,
    call_to_action_links,
    background_image,
  } = value

  let ctaButton
  const isSingleLink = call_to_action_links?.length === 1
  if (isSingleLink) {
    ctaButton = call_to_action_links[0]
  }
  const ctaLinkDes = isSingleLink
    ? ctaButton.call_to_action_description
    : description

  return (
    <S.ImageTextCTA bgImage={image || background_image}>
      <S.CTAContent>
        <S.CTAContentTitle>{heading}</S.CTAContentTitle>
        <S.CTAContentDes>{sub_heading}</S.CTAContentDes>
      </S.CTAContent>
      <S.CTALinks>
        {ctaLinkDes && <S.CTALinkDes>{ctaLinkDes}</S.CTALinkDes>}
        {isSingleLink
          ? renderCTAButton(ctaButton)
          : renderCTALinks(call_to_action_links)}
      </S.CTALinks>
    </S.ImageTextCTA>
  )
}

export default ImageTextCTA
