const IconEmail = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 4.66634C2 4.31272 2.14048 3.97358 2.39052 3.72353C2.64057 3.47348 2.97971 3.33301 3.33333 3.33301H12.6667C13.0203 3.33301 13.3594 3.47348 13.6095 3.72353C13.8595 3.97358 14 4.31272 14 4.66634M2 4.66634V11.333C2 11.6866 2.14048 12.0258 2.39052 12.2758C2.64057 12.5259 2.97971 12.6663 3.33333 12.6663H12.6667C13.0203 12.6663 13.3594 12.5259 13.6095 12.2758C13.8595 12.0258 14 11.6866 14 11.333V4.66634M2 4.66634L8 8.66634L14 4.66634'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconEmail
