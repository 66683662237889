const IconArrowRightCircle = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='18' cy='18' r='17.5' stroke='#001FB9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4672 24.6666L24.6211 17.9999L18.4672 11.3333L17.484 12.2409L22.1871 17.336L11.3334 17.336L11.3334 18.6741L22.1776 18.6741L17.484 23.7589L18.4672 24.6666Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconArrowRightCircle
