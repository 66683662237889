import styled from 'styled-components'
import { DEVICES } from '@constants/global'
import { Link } from '@routes'

export const ListingPreview = styled.div``

export const ViewMoreLinkWrap = styled.div`
  text-align: right;
`

export const ViewMoreLink = styled(Link)`
  margin-left: 20px;
  display: none;

  @media ${DEVICES.mobile} {
    display: inline-block;
    margin-left: 0;
  }
`

export const ListingPreviewHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  z-index: 9;

  > div {
    display: flex;
    align-items: center;
    margin-left: auto;

    > div {
      min-width: 190px;
    }

    ${ViewMoreLink} {
      display: inline-block;
    }
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;
    align-items: flex-start;

    h3,
    h2 {
      margin-bottom: 20px;
    }

    > div {
      margin-left: none;
      width: 100%;
      flex: 1;

      > div {
        min-width: 100%;
        margin-bottom: 6px;
      }

      ${ViewMoreLink} {
        display: none;
      }
    }
  }
`
