// variables
// ---------

var exports = (module.exports = {})

// API Keys
exports.ApiKeys = {
  TAG_MANAGER_CODE: process.env.NEXT_PUBLIC_TAG_MANAGER_CODE,
  GTAG: process.env.NEXT_PUBLIC_GTAG,
  ANALYTICS_ID: process.env.NEXT_PUBLIC_ANALYTICS_ID,
  MAPBOX_KEY: process.env.NEXT_PUBLIC_MAPBOX_KEY,
  RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
  FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
  GOOGLE_OAUTH_ID: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_ID,
  GOOGLE_MAP_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
  SENTRY_DSN: process.env.SENTRY_DSN,
  PROPPS_APP_ID: process.env.NEXT_PUBLIC_PROPPS_APP_ID,
}

exports.PROPPS_ENDPOINT =
  process.env.NEXT_PUBLIC_PROPPS_ENDPOINT || 'https://script.propps.com/v1'

exports.FacebookUserFields = 'first_name,last_name,name,short_name,email'

// hostname
// TODO: Fix hostname/domain
// ! exports.hostname and exports.domain are doing the same thing
// ! one of them needs to be removed
exports.hostname = function () {
  if (process.env.NODE_ENV === 'production') return 'www.barryplant.com.au'
  else return 'localhost:3000'
}

exports.domain =
  process.env.NODE_ENV === 'production'
    ? `https://www.barryplant.com.au`
    : `http://localhost:3000`

// recent properties tab label
exports.listingPreviewTabs = {
  sold: {
    text: 'Sold',
  },
  rent: {
    text: 'Current Rentals',
  },
  leased: {
    text: 'Leased',
  },
  sales: {
    text: 'Current Sales',
  },
  curSale: {
    text: 'Current Sales',
  },
  auctions: {
    text: 'Auctions',
  },
  sale_by_set_date: {
    text: 'Sale by SET DATE<sup>&reg;</sup>',
  },
  upcoming_inspections: {
    text: 'Open for Inspections',
  },
  saved: {
    text: 'Your saved properties',
  },
  offmarket_current: {
    text: 'Exclusive Preview',
  },
  offmarket_sold: {
    text: 'Exclusive properties (sold)',
  },
  offmarket_leased: {
    text: 'Exclusive properties (leased)',
  },
}

exports.itemShowPerPage = {
  BarryPlantTVIndexPage: 10,
  ResourcesIndexPage: 100,
  BlogIndexPage: 12,
  CareerIndexPage: 12,
  TestimonialIndexPage: 15,
  AwardIndexPage: 15,
  ProjectsSearchListPage: 10,
  AgentIndexPage: 15,
  RegionIndexPage: 100,
  LifestyleCategoryIndex: 100,
  LifestyleCategoryPage: 100,
  PropertyMapSearch: 200,
  ListingSearchPage: 10,
  VacantShowcasePage: 6,
}

exports.apiFieldsQuery = {
  SuburbPage: [
    'title',
    'state',
    'postcode',
    'preamble',
    'featured_image_thumb',
    'map_marker',
    'path',
  ],
  BlogPost: [
    'title',
    'article',
    'path',
    'header_image_results',
    'date',
    'first_published_at',
    'show_date',
  ],
  BlogPostBasic: ['title', 'path', 'header_image_results'],
  RegionIndexPage: [
    'title',
    'path',
    'hero_image',
    'hover_text',
    'hero_image_small',
  ],
  RegionPage: ['title', 'path', 'hover_text', 'hero_image', 'hero_image_small'],
  AgentPage: [
    'title',
    'url',
    'profile_image_thumbnail',
    'job_title',
    'phone',
    'office_phone',
  ],
  OfficePage: [
    'title',
    'path',
    'address',
    'suburb',
    'postcode',
    'state',
    'phone',
    'email',
    'featured_image',
    'office_image',
    'latitude',
    'longitude',
  ],
  AwardPage: [
    'title',
    'content',
    'path',
    'hero_image',
    'hero_image_thumb',
    'offices',
    'agents',
    'date_of_award',
    'gallery_items',
    'video_url',
    'stripped_content',
  ],
  LifestyleCategoryPage: [
    'title',
    'path',
    'hover_text',
    'hero_image',
    'hero_image_small',
  ],
  ProjectPage: [
    'title',
    'path',
    'images',
    'status',
    'project_type',
    'address_subNumber',
    'address_streetNumber',
    'address_street',
    'address_suburb',
    'address_state',
    'address_postcode',
    'logo',
    'headline',
    'content',
    'location',
    'documents',
  ],
  CareerPage: [
    'title',
    'path',
    'date_posted',
    'job_name',
    'job_type',
    'get_job_type_display',
    'location',
    'description',
  ],
  resourcesPage: [
    'image_thumbnail',
    'image',
    'created',
    'resource_type',
    'external_link',
    'document',
    'description',
    'body',
    'resource_categories',
    'resource_path',
  ],
  bptvVideo: [
    'title',
    'caption',
    'preamble',
    'video_url',
    'video_categories',
    'cover_image_results',
    'cover_image',
  ],
}

exports.cmsPageTypes = {
  Project: 'projects.Project',
  RegionPage: 'suburbs.RegionPage',
  SuburbPage: 'suburbs.SuburbPage',
}

exports.listSearchPageType = {
  SEARCH_TYPE_PROPERTY: 'property',
  SEARCH_TYPE_AUCTION: 'auction',
  SEARCH_TYPE_INSPECTION: 'inspection',
  SEARCH_TYPE_SALEDATE: 'salebysetdate',
}

exports.themeColours = {
  brand: '#f92044',
  titles: '#000047',
  white: '#fff',
}

exports.SITE_SEARCH_RESULTS_LIMIT = 10
exports.SITE_SEARCH_RESULTS_AGENTS_LIMIT = 30

exports.suburbCategoriesSlugs = {
  acreage: 'AcreageSemi-rural',
  apartments: 'Apartment',
  'apartment-blocks': 'BlockOfUnits',
  duplex: 'DuplexSemi-detached',
  house: 'House',
  studio: 'Studio',
  townhouse: 'Townhouse',
  unit: 'Unit',
  'vacant-land': 'Land',
  villa: 'Villa',
  land: 'Land',
  offices: 'Offices',
  'restaurant-bar': 'Hotel/Leisure',
  retail: 'Retail',
  warehouse: 'Warehouse',
  medical: 'Medical/Consulting',
}

exports.suburbCategoriesExtraCriteria = {
  // These suburb page "categories" are actually translated into new API
  // params
  project: {
    project_children_only: true,
  },
  rural: {
    property_class: 'rural',
  },
}

exports.listingTypeSlugs = {
  'for-sale': 'sale',
  'for-rent': 'lease',
  'rental-properties': 'lease',
}

exports.propertyTypeMap = {
  houses: 'House',
  apartments: 'Apartment',
  units: 'Unit',
  townhouses: 'Townhouse',
  villas: 'Villa',
  land: 'Land',
  acreages: 'AcreageSemi-rural',
  flats: 'Flat',
  terraces: 'Terrace',
  'apartment-blocks': 'BlockOfUnits',
  'serviced-apartments': 'ServicedApartment',
  duplexes: 'DuplexSemi-detached',
  'house-and-land-packages': 'House and Land Package',
  'new-developments': 'Project',
}

exports.channelNames = {
  rent: 'rental-properties',
  sale: 'for-sale',
  sold: 'sold-properties',
}

exports.channelSlugToString = {
  'for-sale': 'For Sale',
  'rental-properties': 'For Rent',
  'sold-properties': 'Sold',
}

exports.channelCriteriaMap = {
  'for-sale': { listing_type: 'sale' },
  'rental-properties': { listing_type: 'lease' },
  'sold-properties': { status: 'sold' },
}

exports.stateCodeStrings = {
  vic: 'Victoria',
  wa: 'Western Australia',
  nsw: 'New South Wales',
  sa: 'South Australa',
  nt: 'Northern  Territory',
  act: 'Australian Capitol Territory',
  qld: 'Queensland',
  tas: 'Tasmania',
}

exports.queryApiParams = {
  // Map URL query args to API Params
  suburb: 'address_suburb',
  type: 'listing_type',
}
exports.formSlugs = {
  emailSignup: 'email-signup',
  careerRegister: 'careers-registration',
}

exports.searchBlockType = {
  residential_sale: 'residential_sale',
  residential_sale_search: 'residential_sale_search',
  residential_rent: 'residential_rent',
  residential_rent_search: 'residential_rent_search',
  commercial_sale: 'commercial_sale',
  commercial_sale_search: 'commercial_sale_search',
  commercial_rent: 'commercial_rent',
  commercial_rent_search: 'commercial_rent_search',
  project: 'project',
  projects_search: 'projects_search',
}

exports.homeSearchBlockType = {
  residential: 'residential',
  commercial: 'commercial',
  project: 'project',
}

exports.homeSearchListingType = {
  all: 'all',
  lease: 'lease',
  sale: 'sale',
}

exports.placeholderImages = {
  officeCard: '/static/img/defaults/large-navy.jpg',
}

exports.defaultImages = {
  xLargeWhite: '/static/img/defaults/xlarge.jpg',
  largeWhite: '/static/img/defaults/large.jpg',
  mediumWhite: '/static/img/defaults/medium.jpg',
  smallWhite: '/static/img/defaults/small.jpg',
  xLargeNavy: '/static/img/defaults/xlarge-navy.jpg',
  largeNavy: '/static/img/defaults/large-navy.jpg',
  mediumNavy: '/static/img/defaults/medium-navy.jpg',
  smallNavy: '/static/img/defaults/small-navy.jpg',
  mediumNavyBTM: '/static/img/defaults/medium-navy__btm.jpg',
  largeDefault: '/static/img/defaults/large-navy.jpg',
}

exports.mapConfig = {
  defaultCenter: [144.96332, -37.814],
  mapSearchDefaultCenter: [150.9103445, -34.3144493],
  mapSearchDefaultZoom: 13,
}

exports.rssFeedData = {
  title: 'Barry Plant Real Estate RSS Feed',
  description:
    'Barry Plant Real Estate - a powerful real estate network operating throughout Melbourne and Victoria.',
  link: 'https://www.barryplant.com.au',
  language: 'en', // optional, used only in RSS 2.0, possible values: http://www.w3.org/TR/REC-html40/struct/dirlang.html#langcodes
  image: 'https://bp.techequipt.com.au/static/img/inline-svgs/logo.svg',
  copyright:
    '(c) Barry Plant All rights Reserved. This content may not be published or re-used without the express written consent of Barry Plant.',
  generator: 'barryplant.com.au', // optional, default = 'Feed for Node.js'
}

exports.timezones = {
  AUS_Sydney: 'Australia/Sydney',
}

exports.reCaptcha_token_timeout = 60000 //milisecond

exports.tourUrls = [
  {
    url: 'my.matterport.com',
    embed: false,
  },
  {
    url: 'vtc.virtualtourscreator.com.au',
    embed: false,
  },
  {
    url: 'portal.diakrit.com/product/smartnav',
    embed: false,
  },
  {
    url: 'cdn.diakrit.com/product/panorama',
    embed: true,
  },
  {
    url: 'app.situ360.com',
    embed: true,
  },
]

exports.interactiveFlooplanUrls = [
  {
    url: 'cdn.diakrit.com/product/furnish',
    embed: false,
  },
]

exports.onlineAuctionUrls = [
  {
    url: 'x5u9a.app.goo.gl', // GAVL
  },
  {
    url: 'anywhereauctions.com.au', // Anywhere Auctions
  },
  {
    url: 'marketbuy.com.au', // Market Buy
  },
]

exports.ieError = {
  title: 'Unsupported Browser',
  content: [
    'It looks like you are using a web browser or outdated version of a we browser that is no longer supported by our website.',
    'Make sure that you are using the most recent version of your browser, or try using one of the following browsers that is supported by our website to get the best experience: Chrome, Edge, Safari or Firefox',
  ],
  link: {
    label: 'Find Out More',
    url: 'https://www.microsoft.com/en-au/microsoft-365/windows/end-of-ie-support',
    target: '_blank',
  },
  cookie: 'ieError',
}

exports.reipUrl = 'https://reip.com.au/'

exports.HTTPStatusCode = {
  404: 'Not Found',
}
