import { UrlBuilder } from '../helpers'
import { apiCall } from '../apiServerHelper'
import { apis } from '../apiUrls'

export const getChildPages = async (
  type,
  parentId,
  limit = 10,
  page = 1,
  params = {},
  fields = null
) => {
  params &&
    Object.keys(params).map((key) => {
      if (params[key] == null || params[key] == '') {
        delete params[key]
      }
    })
  let options = params || {}
  if (type && type.trim().length > 0) {
    options.type = type
  }

  if (parentId) {
    options.child_of = parentId
  }

  if (limit) {
    options.limit = limit
  }

  if (page > 1 && limit) {
    options.offset = (page - 1) * limit
  }

  if (fields && Array.isArray(fields)) {
    options.fields = fields.join(',')
  }

  delete options.page

  return await apiCall(UrlBuilder(apis.WAGTAIL_API_BASE, options))
}
