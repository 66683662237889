import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, textSM } from '@styles/fonts'


export const FeaturedImageWrapper = styled.div` 
  &:hover {
    cursor: pointer;
  }
`

export const FeaturedImageCaption = styled.div`
    ${textSM};
    color: ${COLORS.navi};
    font-family: ${FONTS.regular};
    margin-top: 16px;
`
