import { Container } from '@styles/global.styled'
import Video from '@global/Video'
import dynamic from 'next/dynamic'
import * as S from './LatestVideo.styled'

const Carousel = dynamic(() => import('@global/Carousel'))

const LatestVideo = (props) => {
  const { videos, title } = props

  if (!videos?.length) return null

  return (
    <S.LatestVideoWrap>
      <Container>
        <S.LatestVideoHeader>
          {videos && title && <S.LatestVideoTitle>{title}</S.LatestVideoTitle>}
        </S.LatestVideoHeader>
        {videos && (
          <Carousel>
            {videos.map((item, index) => {
              return (
                <S.VideoCardWrap key={index}>
                  <Video
                    videoUrl={item.video_url}
                    videoThumbnail={item.video_image}
                    videoTitle={item.title}
                    border
                    ratio={74}
                  />
                  <S.VideoCardTitle>{item.title}</S.VideoCardTitle>
                  <S.VideoCardHeading>{item.heading}</S.VideoCardHeading>
                  <S.VideoCardDescription>
                    {item.description}
                  </S.VideoCardDescription>
                </S.VideoCardWrap>
              )
            })}
          </Carousel>
        )}
      </Container>
    </S.LatestVideoWrap>
  )
}

LatestVideo.defaultProps = {
  title: 'Latest Videos',
  videos: [],
}

// eslint-disable-next-line react/display-name
LatestVideo.ApiBlockToProps = (data) => {
  if (!data) {
    return null
  }

  return {
    videos: data.value?.videos || [],
    call_to_action_text: data.value?.call_to_action_text,
    call_to_action_link: data.value?.call_to_action_link,
    call_to_action_url: data.value?.call_to_action_url,
    title: data?.value?.title,
  }
}
export default LatestVideo
