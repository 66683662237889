import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'

export const Button = styled.button`
  border: none;
  border-radius: 20px;
  padding: 7px 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: ${FONTS.bold};
  text-transform: uppercase;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;
  border: 1px solid transparent;

  &:hover {
    color: ${COLORS.white};
  }

  ${({ color }) =>
    css`
      background-color: ${color === 'primary'
        ? COLORS.primary
        : color === 'secondary'
        ? COLORS.secondary
        : color === 'white'
        ? COLORS.white
        : color === 'grey'
        ? COLORS.sand
        : color
        ? color
        : COLORS.nature[95]};
      color: ${color === 'white' || color === 'grey'
        ? COLORS.secondary
        : color
        ? COLORS.white
        : COLORS.primary};

      &:hover {
        background-color: ${color === 'primary'
          ? COLORS.secondary
          : color === 'secondary'
          ? COLORS.primary
          : COLORS.secondary};
      }
    `}

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.startIcon &&
    css`
      svg {
        margin-right: 6px;
      }
    `}

  ${(props) =>
    props.endIcon &&
    css`
      svg {
        margin-left: 6px;
      }
    `}


  ${({ color }) =>
    color &&
    css`
      ${color === 'primary' &&
      css`
        &:hover {
          border-color: ${COLORS.secondary};
        }
      `}

      ${color === 'secondary' &&
      css`
        &:hover {
          border-color: ${COLORS.primary};
        }
      `}
    `}

  ${({ border, color }) =>
    border &&
    css`
      background-color: transparent;

      ${color === 'primary' &&
      css`
        border-color: ${COLORS.primary};
        color: ${COLORS.primary};

        &:hover {
          border-color: ${COLORS.primary};
          background-color: ${COLORS.primary};
          color: ${COLORS.white};
        }
      `}

      ${color === 'secondary' &&
      css`
        border-color: ${COLORS.secondary};
        color: ${COLORS.secondary};

        &:hover {
          border-color: ${COLORS.secondary};
          background-color: ${COLORS.secondary};
          color: ${COLORS.white};
        }
      `}
    `}

  ${({ size }) =>
    size && size === 'sm'
      ? css`
          padding: 7px 16px;
          font-size: 12px;
          letter-spacing: 1px;
          line-height: 18px;
        `
      : size === 'lg'
      ? css`
          padding: 9px 16px;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 20px;
        `
      : ''}

  &:disabled,
  &[disabled] {
    border: 1px solid ${COLORS.grey[50]};
    background-color: ${COLORS.grey[50]};
    color: ${COLORS.grey[20]};
    cursor: not-allowed;
  }

  ${({ circle }) =>
    circle &&
    css`
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: ${COLORS.white};
    `}
`
