const IconFavourite = (props) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M5.54325 7.55625C6.57267 6.52142 7.92196 6 9.27389 6C10.626 6 11.9822 6.52137 13.0117 7.5562L14.0066 8.55815L14.9872 7.5633C17.0463 5.49364 20.3968 5.49364 22.4558 7.5633C24.5147 9.63305 24.5147 12.9906 22.4558 15.0603C19.7961 17.7331 17.1349 20.4032 14.4756 23.076C14.3476 23.2039 14.1744 23.2767 13.9923 23.2767C13.8111 23.2767 13.6379 23.2039 13.5091 23.076L5.54325 15.0462C3.48691 12.9755 3.48425 9.62591 5.54325 7.55625Z'
        fill='#E6F1FF'
      />
      <path
        d='M9.27389 6C7.92196 6 6.57267 6.52142 5.54325 7.55625C3.48425 9.62591 3.48691 12.9755 5.54325 15.0462L13.5091 23.076C13.6379 23.2039 13.8111 23.2767 13.9923 23.2767C14.1744 23.2767 14.3476 23.2039 14.4756 23.076C17.1349 20.4032 19.7961 17.7331 22.4558 15.0603C24.5147 12.9906 24.5147 9.63305 22.4558 7.5633C20.3968 5.49364 17.0463 5.49364 14.9872 7.5633L14.0066 8.55815L13.0117 7.5562C11.9822 6.52137 10.626 6 9.27389 6ZM9.27389 7.35016C10.2714 7.35016 11.2743 7.7401 12.0454 8.51556L13.5234 10.0007C13.6522 10.1286 13.8255 10.2015 14.0067 10.2015C14.1888 10.2015 14.362 10.1286 14.4899 10.0007L15.9537 8.52265C17.4957 6.97264 19.9472 6.97264 21.4894 8.52265C23.0315 10.0727 23.0315 12.5439 21.4894 14.0938C18.9908 16.605 16.4913 19.1222 13.9925 21.6334L6.50979 14.0866C4.96867 12.5348 4.96778 10.0653 6.50979 8.51538C7.2808 7.73992 8.27642 7.35016 9.27389 7.35016Z'
        fill='white'
      />
    </svg>
  )
}

export default IconFavourite
