import { useFormikContext } from 'formik'
import FilterGroup from '../FilterGroup'
import { propertyCategory } from '@lib/searchFields'
import { Tag, Tags } from '../FiltersForm.styled'

const PropertyTypeField = () => {
  const { values, setFieldValue } = useFormikContext()

  const onCategoriesChange = (category) => {
    if (category === '') {
      setFieldValue('categories', [])
    } else {
      let categories = values.categories || []
      if (categories.includes(category)) {
        categories.splice(categories.indexOf(category), 1)
      } else {
        categories.push(category)
      }
      setFieldValue('categories', categories)
    }
  }

  return (
    <FilterGroup title='Property Type'>
      <Tags style={{ paddingTop: 0 }}>
        {propertyCategory[values.property_class]?.options?.map((it) => (
          <Tag
            key={`category-${it.value}`}
            onClick={() => onCategoriesChange(it.value)}
            active={
              (it.value === '' && values.categories?.length === 0) ||
              values.categories?.includes(it.value)
            }
          >
            {it.label}
          </Tag>
        ))}
      </Tags>
    </FilterGroup>
  )
}

export default PropertyTypeField
