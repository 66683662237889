const IconSliderPrev = () => {
  return (
    <svg
      className='c-svgicon c-svgicon--icon-slide-next c-slide-arrow-right__svg'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 46 22'
    >
      <polygon
        fill='currentColor'
        className='st0'
        points='45.6,10 1.1,10 10.8,0.4 10.4,0 0.2,10.2 10.4,20.4 10.8,20 1.3,10.5 45.6,10.5 '
      />
    </svg>
  )
}

export default IconSliderPrev
