const IconWhatsapp = (props) => {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.156494' width='18' height='18' rx='1.6' fill='currentColor' />
      <path
        d='M9.15649 2.34961C5.53235 2.34961 2.59399 5.28013 2.59399 8.89461C2.59399 10.1225 2.93981 11.2672 3.52966 12.2491L2.65274 15.4396L5.9201 14.5842C6.87567 15.126 7.97879 15.4396 9.15649 15.4396C12.7806 15.4396 15.719 12.5091 15.719 8.89461C15.719 5.28013 12.7806 2.34961 9.15649 2.34961ZM6.91025 5.84155C7.01689 5.84155 7.12646 5.84091 7.22107 5.84582C7.3381 5.84854 7.46548 5.85709 7.58743 6.12598C7.73235 6.44559 8.04791 7.2474 8.08838 7.32867C8.12885 7.40993 8.15752 7.50567 8.1012 7.61203C8.0476 7.72111 8.01973 7.78713 7.94098 7.88367C7.85949 7.97748 7.77021 8.094 7.69638 8.1649C7.6149 8.24617 7.53075 8.33528 7.62482 8.49726C7.71888 8.65925 8.04555 9.19029 8.52844 9.61899C9.14915 10.172 9.67285 10.342 9.83582 10.4233C9.99878 10.5045 10.0932 10.4919 10.1872 10.3828C10.284 10.2764 10.5938 9.91149 10.7031 9.74895C10.8098 9.58642 10.9186 9.61454 11.0663 9.66799C11.2161 9.72144 12.0152 10.114 12.1782 10.1953C12.3412 10.2766 12.448 10.3168 12.489 10.3828C12.5311 10.451 12.5312 10.7755 12.3961 11.154C12.261 11.532 11.5977 11.8975 11.3002 11.9232C11 11.951 10.7198 12.0578 9.34875 11.5194C7.695 10.8698 6.65207 9.18053 6.57059 9.07144C6.4891 8.96509 5.90836 8.19315 5.90836 7.39684C5.90836 6.59781 6.32842 6.20649 6.47552 6.04396C6.62537 5.88142 6.80087 5.84155 6.91025 5.84155Z'
        fill='white'
      />
    </svg>
  )
}

export default IconWhatsapp
