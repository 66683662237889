import styled, { css } from 'styled-components'

export const DynamicForm = styled.div`
  position: relative;
`

export const DynamicFormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  > ul {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
`
