import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import * as S from './StatisticsCircle.styled'

const CountUp = dynamic(() => import('react-countup'), { ssr: false })

const StatisticsCircle = ({ statistic }) => {
  const [isFinished, setIsFinished] = useState(false)

  const countUpEndHandle = () => {
    setIsFinished(true)
  }

  return (
    <S.StatisticsCircle>
      <S.StatisticsCircleInner>
        <S.StatisticsCircleValue>
          {isFinished ? (
            <div>{statistic.value || statistic.number}</div>
          ) : (
            <CountUp end={statistic.number} onEnd={countUpEndHandle} />
          )}
        </S.StatisticsCircleValue>
        <S.StatisticsCircleLabel>{statistic.label}</S.StatisticsCircleLabel>
      </S.StatisticsCircleInner>
    </S.StatisticsCircle>
  )
}

StatisticsCircle.defaultProps = {}

export default StatisticsCircle
