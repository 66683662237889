const IconFacebookFilled = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.6049 0C0.718538 0 0 0.718537 0 1.6049V14.3951C0 15.2815 0.718537 16 1.6049 16H14.3951C15.2815 16 16 15.2815 16 14.3951V1.6049C16 0.718538 15.2815 0 14.3951 0H1.6049ZM8.54713 15.9999H11.243L11.2426 9.59911H13.0415L13.28 7.39361H11.2426L11.2463 6.28965C11.2463 5.71444 11.3019 5.40623 12.1433 5.40623H13.2676V3.19992H11.4687C9.30739 3.19992 8.54713 4.27036 8.54713 6.0695V7.39361H7.2V9.59911H8.54713V15.9999Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconFacebookFilled
