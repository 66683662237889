import IconStar from '@icons/Star'
import * as S from './Testimonial.styled'
import Image from '@global/Image'
import React, { useEffect, useState } from 'react'
import { Link } from '@routes'
import IconPlusBig from '@icons/PlusBig'
import useWindowSize from '@hooks/useWindowSize'

const renderRating = (rating) => {
  const items = []
  for (let i = 0; i < rating; i++) {
    items.push(rating % 2 !== 0 && <IconStar />)
  }
  return items
}

const Testimonial = ({ testimonial }) => {
  const name = testimonial?.agents[0]?.agent?.title
  const jobTitle = testimonial?.agents[0]?.agent?.job_title
  const avatar = testimonial?.agents[0]?.agent?.profile_image_thumbnail?.url
  const office = testimonial?.offices[0]?.office?.office_name
  const url = `/testimonials/${testimonial?.slug}/${testimonial?.id}`

  const [displayText, setDisplayText] = useState('');
  const [maxLength, setMaxLength] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    let newMaxLength = 0;

     if (windowSize.width < 768) {
      newMaxLength = 330;
    } else if (windowSize.width < 1500) {
      newMaxLength = 270;
    } else if (windowSize.width < 2000) {
      newMaxLength = 450;
    } else {
      newMaxLength = 500;
    }

    setMaxLength(newMaxLength);
  }, [windowSize.width]);

  const createExcerpt = (htmlContent, maxLength) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, 'text/html')
    let length = 0

    const walkNodes = (node) => {
      for (const child of Array.from(node.childNodes)) {
        if (length >= maxLength) {
          node.removeChild(child)
        } else if (child.nodeType === Node.TEXT_NODE) {
          const textLength = child.textContent.length
          if (length + textLength > maxLength) {
            child.textContent = child.textContent.slice(0, maxLength - length) + '...'
            length = maxLength
          } else {
            length += textLength
          }
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          walkNodes(child)
        }
      }
    }

    walkNodes(doc.body)

    return doc.body.innerHTML
  }

  useEffect(() => {
    if (!testimonial || !testimonial.comments) return

    // Use createExcerpt function here to create excerpt
    const excerpt = createExcerpt(testimonial.comments, maxLength);
    setDisplayText(excerpt);
  }, [testimonial, maxLength])


  return (
    <S.TestimonialWrap>
      <S.ImageWrap>
        <Image src={testimonial?.image} />
      </S.ImageWrap>
      <S.Content>
        <S.HTMLText dangerouslySetInnerHTML={{ __html: displayText }} />
        {testimonial?.comments && testimonial.comments.length > maxLength && (
          <S.ReadMore><Link route={url}>Read more <IconPlusBig /></Link></S.ReadMore>
        )}
        <S.AuthorWrap>
          {testimonial?.agents && testimonial?.agents.length > 0 && (
            <>
              <S.AuthorAvatar>
                <Image src={avatar} />
              </S.AuthorAvatar>
              <S.AuthorInfo>
                <S.AuthorName>{name}</S.AuthorName>
                {jobTitle && <S.AuthorBusiness>{jobTitle}</S.AuthorBusiness>}
                <S.AuthorBusiness>{office}</S.AuthorBusiness>
              </S.AuthorInfo>
            </>
          )}
        </S.AuthorWrap>
      </S.Content>
    </S.TestimonialWrap>
  )
}

export default Testimonial
