import SVGIcon from '../icon/svgIcon'
import dynamic from 'next/dynamic'

const InvestorLandingForm = dynamic(
  () => import('@components/Investor/InvestorLandingForm'),
  { ssr: false }
)

const Investors = ({
  value: { heading, sub_heading, bullet_points, image, form, disclaimer_text },
}) => {
  return (
    <div className='investor-hero'>
      <div className='o-wrapper'>
        {heading && (
          <div className='o-layout'>
            <div className='o-layout__item u-1/2@desktop'>
              <h2 className='investor-hero__heading'>{heading}</h2>
            </div>
          </div>
        )}
        <div className='o-layout flex'>
          <div className='o-layout__item u-1/2@desktop'>
            <div className='investor-hero__content'>
              <div>
                {sub_heading && (
                  <h3 className='investor-hero__subHeading'>{sub_heading}</h3>
                )}
                {bullet_points?.length > 0 ? (
                  <ul>
                    {bullet_points.map(({ bullet_point }, index) =>
                      bullet_point ? (
                        <li key={index}>
                          <SVGIcon name='icon-chevron-right' />
                          <span>{bullet_point}</span>
                        </li>
                      ) : null
                    )}
                  </ul>
                ) : null}
                <div className='investor-hero__form'>
                  {form?.slug && <InvestorLandingForm formSlug={form.slug} />}
                </div>
              </div>
              {disclaimer_text && (
                <p className='investor-hero__term-condition'>
                  {disclaimer_text}
                </p>
              )}
            </div>
          </div>

          {image && (
            <div className='investor-hero__image o-layout__item u-1/2@desktop'>
              <img src={image} alt={heading} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Investors
