import styled, { css } from 'styled-components'

export const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
`

export const VideoWrapper = styled.div`
  position: relative;
  height: 0;
  
  ${({ ratio }) =>
    ratio &&
    css`
      padding-top: ${`${ratio}%`};
    `}

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9;
  }

  ${({ border }) =>
    border &&
    css`
      .video-player {
        iframe {
          border-radius: ${({ borderAll }) => borderAll ? '12px' : '0 0 40px 0'};
        }
      }

      img {
        border-radius: ${({ borderAll }) => borderAll ? '10px' : '0 0 38px 0'};
      }
    `
}
`
