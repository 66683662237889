import * as S from './BlogParagraph.styled'

const BlogParagraph = ({ paragraph }) => {
  return (
    <S.BlogParagraphWrap className={'blog-paragraph'} dangerouslySetInnerHTML={{ __html: paragraph }}/>
  )
}

BlogParagraph.defaultProps = {
  paragraph: ''
}

BlogParagraph.ApiBlockToProps = data => {
  let text = data.value
  if (typeof text != 'string') {
    text = data.value.content || data.value.text
  }
  return {
    paragraph: text
  }
}

export default BlogParagraph
