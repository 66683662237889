const defaultTitle = 'Barry Plant'
const defaultDescription =
  'Successful real estate group of around 80 offices across Australia. Achieving excellence in sales &amp; property management. Search properties for rent or sale.'

export default {
  title: defaultTitle,
  titleTemplate: `%s`,
  description: defaultDescription,
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.barryplant.com.au',
    title: defaultTitle,
    description: defaultDescription,
    defaultImageWidth: 1200,
    defaultImageHeight: 1200,
    noindex: true,
    images: [
      {
        url: './styles/img/inline-svgs/logo.svg',
        width: 800,
        height: 600,
        alt: 'Barry Plant logo'
      }
    ],
    site_name: 'Barry Plant'
  },
  twitter: {
    handle: '@barryplantre',
    site: '@barryplantre',
    cardType: 'summary_large_image'
  }
}
