import { Link } from '@routes'
import useWindowSize from '@hooks/useWindowSize'
import IconChervonRight from '@global/Icons/ChervonRight'

const Menu = ({
  menuItems = [],
  open,
  subMenuActive,
  setSubMenuActive,
  setMenuOpen,
  withOffice,
}) => {
  const windowSize = useWindowSize()

  return (
    <ul className={`bp-menu ${withOffice ? 'width-office' : ''}`}>
      {menuItems?.map((it, key) => (
        <li
          key={`menu-${key}`}
          className={`bp-menu-item ${subMenuActive === key ? 'active' : ''}`}
          onClick={() => setSubMenuActive(subMenuActive === key ? null : key)}
        >
          <span className='bp-menu-item-label'>
            {it.label}
            <IconChervonRight />
          </span>
          {it.subNavItems && (
            <ul
              className={`bp-sub-menu ${subMenuActive === key ? 'active' : ''}`}
              style={{
                left:
                  subMenuActive === key && windowSize?.width > 1920
                    ? 'calc(((100vw - 1920px) / 2) + 352px)'
                    : '352px',
              }}
            >
              {it.subNavItems.map(
                (child, subKey) =>
                  child.url && (
                    <li
                      key={`menu-child-${key}-${subKey}`}
                      className='bp-sub-menu-item'
                      onClick={() => setSubMenuActive(null)}
                    >
                      <Link
                        route={child.url}
                        passHref
                        onClick={() => {
                          setMenuOpen(false)
                          setSubMenuActive(null)
                        }}
                      >
                        {child.label}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Menu
