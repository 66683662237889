import { Link } from '@routes'
import Image from '@global/Image'
import { titleCase } from '@lib/helpers'
import * as S from './ProjectCard.styed'

const ProjectCard = ({
  slug,
  images,
  title,
  address_suburb,
  address_state,
  address_postcode,
  status,
  property_type,
  mapResults,
}) => {
  const url = `/projects/${slug}/`

  return (
    <S.ProjectCard mapResults={mapResults}>
      <S.ProjectImage>
        <Link route={url} passHref>
          <Image src={images?.[0]?.image} alt={title} />
        </Link>
        {status === 'sold' && <S.ProjectTag>{status}</S.ProjectTag>}
        {status !== 'sold' && (
          <S.PropertyInfo>
            <span>For Sale</span>
            {titleCase(property_type)}
          </S.PropertyInfo>
        )}
      </S.ProjectImage>
      <S.ProjectContent>
        <S.ProjectStatus>
          {status === 'sold' ? 'Completed' : status}
        </S.ProjectStatus>
        <S.ProjectName>
          <Link route={url} passHref>
            {title}
          </Link>
        </S.ProjectName>
        <S.ProjectAddress>
          <strong>{address_suburb}</strong> {address_state} {address_postcode}
        </S.ProjectAddress>
        {status !== 'sold' && (
          <S.PropertyInfo>
            <span>For Sale</span>
            {titleCase(property_type)}
          </S.PropertyInfo>
        )}
      </S.ProjectContent>

      <Link route={url} passHref />
    </S.ProjectCard>
  )
}

export default ProjectCard
