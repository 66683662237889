const ListingPrice = listing => {
  if (listing.listing) listing = listing.listing

  return listing.status !== 'sold' && listing.status !== 'leased' ? (
    <>
      {/* display "Price Guide: " for sale properties */}
      {(listing.listingType == 'sale' ||
        (!listing.listingType && listing.offmarket)) &&
        listing.status !== 'sold' &&
        'Price Guide: '}

      {listing.listingType == 'lease' && 'Price: '}

      {/* Show sold price if sold and allowed, otherwise price */}
      {listing.status == 'sold' && listing.soldDetails_price_display
        ? `${new Intl.NumberFormat('en-au', {
            style: 'currency',
            currency: 'AUD'
          }).format(listing.soldDetails_price)}`
        : listing.priceGuide}
    </>
  ) : null
}

export default ListingPrice
