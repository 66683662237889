const ChervonRight = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 7.97182L6.78022 12.5433L6.10597 11.9209L9.75124 7.97182L6.10597 4.02277L6.78022 3.40039L11 7.97182Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ChervonRight
