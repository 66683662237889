import { LISTING_STATUS } from '@constants/property'
import * as S from './ListingTags.styled'

const ListingTags = ({ listing, big, isShowLeasedStatus }) => {
  return (
    <>
      {(listing.status === LISTING_STATUS.SOLD ||
        (listing.status === LISTING_STATUS.LEASED && isShowLeasedStatus)) && (
        <S.ListingTag big={big}>{listing.status}</S.ListingTag>
      )}
      {listing.new ? (
        <S.ListingTags>
          {listing.new && <S.ListingTagSmall>New Listing</S.ListingTagSmall>}
        </S.ListingTags>
      ) : listing.category ? (
        <S.ListingTags>
          <S.ListingTagSmall>{listing.category}</S.ListingTagSmall>
        </S.ListingTags>
      ) : null}
    </>
  )
}

export default ListingTags
