const IconBed = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.6271 14.9006V11.3516H2.31067V14.9006'
        stroke='currentColor'
        strokeWidth='0.762013'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M16.7686 11.6075V8.11428C16.7686 7.11096 15.9634 6.29688 14.9696 6.29688H5.04502C4.05186 6.29688 3.24603 7.11032 3.24603 8.11428V11.6075'
        stroke='currentColor'
        strokeWidth='0.762013'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M8.54773 10.0649H5.43872C5.02597 10.0649 4.68814 9.72707 4.68814 9.31431V9.29844C4.68814 8.88568 5.02597 8.54785 5.43872 8.54785H8.54773C8.96049 8.54785 9.29832 8.88568 9.29832 9.29844V9.31431C9.29832 9.72707 8.96049 10.0649 8.54773 10.0649Z'
        stroke='currentColor'
        strokeWidth='0.508009'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5168 10.0649H11.4078C10.9951 10.0649 10.6572 9.72707 10.6572 9.31431V9.29844C10.6572 8.88568 10.9951 8.54785 11.4078 8.54785H14.5168C14.9296 8.54785 15.2674 8.88568 15.2674 9.29844V9.31431C15.2674 9.72707 14.9296 10.0649 14.5168 10.0649Z'
        stroke='currentColor'
        strokeWidth='0.508009'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconBed
