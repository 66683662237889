import Image from '@global/Image'

const CTADownloadBlock = ({ value }) => {
  const { heading, image, content } = value

  return (
    <div className='block-component'>
      <div className='o-wrapper'>
        <div className='cta-download'>
          <div className='cta-download__info'>
            <div>
              <h2 className='c-basic-text__title u-h1'>{heading}</h2>
              <p>{content}</p>
            </div>
            <div>
              <a
                className='c-btn c-btn--primary c-btn--animated c-btn--medium'
                data-text='free download'
                href='#investor-hero-form'
              >
                <span>free download</span>
              </a>
            </div>
          </div>
          <div
            className='cta-download__image'
            style={{ backgroundImage: `url(${image})` }}
          >
            <Image src={image} alt={heading} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTADownloadBlock
