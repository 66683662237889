import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

export const VideosListWrap = styled.div``

export const VideoCardWrap = styled.div``

export const Carousel = styled.div`
  .slick-slider {
    .slick-prev {
      left: 22px;
    }

    .slick-next {
      right: 22px;
    }
  }

  @media ${DEVICES.mobile} {
    .slick-slider {
      .slick-arrow {
        top: 75%;
        opacity: 1;
      }

      .slick-prev {
        left: 10px;
      }

      .slick-next {
        right: 10px;
      }
    }
  }
`

const CarouselButtonStyle = css`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.secondary};
  cursor: pointer;
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s ease-in;

  &.slick-disabled {
    display: none;
  }

  &:before {
    display: none;
  }

  svg {
    position: relative;
  }

  &:hover {
    background-color: ${COLORS.primary};
  }
`

export const CarouselNext = styled.div`
  ${CarouselButtonStyle};

  svg {
    right: -1px;
  }
`

export const CarouselPrev = styled.div`
  ${CarouselButtonStyle}

  svg {
    left: -1px;
  }
`

export const CarouselIndicators = styled.ul`
  left: 35px;
  width: unset;
  bottom: 100px;

  li,
  li button {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  li.slick-active button:before {
    opacity: 1;
    color: transparent;
    border: 3px solid ${COLORS.white};
    background-color: ${COLORS.secondary};
  }

  li button:before {
    width: 15px;
    height: 15px;
    opacity: 1;
    color: transparent;
    border-radius: 50%;
    background-color: ${COLORS.white};
  }

  @media ${DEVICES.tablet} {
    bottom: 50px;
  }

  @media ${DEVICES.mobile} {
    top: unset;
    left: unset;
    width: 100%;
    bottom: 25px;

    li,
    li button,
    li button:before {
      width: 10px;
      height: 10px;
    }

    li.slick-active button:before {
      border: 2px solid ${COLORS.white};
    }
  }
`
