import { ReactSVG } from 'react-svg'
import { iconConfigs } from './iconConfig'

export const SVGIcon = ({ name, option }) => {
  if (!name) {
    return null
  }
  let options = option
  !options &&
    iconConfigs.every(opt => {
      if (opt.name == name || opt.name == 'icon-' + name) {
        options = opt
        return false
      }
      return true
    })
  if (!options) {
    options = { name: 'icon-' + name }
  }

  const classList = `c-svgicon c-svgicon--${options.name} ${options.class ||
    ''}`

  return (
    <ReactSVG
      src={'/static/img/inline-svgs/' + options.name + '.svg'}
      wrapper='span'
      beforeInjection={svg => {
        classList.split(' ').map(it => it && svg.classList.add(it))
      }}
    />
  )
}

export default SVGIcon
