import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { textLG } from '@styles/fonts'

export const OfficeCardImage = styled.div`
  position: relative;
  height: 0;
  padding-top: 75%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const OfficeCardHeading = styled.div`
  padding: 16px;
`

export const OfficeCardInfo = styled.div`
  padding: 12px 16px 16px;
  border-top: 1px solid ${COLORS.sand};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s ease-in;

  svg {
    color: ${COLORS.secondary};
    transition: all 0.25s ease-in;
  }
`

export const OfficeCardContact = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  cursor: pointer;

  > span,
  > a {
    color: ${COLORS.navi};
    transition: all 0.25s ease-in;
  }

  > svg {
    margin-right: 6px;
  }
`

export const OfficeName = styled.h3`
  ${textLG};
  letter-spacing: 1.2px;
  text-transform: uppercase;
`

export const OfficeAddress = styled.span`
  overflow: hidden;
  color: ${COLORS.navi};
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  transition: all 0.25s ease-in;
`

export const OfficeCard = styled.div`
  position: relative;
  margin-bottom: 40px;
  border-radius: 0px 0px 40px 0px;
  background: ${COLORS.nature[95]};
  transition: all 0.25s ease-in;

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background: ${COLORS.navi};

    ${OfficeName} a,
    ${OfficeAddress},
    ${OfficeCardContact},
    ${OfficeCardContact} a,
    ${OfficeCardContact} span {
      color: ${COLORS.white};
    }

    ${OfficeCardContact} svg {
      color: ${COLORS.white};
    }

    ${OfficeCardInfo} {
      border-color: transparent;
    }
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 28px;
  }

  ${({ mapResults }) =>
    mapResults &&
    css`
      width: 315px;
      margin-bottom: 0;
    `}
`
