export const iconConfigs = [
  { name: 'icon-slide-previous', class: 'c-slide-arrow-left__svg' },
  { name: 'icon-slide-next', class: 'c-slide-arrow-next__svg' },
  { name: 'icon-bathroom' },
  { name: 'icon-bedroom' },
  { name: 'icon-calendar' },
  { name: 'icon-checklist' },
  { name: 'icon-close' },
  { name: 'icon-dash' },
  { name: 'icon-dlstatement' },
  { name: 'icon-down-arrow' },
  { name: 'icon-draw' },
  { name: 'icon-expand' },
  { name: 'icon-facebook' },
  { name: 'icon-favourite-alt' },
  { name: 'icon-favourite' },
  { name: 'icon-garage' },
  { name: 'icon-googleplus' },
  { name: 'icon-instagram' },
  { name: 'icon-left-arrow' },
  { name: 'icon-linkedin' },
  { name: 'icon-location' },
  { name: 'icon-map' },
  { name: 'icon-nav-close' },
  { name: 'icon-nav' },
  { name: 'icon-near-me' },
  { name: 'icon-pintrest' },
  { name: 'icon-print' },
  { name: 'icon-right-arrow' },
  { name: 'icon-school' },
  { name: 'icon-search' },
  { name: 'icon-share' },
  { name: 'icon-slide-next', class: 'c-slide-next__svg' },
  { name: 'icon-slide-previous', class: 'c-slide-previous__svg' },
  { name: 'icon-station' },
  { name: 'icon-study' },
  { name: 'icon-toilet' },
  { name: 'icon-travel-time' },
  { name: 'icon-twitter' },
  { name: 'icon-youtube' },
  { name: 'logo', class: 'c-header-logo__svg' },
  { name: 'logo-footer', class: 'c-header-logo__svg' },
  { name: 'icon-radio-active', class: 'c-icon-radio-active__svg' },
  { name: 'icon-radio-inactive', class: 'c-icon-radio-inactive__svg' },
  { name: 'icon-agent-male', class: 'c-staff-card__img-svg' },
  { name: 'icon-agent-female', class: 'c-staff-card__img-svg' },
  { name: 'icon-google' },
  { name: 'icon-vertical-burger-single' },
  { name: 'icon-vertical-burger' },
  { name: 'icon-edit' },
  { name: 'kp-ahead-sales' },
  { name: 'kp-breeding-agents' },
  { name: 'kp-commissions' },
  { name: 'kp-multi-award' },
  { name: 'kp-number1' },
  { name: 'kp-success' },
  { name: 'icon-quotes' },
  { name: 'icon-attachment', class: 'c-resource-card__attachment-svg' },
  { name: 'icon-download-doc', class: 'c-resource-card__download-doc-svg' },
  { name: 'icon-play-video' },
  { name: 'sm-borrow' },
  { name: 'sm-buyers' },
  { name: 'sm-buying-costs' },
  { name: 'sm-investors' },
  { name: 'sm-market-insight' },
  { name: 'sm-stamp-duty' },
  { name: 'icon-map-pin' },
  { name: 'award-bronze' },
  { name: 'award-gold' },
  { name: 'award-silver' },
  { name: 'icon-propertyalert-1' },
  { name: 'icon-propertyalert-2' },
  { name: 'icon-propertyalert-3' },
  { name: 'icon-checkmark' },
  { name: 'bus' },
  { name: 'chemist' },
  { name: 'childcare' },
  { name: 'eating-out' },
  { name: 'gyms' },
  { name: 'hospitals' },
  { name: 'icon-calc-1' },
  { name: 'icon-calc-2' },
  { name: 'icon-calc-3' },
  { name: 'icon-calc-4' },
  { name: 'icon-calc-5' },
  { name: 'icon-calc-6' },
  { name: 'icon-calc-7' },
  { name: 'icon-calc-8' },
  { name: 'icon-calc-9' },
  { name: 'icon-calc-10' },
  { name: 'icon-calc-11' },
  { name: 'icon-calc-12' },
  { name: 'icon-calc-13' },
  { name: 'icon-calc-14' },
  { name: 'icon-calc-15' },
  { name: 'icon-calc-16' },
  { name: 'icon-calc-17' },
  { name: 'icon-calc-18' },
  { name: 'icon-calc-19' },
  { name: 'medical' },
  { name: 'news-agency' },
  { name: 'parks' },
  { name: 'petrol' },
  { name: 'places-of-worship' },
  { name: 'police-station' },
  { name: 'post-office' },
  { name: 'schools' },
  { name: 'shopping' },
  { name: 'sport' },
  { name: 'train' },
  { name: 'tram' },
  { name: 'icon-private-sale' },
  { name: 'icon-sale-by-auction' },
  { name: 'icon-sale-by-negotiation' },
  { name: 'icon-online-agent-assessment' },
  { name: 'icon-house-info-board' },
  { name: 'icon-map-pins' },
  { name: 'icon-play' },
  { name: 'icon-star' },
  { name: 'icon-chevron-right' },
  { name: 'icon-star-half' }
]
