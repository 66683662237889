import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const FeaturedArticleHero = styled.div`
  display: flex;
  gap: 42px;

  a {
    display: inline-block;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column-reverse;
    gap: 40px;
  }
`

export const FeatureArticleContent = styled.div`
  flex: 0.4;
  display: flex;
  flex-direction: column;

  @media ${DEVICES.mobile} {
    justify-content: flex-start;
  }
`

export const FeaturedArticleTitle = styled.h2`
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: ${FONTS.medium};

  @media ${DEVICES.mobile} {
    ${text2XL};
  }
`

export const FeaturedArticleDescription = styled.div`
  color: ${COLORS.navi};
  margin-bottom: 36px;
`

export const BorderedImageWrap = styled.div`
  flex: 0.6;
  img {
    border-bottom-right-radius: 100px;
  }
`
