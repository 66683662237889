import React, { useState, useEffect, useContext } from 'react'
import { setCookie, JWT_COOKIE, USERID_COOKIE, getCookie } from '../lib/cookies'
import { userDetails, userAlerts } from '../lib/api-services/authService'
import { Router } from '../routes'
import { cmsSettings, getSettings } from '../lib/api-services/settingsService'

export const UserContext = React.createContext()

const initialUserState = {
  token: null, // Stores the auth token for API calls
  userProfile: {}, // Information about the logged in user
  favouriteProperties: [],
  favouriteAgents: [],
  favouriteOffices: [],
  favouritePosts: [],
  isOpenFavouriteModal: false,
}

export const UserContextProvider = ({ ctx, children }) => {
  const [userState, setUserState] = useState(initialUserState)

  // search term (query string "q")
  const { router } = Router
  let q = ''
  if (router && router.query && router.query.q) q = router.query.q
  const [searchTerm, setSearchTerm] = useState(q)

  // current page
  const [currentPage, setCurrentPage] = useState('')

  // login / signup return data
  const [returnto, setReturnto] = useState({
    name: '',
    path: '',
  })

  // search pages
  const [searchPages, setSearchPages] = useState({})
  const getSearchPages = async () => {
    const ApiSearchPages = await getSettings(cmsSettings.searchPages)
    setSearchPages(ApiSearchPages)
  }

  const [alerts, setAlerts] = useState([])
  const getAlerts = async (token) => {
    const alertResponse = await userAlerts(token)
    setAlerts(alertResponse.results)
  }

  // Set API token
  const setToken = (token) => {
    if (!token) return false
    setUserState((prevState) => ({
      ...prevState,
      token: token,
    }))
    setCookie(JWT_COOKIE, token)
  }

  // Set User Profile object
  const setProfile = async (data) => {
    await setUserState((prevState) => ({
      ...prevState,
      userProfile: data,
    }))
  }

  const toggleFavouriteModal = () => {
    setUserState({
      ...userState,
      isOpenFavouriteModal: !userState.isOpenFavouriteModal,
    })
  }

  // Builds the user data object
  const setupUserData = async (ctx) => {
    // get token from cookies
    const token = await getCookie(JWT_COOKIE, ctx)

    if (token) {
      let userData = {}
      // add token to user data (goes to state)
      userData.token = token
      // set new cookie
      await setCookie(JWT_COOKIE, token, 365)
      // get user from API
      const user = await userDetails(token)
      await getAlerts(token)
      // put user details in context
      if (!user.error) {
        userData.userProfile = user
        await setCookie(USERID_COOKIE, user.pk, 365)

        // add user favourites
        if (user.subscriber && user.subscriber.favourites) {
          userData.favouriteProperties = user.subscriber.favourites.listing
          userData.favouriteAgents = user.subscriber.favourites.agent
          userData.favouriteOffices = user.subscriber.favourites.office
          userData.favouritePosts = user.subscriber.favourites.post
        }

        await setUserState((prevState) => ({
          ...prevState,
          ...userData,
        }))
      }
    }
  }

  const resetUserState = () => {
    setUserState(initialUserState)
    setReturnto({
      name: '',
      path: '',
    })
  }

  useEffect(() => {
    ctx && setupUserData(ctx)
    getSearchPages()
  }, [ctx])

  return (
    <UserContext.Provider
      value={{
        ...userState,
        setToken: setToken,
        setProfile: setProfile,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        searchPages: searchPages,
        setSearchPages: setSearchPages,
        returnto: returnto,
        setReturnto: setReturnto,
        resetUserState: resetUserState,
        alerts: alerts,
        setAlerts: setAlerts,
        toggleFavouriteModal: toggleFavouriteModal,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)
