const IconAccount = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4 19V17C4 15.9391 4.42143 14.9217 5.17157 14.1716C5.92172 13.4214 6.93914 13 8 13H12C13.0609 13 14.0783 13.4214 14.8284 14.1716C15.5786 14.9217 16 15.9391 16 17V19M6 5C6 6.06087 6.42143 7.07828 7.17157 7.82843C7.92172 8.57857 8.93914 9 10 9C11.0609 9 12.0783 8.57857 12.8284 7.82843C13.5786 7.07828 14 6.06087 14 5C14 3.93913 13.5786 2.92172 12.8284 2.17157C12.0783 1.42143 11.0609 1 10 1C8.93914 1 7.92172 1.42143 7.17157 2.17157C6.42143 2.92172 6 3.93913 6 5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconAccount
