import React from 'react'
import { COLORS } from '@styles/colors'
import Testimonial from '../SitewideTestimonials/Testimonial'
import GridItems from '@global/GridItems'

const RatedTestimonials = ({ title, testimonials }) => {

  return (
    <GridItems
      title={title}
      items={testimonials}
      itemSize={4}
      minVisibleDesktopItems={3}
      minVisibleMobileItems={2}
      itemMarginBottom={48}
      renderItem={(testimonial) => <Testimonial testimonial={testimonial} />}
      style={{ backgroundColor: COLORS.nature[95], padding: '80px 0' }}
    />
  )
}

RatedTestimonials.defaultProps = {
  title: 'Testimonials',
  testimonials: [],
}

RatedTestimonials.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return
  }

  return {
    title: data.title,
    testimonials:
      data.value?.testimonials?.map((t) => ({
        ...t,
        name: t.headline,
        copy: t.comments,
        image: t.image_thumbnail,
      })) ?? [],
  }
}

export default RatedTestimonials
