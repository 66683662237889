import { useContext, useState, useEffect } from 'react'
import { createFavourite, deleteFavourite } from '@lib/api-services/authService'
import { getCookie, JWT_COOKIE } from '@lib/cookies'
import { Router } from '@routes'
import { UserContext } from '@context/UserContext'
import { AccountContext } from '@context/AccountContext'
import IconFavourite from '@icons/Favourite'
import IconFavouriteFilled from '@icons/FavouriteFilled'
import * as S from './FavouriteHeart.styled'

// create or delete favourite via API
const toggleFavourite = async (data, updateAccount) => {
  const { token, type, id, isFav } = data
  let response

  if (isFav) {
    response = await deleteFavourite(token, type, id)
    if (!response.error) {
      updateAccount(type)
    }
  } else {
    response = await createFavourite(token, type, id)
  }

  return response
}

// favourite heart component
const FavouriteHeart = ({
  type,
  id,
  text,
  noIcon,
  fav,
  label,
  inline,
  label_added,
}) => {
  const {
    token,
    favouriteProperties,
    favouriteAgents,
    favouriteOffices,
    favouritePosts,
    toggleFavouriteModal,
    setReturnto,
  } = useContext(UserContext)
  const account = useContext(AccountContext)

  // don't render if user not logged in
  // if (!token) return false

  // set up favourite state
  const [isFav, setFavourite] = useState(fav || false)

  useEffect(() => {
    // init favourited state if user already saved
    if (type === 'listing' && favouriteProperties.indexOf(id) != -1)
      setFavourite(true)
    else if (type === 'agent' && favouriteAgents.indexOf(id) != -1)
      setFavourite(true)
    else if (type === 'office' && favouriteOffices.indexOf(id) != -1)
      setFavourite(true)
    else if (type === 'post' && favouritePosts.indexOf(id) != -1)
      setFavourite(true)
    else setFavourite(false)
  }, [
    token,
    type,
    id,
    favouriteProperties,
    favouriteAgents,
    favouriteOffices,
    favouritePosts,
  ])

  return (
    <S.FavouriteHeart
      inline={inline}
      onClick={async (e) => {
        e.preventDefault()
        const token = getCookie(JWT_COOKIE)
        if (token) {
          const toggle = await toggleFavourite(
            {
              isFav,
              type,
              id,
              token,
            },
            account.setAccount
          )
          if (!toggle.error) setFavourite(!isFav)
        } else {
          toggleFavouriteModal()
          localStorage.setItem(
            'favouriteParams',
            JSON.stringify({
              type: type,
              id: id,
            })
          )
          setReturnto({
            name: Router.router.pathname,
            path: Router.router.asPath,
          })
        }
      }}
    >
      {text && (
        <span>
          {isFav
            ? label_added || 'Remove from wishlist'
            : label || 'Add to wishlist'}
        </span>
      )}
      {!noIcon &&
        (isFav ? (
          <IconFavouriteFilled />
        ) : (
          <IconFavourite className='icon-favourite' />
        ))}
    </S.FavouriteHeart>
  )
}

export default FavouriteHeart
