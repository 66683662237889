import styled, { css } from 'styled-components'
import { FONTS, text3XL } from '@styles/fonts'
import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'

export const FullWidthText = styled.div``

export const FullWidthTextTitle = styled.h3`
  ${text3XL};
  color: ${COLORS.secondary};
  text-transform: none;
  font-family: ${FONTS.medium};
  letter-spacing: 0.1px;
  margin-bottom: 40px;
`

export const FullWidthTextDescription = styled.div`
  color: ${COLORS.navi};

  p {
    padding-bottom: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FONTS.medium};
    padding-bottom: 16px;
  }
`

export const FullWidthTextAction = styled.div`
  padding-top: 32px;
`
