import Image from '@global/Image'
import { defaultImages } from '@lib/variables'
import { Link } from '@routes'
import * as S from './ContactCard.styled'

const ContactCard = (props) => {
  const {
    image,
    name,
    nameUrl,
    phone,
    link,
    subTitle,
    subTitleUrl,
    urlFullInfo = false,
  } = props
  return (
    <S.ContactCard>
      <Image src={image} alt={name} fallbackImage={defaultImages.largeWhite} />
      {urlFullInfo ? (
        <Link route={nameUrl} className={'url-full-info'}>
          <S.ContactCardInfo>
            <S.ContactCardInfoTitle>{name}</S.ContactCardInfoTitle>
            {subTitle && (
              <S.ContactCardInfoSubTitle>{subTitle}</S.ContactCardInfoSubTitle>
            )}
          </S.ContactCardInfo>
        </Link>
      ) : (
        <S.ContactCardInfo>
          <S.ContactCardInfoTitle>
            {name && nameUrl ? <Link route={nameUrl}>{name}</Link> : name}
          </S.ContactCardInfoTitle>

          {subTitle && (
            <S.ContactCardInfoSubTitle>
              {subTitleUrl ? (
                <Link route={subTitleUrl}>{subTitle}</Link>
              ) : (
                subTitle
              )}
            </S.ContactCardInfoSubTitle>
          )}

          {phone && (
            <S.ContactCardInfoPhone href={`tel:${phone}`}>
              {phone}
            </S.ContactCardInfoPhone>
          )}
        </S.ContactCardInfo>
      )}
      {link && <Link route={link} passHref />}
    </S.ContactCard>
  )
}

export default ContactCard
