import { Col, Row } from '@styles/global.styled'
import FilterGroup from '../FilterGroup'
import { Tag, Tags } from '../FiltersForm.styled'
import { useFormikContext } from 'formik'

const OPTIONS = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: '1+',
    value: 1,
  },
  {
    label: '2+',
    value: 2,
  },
  {
    label: '3+',
    value: 3,
  },
  {
    label: '4+',
    value: 4,
  },
  {
    label: '5+',
    value: 5,
  },
]

const PropertyFeaturesFields = () => {
  const { values, setFieldValue } = useFormikContext()

  const onChange = (name, value) => {
    setFieldValue(name, value)
  }

  return (
    <FilterGroup title='Property Features'>
      <Row gutter={16}>
        <Col md={4}>
          <label>No. of Bedrooms</label>
          <Tags>
            {OPTIONS.map((it) => (
              <Tag
                key={`bedrooms__gte-${it.value}`}
                onClick={() => onChange('bedrooms__gte', it.value)}
                active={(values.bedrooms__gte ?? '') == it.value}
              >
                {it.label}
              </Tag>
            ))}
          </Tags>
        </Col>
        <Col md={4}>
          <label>No. of Bathrooms</label>
          <Tags>
            {OPTIONS.map((it) => (
              <Tag
                key={`bathrooms__gte-${it.value}`}
                onClick={() => onChange('bathrooms__gte', it.value)}
                active={(values.bathrooms__gte ?? '') == it.value}
              >
                {it.label}
              </Tag>
            ))}
          </Tags>
        </Col>
        <Col md={4}>
          <label>No. of Carspaces</label>
          <Tags mbXS={-10}>
            {OPTIONS.map((it) => (
              <Tag
                key={`parking__gte-${it.value}`}
                onClick={() => onChange('parking__gte', it.value)}
                active={(values.parking__gte ?? '') == it.value}
              >
                {it.label}
              </Tag>
            ))}
          </Tags>
        </Col>
      </Row>
    </FilterGroup>
  )
}

export default PropertyFeaturesFields
