import { COLORS } from "@styles/colors";
import { FONTS, textBase, textSM } from '@styles/fonts'
import styled, { css } from 'styled-components'
import { DEVICES } from '@constants/global'

export const ImageGallery = styled.div`
`

export const MainPhoto = styled.div`
  width: calc(66.66666% - 6px);
  flex: 0 0 calc(66.66666% - 6px);
  margin-right: 6px;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;


  > div {
    max-height: 400px;
    height: 400px;
  }

  img {
    width: 100%;
    height: auto !important;
    object-fit: cover;
    cursor: pointer;
  }

  ${({ fullwidth }) =>
    fullwidth &&
    css`
      width: 100%;
      flex: 0 0 100%;
    `}
  @media ${DEVICES.mobile} {
  margin-right: 0;
  flex: 0 0 100%;
  width: 100%;

  > div {
    max-height: 300px;
    height: 300px;
  }
}

  @media ${DEVICES.xs} {
    > div {
      max-height: 200px;
      height: 200px;
    }
  }
`

export const ImageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: end;

  @media ${DEVICES.mobile} {
    flex-direction: column;
    text-align: start;
    align-items: start;
  }
`

export const ImageCount = styled.span`
  ${textBase};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};
  flex-basis: 50%;
  text-align: start;

  strong {
    font-family: ${FONTS.regular};
  }
`

export const ImageCaption = styled.span`
  ${textSM};
  color: ${COLORS.navi};
  font-family: ${FONTS.regular};
  flex-wrap: wrap;
  flex-basis: 50%;
`
