import { COLORS } from '@styles/colors'
import styled from 'styled-components'
import { FONTS } from '@styles/fonts'

export const TestimonialRating = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  color: ${COLORS.primary};
  font-family: ${FONTS.bold};
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }

  span {
    margin-left: 6px;
  }
`

export const TestimonialAuthor = styled.h4`
  color: ${COLORS.secondary};
  margin-bottom: 24px;
`

export const TestimonialDes = styled.p`
  color: ${COLORS.navi};
  margin-bottom: 24px;
`

export const TestimonialFooter = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.grey[60]};

  svg {
    margin-right: 10px;
  }
`

export const Testimonial = styled.div``

export const VerifyWrap = styled.div`
  display: flex;
  align-items: center;
`
