import * as S from './Social.styled'

const Social = ({ title, socialLinks }) => {
  if (!socialLinks?.length) return null

  return (
    <S.Social>
      {title && <h4>{title}</h4>}
      <S.SocialLinks>
        {socialLinks.map((it, index) => (
          <S.SocialLink
            key={`social-${index}`}
            route={it.url}
            passHref
            target='_blank'
          >
            {it.icon}
          </S.SocialLink>
        ))}
      </S.SocialLinks>
    </S.Social>
  )
}

export default Social
