import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textLG } from '@styles/fonts'
import styled from 'styled-components'

export const CloseButton = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: underline;
  font-family: ${FONTS.medium};
  color: ${COLORS.white};

  svg {
    margin-left: 10px;
  }
`

export const ModalContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 6rem);
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 720)}px;
  box-shadow: 4px 4px 24px 0px rgba(0, 23, 135, 0.1);
  border-radius: 0px 0px 80px 0px;
  margin: auto;
  position: relative;

  @media ${DEVICES.mobile} {
    margin-right: 16px;
    margin-left: 16px;
    max-width: 100%;
  }
`

export const ModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  overflow-y: auto;

  &.timeable-modal {
    @media ${DEVICES.mobile} {
      ${ModalContainer} {
        width: auto;
      }
    }
  }
`

export const Header = styled.div`
  padding: 20px 42px 20px 36px;
  background: ${COLORS.navi};

  @media ${DEVICES.mobile} {
    padding: 16px 20px;
  }
`

export const ModalBody = styled.div`
  background: ${COLORS.white};
  border-radius: 0 0 80px 0;
  overflow: hidden;
`

export const Title = styled.h4`
  ${text2XL};
  color: ${COLORS.white};
  font-family: ${FONTS.medium};
  letter-spacing: 1.6px;
  text-transform: uppercase;

  @media ${DEVICES.mobile} {
    ${textLG};
    letter-spacing: 1.2px;
  }
`
