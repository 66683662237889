import styled, { css } from 'styled-components'
import { FONTS, text3XL, textLG } from '@styles/fonts'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

export const Text = styled.div`
  max-width: 570px;
`

export const TextTitle = styled.h3`
  ${text3XL};
  color: ${COLORS.secondary};
  text-transform: none;
  font-family: ${FONTS.medium};
  letter-spacing: 0.1px;
  margin-bottom: 32px;
`

export const TextContent = styled.div`
  ${textLG};
  color: ${COLORS.secondary};

  p {
    padding-bottom: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 16px;
  }

  iframe {
    width: 100%;
    height: 33vh;
  }

  ul {
    margin-bottom: 16px;

    li {
      margin-left: 40px;
    }
  }

  img.richtext-image.full-width {
    width: 100%;
    height: 100%;
  }

  @media ${DEVICES.tablet} {
    iframe {
      height: 25vh;
    }
  }
`
