import IconCloseCircle from '@icons/CloseCircle'
import * as S from './Modal.styled'
import useBodyClass from '@hooks/useBodyClass'

const Modal = ({ toggleModal, title, open, maxWidth, children, className }) => {
  useBodyClass('modal-open', open)

  return open ? (
    <>
      <S.ModalWrap className={className}>
        <S.ModalContainer maxWidth={maxWidth}>
          <S.CloseButton onClick={toggleModal}>
            Close <IconCloseCircle />
          </S.CloseButton>
          <S.Header>
            <S.Title>{title}</S.Title>
          </S.Header>
          <S.ModalBody>{children}</S.ModalBody>
        </S.ModalContainer>
      </S.ModalWrap>
    </>
  ) : null
}

export default Modal
