import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const FilterGroup = styled.div`
  padding: 28px 30px 14px;
  border-bottom: 1px solid ${COLORS.grey[20]};
  color: ${COLORS.secondary};

  label {
    font-family: ${FONTS.medium};
    display: block;
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-bottom: none;
      padding-bottom: 4px;
      margin-bottom: -18px;
    `}

  @media ${DEVICES.mobile} {
    padding: 28px 16px 14px;

    ${({ noBorder }) =>
      noBorder &&
      css`
        border-bottom: none;
        padding-bottom: 4px;
        margin-bottom: -18px;
      `}
  }
`

export const FilterGroupTitle = styled.h4`
  color: ${COLORS.secondary};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: ${FONTS.bold};
  margin-bottom: 16px;
`
