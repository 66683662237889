import dynamic from 'next/dynamic'
import SVGIcon from '../../global/icon/svgIcon'
import { Container } from '@styles/global.styled'
import Image from '@global/Image'

const Carousel = dynamic(() => import('@global/Carousel'))

const IconSlider = ({ title, icons }) => {
  return (
    <Container>
      <h4 className='u-h4'>{title}</h4>
      <Carousel>
        {icons?.length > 0 &&
          icons.map((icon, key) => (
            <div key={`icon-${key}`} className='c-slider__slide o-layout__item'>
              <div className='c-icon-slider__slide'>
                <div className='c-icon-slider__icon'>
                  <a target='_blank' href={icon.link}>
                    {icon.icon && <SVGIcon name={icon.icon} />}
                    {icon.image && (
                      <Image
                        src={icon.image}
                        alt=''
                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                      />
                    )}
                  </a>
                </div>
                {icon.label && (
                  <div className='c-icon-slider__label'>{icon.label}</div>
                )}
              </div>
            </div>
          ))}
      </Carousel>
    </Container>
  )
}

IconSlider.defaultProps = {
  title: 'Awards',
  icons: [],
  link: {
    label: 'view all awards ',
    url: '#',
  },
}
IconSlider.ApiBlockToProps = (data, type) => {
  const rawData = data.value
  switch (type) {
    case 'community_causes': {
      return {
        icons: rawData.gallery_items.map((item) => {
          return {
            image: item.image,
            label: item.name,
            link: item.link,
            icon: null,
          }
        }),
        title: rawData.title,
      }
    }
    case 'awards': {
      return {
        title: rawData.title,
        icons: rawData.awards.map((item) => {
          return {
            icon: 'award-' + item.colour,
            label: item.name,
          }
        }),
      }
    }
    case 'developers': {
      return {
        icons: rawData.developers.map((item) => {
          return {
            image: item.logo,
            label: item.name,
            link: item.link,
            icon: null,
          }
        }),
        title: rawData.heading,
      }
    }
  }
}
export default IconSlider
