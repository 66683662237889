const IconSelect2ArrowDown = (props) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3 6.86753L8.34584 12.2142C8.58571 12.4533 8.97429 12.4533 9.21416 12.2142L14.56 6.86753L13.6925 6L8.77996 10.9125L3.86745 6L3 6.86753Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconSelect2ArrowDown
