import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const PropertyClassField = styled.div`
  display: flex;
  align-items: center;
  margin: 0 30px 28px;

  @media ${DEVICES.mobile} {
    margin: 0 16px 24px;
  }
`

export const PropertyClass = styled.div`
  flex: 1;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-family: ${FONTS.bold};
  color: ${COLORS.grey[40]};
  border: 1px solid ${COLORS.grey[20]};
  padding: 10px 18px;
  letter-spacing: 1px;
  cursor: pointer;

  &:first-child {
    border-radius: 28px 0 0 28px;
  }

  &:last-child {
    border-radius: 0 28px 28px 0;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${COLORS.primary};
      border-color: ${COLORS.primary};
      color: ${COLORS.white};
    `}

  @media ${DEVICES.mobile} {
    padding: 8px 10px;
  }
`
