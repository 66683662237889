// Defines routes extra to those handled by the next.js
// file structure (pages/<page>.js)

const routes = require('next-routes')
const propertyChannelsRegex = `(for-sale|rental-properties|sold-properties)`
const stateRegex = `(vic|wa|sa|nsw|qld|tas|act|nt)`

module.exports = routes()
  // Account page
  .add('reset-password')
  .add('my-account')
  .add('off-market-login')
  .add('project-properties')
  .add('wagtail-preview')
  .add('listing-referral')
  .add({
    name: 'projectDetails',
    pattern: '/projects/:slug/',
    page: 'project-details',
  })
  .add({
    name: 'acceptReferral',
    pattern: '/listing-referral/accept/',
    page: 'listing-referral/accept',
  })
  .add({
    name: 'rejectReferral',
    pattern: '/listing-referral/reject/',
    page: 'listing-referral/reject',
  })
  .add({
    name: 'updatePassword',
    pattern: '/update-password/:uid-:token/',
    page: 'update-password',
  })
  // testimonials
  .add({
    name: 'testimonialDetails',
    pattern: '/testimonials/:slug/:id/',
    page: 'testimonial-details',
  })

  // properties
  .add({
    name: 'propertyDetails',
    pattern: `/:type${propertyChannelsRegex}/:slug-:id(\\d+)/`,
    page: 'property-details',
  })

  // Legacy Properties URL, triggers a redirect
  .add({
    name: 'propertyDetailsLegacy',
    pattern: '/property/:uniqueId/:slug',
    page: 'property-details',
  })

  // suburb properties new
  .add({
    name: 'suburbPropertiesBedrooms',
    pattern: `/:channel${propertyChannelsRegex}/:suburb-:state${stateRegex}/:bedrooms(\\d+)-bedrooms/`,
    page: 'suburb-properties',
  })

  // appraisal request with slug
  .add({
    name: 'appraisalRequestSlug',
    pattern: '/request-an-appraisal/:slug',
    page: 'appraisal-request',
  })

  .add({
    name: 'suburbProperties',
    pattern: `/:channel${propertyChannelsRegex}/:suburb-:state${stateRegex}/:categories?/`,
    page: 'suburb-properties',
  })

  // wagtail catchall
  .add({
    name: 'wagtail_catchall',
    pattern: '/:slug*/',
    page: 'wagtail',
  })
