import * as S from './Button.styled'

const Button = ({
  children,
  border,
  block,
  color,
  endIcon,
  size,
  startIcon,
  circle,
  disabled,
  type,
  ...props
}) => {
  return (
    <S.Button
      block={block}
      border={border}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      circle={circle}
      type={type}
      disabled={disabled}
      {...props}
    >
      {startIcon}
      {children}
      {endIcon}
    </S.Button>
  )
}

export default Button
