import OfficeCard from '@global/OfficeCard'
import { Container } from '@styles/global.styled'
import dynamic from 'next/dynamic'

const Carousel = dynamic(() => import('@global/Carousel'))

const ServicedByTitles = ({ title, offices }) => {
  return (
    <Container>
      {title && <h5 className='c-serviced-by-tiles__title'>{title}</h5>}
      <Carousel>
        {offices &&
          offices.length > 0 &&
          offices.map((office, key) => (
            <div
              key={`office-${key}`}
              className='c-slider__slide o-layout__item o-island'
            >
              <OfficeCard
                {...office}
                id={office.id}
                link={office.link}
                img={office.img}
                name={office.name}
                address={office.address}
                phoneLabel={office.phoneLabel}
                phone={office.phone}
                email={office.email}
                emailLabel={office.emailLabel}
                profileLink={office.profileLink}
                directionsLink={office.directionsLink}
              />
            </div>
          ))}
      </Carousel>
    </Container>
  )
}

ServicedByTitles.defaultProps = {
  title: 'Serviced By',
  offices: [],
}

// eslint-disable-next-line react/display-name
ServicedByTitles.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return null
  }
  return {
    title: 'Serviced By',
    offices: data.value.map((item) => {
      return {
        ...item,
        id: item.id,
        img: {
          url: item.results_image_small,
          alt: item.title,
        },
        name: item.title,
        link: item.path,
        address: item.address,
        phone: item.phone,
        phoneLabel: 'T',
        email: item.email,
        emailLabel: 'Email',
        profileLink: {
          label: 'office profile',
          link: item.path,
        },
        directionsLink: {
          label: 'get directions',
        },
      }
    }),
  }
}

export default ServicedByTitles
