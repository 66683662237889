import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'


export const SelectedFilters = styled.div`
  font-family: ${FONTS.bold};
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  display: none;
  
  
  > svg {
    width: 11px;
    height: 11px;
    stroke: ${COLORS.secondary};
  }
  
  @media ${DEVICES.mobile} {
    display: block;
  }
`

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: 52px;
  color: ${COLORS.secondary};
  flex: 1;

  > svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }

  ${({ size }) =>
    size === 'big' &&
    css`
      margin-bottom: 44px;
      margin: 0 30px;

      input {
        border-color: ${COLORS.secondary};
        padding: 16px 20px 16px 56px;
      }

      @media ${DEVICES.mobile} {
        margin: 0 16px;
      }
    `}

  .bp-autocomplete {
    width: 100%;

    &__control {
      padding: 8px 20px 8px 46px;
      border: 1px solid #dbd7cd;
      border-radius: 4px;
      width: 100%;
      font-size: 16px;
      color: ${COLORS.secondary};
      box-shadow: none;

      &::placeholder {
        color: ${COLORS.secondary};
      }
    }

    &__input-container {
      font-size: 16px;
      color: ${COLORS.secondary};
    }

    &__multi-value {
      position: relative;
      margin: 0 0 0 5px;
      border-radius: 30px;
      padding: 0 8px;
      background-color: ${COLORS.nature[95]};

      &__label {
        color: ${COLORS.secondary};
        font-family: ${FONTS.regular};
        font-size: 16px;
        text-transform: capitalize;
      }

      &__remove {
        cursor: pointer;
        transition: all 0.25s ease-in;

        &:hover {
          background-color: transparent;
          color: ${COLORS.primary};
        }
      }
    }

    &__single-value,
    &__placeholder,
    &__input {
      color: ${COLORS.secondary};
    }
  }

  ${({ showFilters }) =>
    showFilters &&
    css`
      @media ${DEVICES.mobile} {
        .bp-autocomplete {
          &__control {
            padding: 8px 95px 8px 8px !important;
          }

          &__input {
            padding: 2px;
          }

          .bp-autocomplete__menu-portal {
            position: relative;
            top: 0;
            left: 0;

            .bp-autocomplete__menu {
              overflow-y: scroll;
              height: 200px;
              bottom: 50px;
            }
          }
        }
      }
    `}
  
  @media ${DEVICES.mobile} {
  > svg {
    right: 10px;
    left: unset;
  }

  .bp-autocomplete {
    &__control {
      padding: 8px 40px 8px 8px;
    }
  }
}
`


