const IconGoogle = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 28 28" fill="currentColor" {...props}>
  <g clip-path="url(#clip0_2483_51879)">
    <path d="M0 14C0 6.2804 6.2804 0 14 0C17.1177 0 20.0687 1.00307 22.5339 2.9008L19.2805 7.12693C17.7563 5.9536 15.9303 5.33333 14 5.33333C9.2212 5.33333 5.33333 9.2212 5.33333 14C5.33333 18.7788 9.2212 22.6667 14 22.6667C17.8489 22.6667 21.1197 20.1449 22.2469 16.6667H14V11.3333H28V14C28 21.7196 21.7196 28 14 28C6.2804 28 0 21.7196 0 14Z" fill="currentColor"/>
  </g>
  <defs>
    <clipPath id="clip0_2483_51879">
      <rect width="28" height="28" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

export default IconGoogle
