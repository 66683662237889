import { Col, Container, Row } from '@styles/global.styled'
import ImageText from './ImageText'
import PropTypes from 'prop-types'

const TwoColomnImageText = ({ textImageBlocks }) => {
  if (!textImageBlocks.length) return null

  return (
    <Container>
      <Row gutter={15}>
        {textImageBlocks.map((item, index) => (
          <Col md={6} key={index}>
            <ImageText item={item} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

TwoColomnImageText.propTypes = {
  textImageBlocks: PropTypes.array,
}

TwoColomnImageText.defaultProps = {
  textImageBlocks: [],
}

TwoColomnImageText.ApiBlockToProps = (data) => {
  const textImageBlocks = []
  data.value &&
    data.value.panels &&
    data.value.panels.map((panel) => {
      let panelData = {
        title: panel.heading,
        description: panel.content,
      }
      if (panel.call_to_action_text) {
        panelData.link = {
          text: panel.call_to_action_text,
          url:
            panel.call_to_action_link && panel.call_to_action_link.path
              ? panel.call_to_action_link && panel.call_to_action_link.path
              : panel.call_to_action_external_link
              ? panel.call_to_action_external_link
              : '#',
        }
      }

      if (panel.image) {
        panelData.image = {
          url: panel.image,
          alt: '',
        }
      }

      textImageBlocks.push(panelData)
    })
  return { textImageBlocks: textImageBlocks }
}

export default TwoColomnImageText
