const CarouselPrevArrow = props => {
  return (
    <svg
      width='9'
      height='17'
      viewBox='0 0 9 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.43164 8.66357L7.21609 0.663574L8.43164 2.09691L2.83447 8.66357L8.40337 15.2302L7.18783 16.6636L0.43164 8.66357Z'
        fill='white'
      />
    </svg>
  )
}

export default CarouselPrevArrow
