import JobCard from '@components/Careers/JobCard'
import { Col, Row } from '@styles/global.styled'

const JobCardGrid = ({ jobs }) => {
  if (!jobs?.length) return null

  return (
    <Row gutter={15}>
      {jobs.map((job, i) => (
        <Col md={4} key={`job-${i}`} flex>
          <JobCard job={job} />
        </Col>
      ))}
    </Row>
  )
}

JobCardGrid.defaultProps = {
  jobs: [],
}

export default JobCardGrid
