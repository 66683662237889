import { createContext, useContext, useState } from 'react'

export const defaultFormModalContext = {
  isShowFormModal: false,
  heading: '',
}

export const FormModalContext = createContext(defaultFormModalContext)

export const useFormModalContext = () => useContext(FormModalContext)

export const FormModalProvider = ({ children }) => {
  const [heading, setHeading] = useState('')
  const [isShowFormModal, setShowFormModal] = useState(false)

  return (
    <FormModalContext.Provider
      value={{ heading, setHeading, isShowFormModal, setShowFormModal }}
    >
      {children}
    </FormModalContext.Provider>
  )
}
