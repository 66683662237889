import { useEffect } from 'react'

const useBodyClass = (className, shouldAdd) => {
  useEffect(() => {
    if (shouldAdd) {
      document.documentElement.classList.add(className)
      document.body.classList.add(className)
    } else {
      document.body.classList.remove(className)
      document.documentElement.classList.remove(className)
    }
  }, [shouldAdd])
}

export default useBodyClass
