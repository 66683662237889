export const DynamicFormData = {
  'franchising-enquiry': {
    leftFieldsName: [
      'first-name',
      'last-name',
      'email-address',
      'phone-number',
    ],
    rightFieldsName: ['comments'],
  },

  sell: {
    leftFieldsName: [
      'name',
      'phone-number',
      'email-address',
      'property-address',
    ],
    rightFieldsName: ['type-message-here-optional'],
  },

  'request-call-back': {
    leftFieldsName: [
      'full-name',
      'contact-phone-number',
      'email-address',
      'how-can-we-help-you',
    ],
    rightFieldsName: ['comments'],
  },

  'free-contract-review-request': {
    leftFieldsName: [
      'first-name',
      'last-name',
      'mobile-number',
      'email-address',
    ],
    rightFieldsName: ['property-address-for-contract-review'],
  },

  'settle-easy-conveyancing-request': {
    leftFieldsName: ['first-name', 'last-name', 'referring-office'],
    rightFieldsName: [
      'mobile-number',
      'email-address',
      'name-of-referring-agent',
    ],
  },

  'request-appraisal': {
    leftFieldsName: [
      'full-name',
      'contact-phone-number',
      'email-address',
      'how-can-we-help-you',
    ],
    rightFieldsName: ['full-property-address'],
  },

  default: {
    leftFieldsName: [
      'name',
      'phone-number',
      'email-address',
      'property-address',
    ],
    rightFieldsName: ['type-message-here-optional'],
  },
}
