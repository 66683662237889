import { useContext, useRef, useState } from 'react'
import { UserContext } from '@context/UserContext'
import Button from '@global/Button'
import IconClose from '@global/Icons/Close'
import IconAccount from '@global/Icons/Account'
import { Link } from '@routes'
import useWindowSize from '@hooks/useWindowSize'
import { OfficeSiteContext } from '@context/OfficeSiteContext'
import Menu from './Menu'
import useBodyClass from '@hooks/useBodyClass'
import useClickOutside from '@hooks/useClickOutside'

const Sidebar = ({ menuData }) => {
  const windowSize = useWindowSize()
  const { megaNavMenus, mainMenus } = menuData
  const officeContext = useContext(OfficeSiteContext)
  const { userProfile } = useContext(UserContext)
  const [subMenuActive, setSubMenuActive] = useState(null)
  const [menuOpen, setMenuOpen] = useState(null)
  const lastMenuItem = mainMenus.length > 0 && mainMenus[mainMenus.length - 1]
  const navRef = useRef(null)

  const closeMenu = () => {
    setMenuOpen(false)
    setSubMenuActive(null)
  }

  useBodyClass('sub-menu-active', subMenuActive !== null)
  useBodyClass('menu-open', menuOpen)

  useClickOutside(navRef, () => setSubMenuActive(null))

  return (
    <nav
      className={`bp-sidebar ${menuOpen ? 'active' : ''} ${
        subMenuActive !== null ? 'sub-menu-active' : ''
      }`}
      ref={navRef}
    >
      {subMenuActive !== null && (
        <IconClose
          className='close-menu-btn'
          style={{
            left:
              windowSize?.width > 1920
                ? 'calc(((100vw - 1920px) / 2) + 642px)'
                : '642px',
          }}
          onClick={() => setSubMenuActive(null)}
        />
      )}

      <div
        className={`bp-nav-header ${
          officeContext?.currentSite?.officeId ? 'with-office' : ''
        }`}
      >
        <div className='bp-logo'>
          <Link route='/' passHref>
            <img src='/imgs/logo.svg' alt='Barryplant' />
          </Link>
          {officeContext?.currentSite?.officeId && (
            <Link
              className='bp-office-name'
              route={officeContext?.currentSite?.officePath}
              passHref
            >
              {officeContext?.currentSite?.officeName}
            </Link>
          )}
        </div>

        <div
          className={`mobile-burger ${menuOpen ? 'close' : ''}`}
          onClick={() => {
            setMenuOpen(!menuOpen)
            setSubMenuActive(null)
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <Menu
        open={menuOpen}
        setMenuOpen={setMenuOpen}
        subMenuActive={subMenuActive}
        setSubMenuActive={setSubMenuActive}
        menuItems={
          officeContext?.currentSite?.officeId
            ? officeContext?.currentSite?.menus
            : megaNavMenus
        }
        withOffice={officeContext?.currentSite?.officeId}
      />

      {!officeContext?.currentSite?.officeId && (
        <ul className='bp-main-menu'>
          {mainMenus.map(
            (it, key) =>
              key < mainMenus.length - 1 && (
                <li className='bp-main-menu-item' key={`main-menu-${key}`}>
                  <Link route={it.href} passHref onClick={closeMenu}>
                    {it.label}
                  </Link>
                </li>
              )
          )}
        </ul>
      )}

      {lastMenuItem && (
        <Link
          href={
            officeContext?.currentSite?.officeId
              ? `${
                  lastMenuItem.href
                }${officeContext?.currentSite?.officePath.replace(
                  '/offices/',
                  ''
                )}`
              : lastMenuItem.href
          }
          passHref
          className='last-main-menu-item'
          onClick={closeMenu}
        >
          <Button color='primary'>{lastMenuItem.label}</Button>
        </Link>
      )}

      {userProfile && userProfile.first_name ? (
        <div className='bp-sidebar-auth-link'>
          <Link route='/my-account/' passHref onClick={closeMenu}>
            <div className='user-info'>
              <IconAccount /> My account
            </div>
          </Link>
        </div>
      ) : (
        <div className='bp-sidebar-auth-link'>
          <Link route='/login/' passHref prefetch={false} onClick={closeMenu}>
            Sign in
          </Link>
          <Link
            route='/create-account/'
            passHref
            prefetch={false}
            onClick={closeMenu}
          >
            Join
          </Link>
        </div>
      )}
    </nav>
  )
}

export default Sidebar
