import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, text3XL } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const TwinEqualCTA = styled.div`
  > div {
    margin-bottom: -28px;
  }
`

export const Title = styled.h2`
  ${text3XL};
  max-width: 575px;
  color: ${COLORS.secondary};
  margin-bottom: 44px;

  @media ${DEVICES.mobile} {
    ${text2XL}
    max-width: 358px;
    text-align: start;
    letter-spacing: 1.6px;
    margin-bottom: 36px;
  }

  ${({ titleStyle }) =>
    titleStyle === 'normal' &&
    css`
      max-width: 100%;
      text-transform: unset;
      font-family: ${FONTS.medium};
      margin-bottom: 32px;

      @media ${DEVICES.mobile} {
        margin-bottom: 20px;
      }
    `}
`

export const CardImage = styled.div`
  width: 100%;
  display: block;
  position: relative;
  padding-top: 66.5%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

export const CardBody = styled.div`
  background-color: ${COLORS.nature[95]};
  position: absolute;
  width: 100%;
  transition: all 0.25s ease-out;

  ${({ height, titleHeight }) =>
    height &&
    titleHeight &&
    css`
      bottom: ${-height + titleHeight}px;
    `}

  @media ${DEVICES.mobile} {
    position: relative;
    bottom: 0;
  }
`

export const CardTitle = styled.h4`
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  font-size: 22px;
  line-height: 30px;
  padding: 22px 20px;
  text-transform: unset;

  @media ${DEVICES.mobile} {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 18px;
    white-space: unset;
  }
`

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px 22px;
  background-color: ${COLORS.nature[95]};

  svg {
    flex: 0 0 36px;
    color: ${COLORS.secondary};
    transition: color 0.25s ease-in;
    margin-left: auto;
  }
`

export const Description = styled.p`
  color: ${COLORS.navi};
  margin-right: 20px;
  line-height: 22px;
`

export const Card = styled.div`
  position: relative;
  border-radius: 0px 0px 40px;
  background-color: ${COLORS.nature[95]};
  margin-bottom: 28px;
  overflow: hidden;
  padding-bottom: 74px;

  @media ${DEVICES.mobile} {
    padding-bottom: 0;
  }

  &:hover {
    ${CardBody} {
      bottom: 0;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`
