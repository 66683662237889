import { useCallback, useState } from 'react'
import { Link } from '@routes'
import { Row, Col } from '@styles/global.styled'
import IconPlus from '@icons/Plus'
import OfficesList from './OfficeList'
import SocialLinks from './SocialLinks'
import IconMinus from '@icons/Minus'
import { useInView } from 'react-intersection-observer'

import * as S from './Footer.styled'

const Footer = ({ isHomePage, menuData }) => {
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true })
  const { footerMenus = [], footerMainMenus = [] } = menuData
  const [activeMenu, setActiveMenu] = useState(null)

  const toggleMenu = useCallback(
    (key) => {
      if (activeMenu === key) {
        setActiveMenu(null)
      } else {
        setActiveMenu(key)
      }
    },
    [activeMenu]
  )

  return (
    <>
      <S.Footer ref={ref}>
        <S.FooterInner>
          <S.FooterBody>
            <Row gutter={15}>
              {footerMainMenus.map((it, key) => (
                <Col md={6} lg={4} xl={3} key={`footer-main-menu-${key}`}>
                  <S.FooterHeading onClick={() => toggleMenu(key)}>
                    {it.heading}{' '}
                    {activeMenu === key ? <IconMinus /> : <IconPlus />}
                  </S.FooterHeading>
                  {it.menu && (
                    <S.FooterMenu $active={activeMenu === key}>
                      {it.menu.map(
                        (menuItem, key) =>
                          menuItem.url && (
                            <S.FooterMenuItem
                              key={`footer-main-menu-item-${key}`}
                            >
                              <Link route={menuItem.url} passHref>
                                {menuItem.label}
                              </Link>
                            </S.FooterMenuItem>
                          )
                      )}
                    </S.FooterMenu>
                  )}
                </Col>
              ))}
            </Row>
          </S.FooterBody>

          <S.FooterBottom>
            <S.FooterBottomGroup>
              {inView && (
                <Link route='/' passHref>
                  <S.FooterLogo src='/imgs/logo-white.svg' alt='BarryPlant' />
                </Link>
              )}
              <SocialLinks />
            </S.FooterBottomGroup>
            <S.FooterBottomGroup>
              <S.Copyright>
                &copy; {new Date().getFullYear()} Barry Plant Pty Ltd.
                <span>All rights reserved.</span>
              </S.Copyright>

              <S.FooterBottomLinks>
                {footerMenus.map(
                  (it) =>
                    it.url && (
                      <S.FooterBottomLink key={it.url}>
                        <Link route={it.url} passHref>
                          {it.label}
                        </Link>
                      </S.FooterBottomLink>
                    )
                )}
              </S.FooterBottomLinks>
            </S.FooterBottomGroup>
          </S.FooterBottom>
        </S.FooterInner>
      </S.Footer>
      {isHomePage && <OfficesList />}
    </>
  )
}

export default Footer
