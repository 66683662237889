import React, { useState } from 'react'
import { apiFieldsQuery } from '@lib/variables'
import { truncateText, stripTags } from '@lib/helpers'
import dynamic from 'next/dynamic'
import JobCardGrid from '@components/Careers/JobCardGrid'
import LoadmoreInfo from '@global/SearchResults/LoadmoreInfo'
import { getChildPages } from '@lib/api-services/cmsService'
import NoResults from '@global/SearchResults/NoResults'

const JobFilters = dynamic(() => import('@components/Careers/JobFilters'), {
  ssr: false,
})

const formatJobItems = (items) =>
  items.map((item) => ({
    name: item.title || item.job_name,
    location: item.location?.title || item.location,
    type: item.get_job_type_display,
    description: truncateText(stripTags(item.description), 100, '…'),
    link: item.meta?.path || item.path,
  }))

const JobResults = ({
  title,
  parentId,
  itemPerPage,
  searchFields,
  apiCall,
  ...props
}) => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: itemPerPage || 15,
  })
  const [jobs, setJobs] = useState({
    ...props.jobs,
  })

  const getJobs = async (params, isClearSearch) => {
    const page = params.page || 1
    let nQueryParams = { ...queryParams, ...params, page }

    if (isClearSearch) {
      nQueryParams = {
        page: 1,
      }
    }

    setQueryParams({ ...nQueryParams })

    if (apiCall) {
      const resp = await apiCall(nQueryParams)

      const data = {
        ...resp,
        results: resp.results ? formatJobItems(resp.results) : [],
      }

      if (page === 1) {
        setJobs({
          ...data,
        })
      } else {
        return data
      }
    } else {
      const resp = await getChildPages(
        'careers.CareerPage',
        parentId,
        itemPerPage,
        page,
        nQueryParams,
        apiFieldsQuery.CareerPage
      )

      const data = {
        count: resp.meta.total_count,
        results: resp.items ? formatJobItems(resp.items) : [],
      }

      if (page === 1) {
        setJobs({
          ...data,
        })
      } else {
        return data
      }
    }
  }

  return (
    <>
      <JobFilters
        title={title}
        total={jobs.count}
        queryParams={queryParams}
        searchFields={searchFields}
        onChange={getJobs}
      />
      {jobs.count > 0 ? (
        <React.Fragment>
          <JobCardGrid jobs={jobs.results} />
          <LoadmoreInfo
            total={jobs.count}
            numberShowing={queryParams.page * queryParams.limit}
            queryParams={queryParams}
            apiCall={getJobs}
            page={queryParams.page}
            onSuccess={(data) => {
              setJobs((prev) => ({
                ...prev,
                results: prev.results.concat(data),
              }))
            }}
          />
        </React.Fragment>
      ) : (
        <NoResults
          title='There are no jobs matching your search.'
          description='Please adjust your search filters, check back later, or talk to one of our agents for assistance.'
          onClearSearch={() => getJobs({}, true)}
        />
      )}
    </>
  )
}

export default JobResults
