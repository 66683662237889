const ArrowRightCircleFilled = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='18' cy='18' r='18' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4672 24.6663L24.6211 17.9997L18.4672 11.333L17.484 12.2406L22.1871 17.3357L11.3334 17.3357L11.3334 18.6739L22.1776 18.6739L17.484 23.7587L18.4672 24.6663Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default ArrowRightCircleFilled
