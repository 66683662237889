import { COLORS } from '@styles/colors'
import { FONTS, textLG } from '@styles/fonts'
import styled from 'styled-components'

export const OurResults = styled.div`
  margin-bottom: -48px;
`

export const OurResultsItem = styled.div`
  margin-bottom: 48px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 12px;
  }
`

export const OurResultsItemNumber = styled.span`
  ${textLG};
  font-family: ${FONTS.medium};
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.nature[95]};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const OurResultsItemTitle = styled.h4`
  ${textLG};
  font-family: ${FONTS.medium};
  margin-bottom: 16px;
`

export const OurResultsItemDes = styled.p`
  color: ${COLORS.navi};
`
