import { Container, Row, Col, SectionTitle } from '@styles/global.styled'
import * as S from './OurResults.styled'
import Image from '@global/Image'

const OurResultsItem = ({ isNumberedItem, item }) => {
  return (
    <S.OurResultsItem>
      {isNumberedItem ? (
        <S.OurResultsItemNumber>{item.number}</S.OurResultsItemNumber>
      ) : item.image ? (
        <Image src={item.image} alt={item.title} />
      ) : null}
      <S.OurResultsItemTitle>{item.title}</S.OurResultsItemTitle>
      <S.OurResultsItemDes>{item.description}</S.OurResultsItemDes>
    </S.OurResultsItem>
  )
}

const OurResults = ({ title, isNumberedItems, items = [] }) => {
  return (
    <Container>
      <SectionTitle>{title}</SectionTitle>
      <S.OurResults>
        <Row gutter={16}>
          {items.map((item, index) => (
            <Col md={4} key={index}>
              <OurResultsItem
                item={{ ...item, number: index + 1 }}
                isNumberedItem={isNumberedItems}
              />
            </Col>
          ))}
        </Row>
      </S.OurResults>
    </Container>
  )
}

OurResults.ApiBlockToProps = (data) => {
  if (!data.value) {
    return
  }

  const componentProps = {
    title: data.value.title,
    isNumberedItems: data.value.numbered_items,
    items:
      data.value.results?.map((result) => ({
        title: result.title,
        description: result.content,
        smallText: result.attribution || '',
        icon: result.icon,
        image: result.image,
      })) ?? [],
  }

  return componentProps
}

export default OurResults
