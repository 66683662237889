import React from 'react'
import { defaultImages } from '../../lib/variables'
import IconSliderPrev from './icon/IconSliderPrev'
import IconSliderNext from './icon/IconSliderNext'
import { isExternalURL } from '../../lib/helpers'
import Slider from 'react-slick'

class FeaturedSuburbs extends React.Component {
  state = {
    slideIndex: 0,
  }

  prev = () => {
    this.slider.slickPrev()
  }

  next = () => {
    this.slider.slickNext()
  }
  sliderDotClick = (index) => {
    this.slider.slickGoTo(index)
  }
  beforeChange = (current, next) => {
    if (!this.slider) return
    if (current == next) return
    let itemPerPage = this.slider.props.slidesToShow,
      page = 0
    if (this.slider.state && this.slider.state.breakpoint) {
      let breakPoint = this.slider.props.responsive.find(
        (s) => s.breakpoint == this.slider.state.breakpoint
      )
      if (breakPoint) {
        itemPerPage = breakPoint.settings.slidesToShow
      }
    }
    if (next > 0) {
      page = next / itemPerPage
    }
    this.setState({ slideIndex: page })
  }
  appendDots = (dots) => {
    this.setState((prev) => ({ ...prev, totalDots: dots.length }))
    const { slideIndex } = this.state
    const { isWhite } = this.props
    return (
      <div>
        <div className='c-cb4-2__bottom o-layout'>
          <div className='o-layout__item no-padding c-cb4-2__slider-nav o-module__item u-5/12@desktop u-8/12@tablet'>
            <div className={`c-slider__footer ${isWhite ? 'white' : ''}`}>
              <div className='c-slider__nav'>
                <button
                  className='c-slider__prev'
                  onClick={this.prev}
                  aria-label='Left Align'
                >
                  <IconSliderPrev />
                </button>
                <div className='c-slider__dots'>
                  <ul className='slick-dots' role='tablist'>
                    {dots &&
                      dots.map((item, i) => {
                        return (
                          <li
                            key={i}
                            className={slideIndex == i ? 'slick-active' : ''}
                            role='presentation'
                          >
                            {slideIndex == i ? (
                              <button
                                type='button'
                                role='tab'
                                id={'slick-slide-control00' + i * 3}
                                aria-controls='slick-slide00'
                                aria-label={`${i + 1} of ${dots.length}`}
                                tabIndex='0'
                                aria-selected='true'
                                onClick={() => this.sliderDotClick(i)}
                              />
                            ) : (
                              <button
                                type='button'
                                role='tab'
                                id={'slick-slide-control00 ' + i}
                                aria-controls='slick-slide00'
                                aria-label={`${i + 1} of ${dots.length}`}
                                tabIndex='0'
                                onClick={() => this.sliderDotClick(i)}
                              />
                            )}
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <button
                  className='c-slider__next'
                  onClick={this.next}
                  aria-label='Left Align'
                >
                  <IconSliderNext />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { slides, heading, link } = this.props
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      appendDots: this.appendDots,
      beforeChange: this.beforeChange,
    }
    return (
      <div className='o-wrapper'>
        <div className='c-cb4-2'>
          <h4 className='c-cb4-2__label u-h4'>{heading}</h4>
          <div className='c-slider c-cb4-2__slider'>
            <Slider
              ref={(c) => (this.slider = c)}
              {...settings}
              className='c-slider__slides is-one-col'
            >
              {slides &&
                slides.map((slide, index) => {
                  return (
                    <div key={index} className='c-slider__slide'>
                      <div className='o-layout o-module'>
                        <div className='o-layout__item c-cb4-2__image-wrap o-module__item u-7/12@desktop'>
                          <div
                            className='o-module__content u-image__bg-75'
                            style={{
                              backgroundImage: `url(${
                                slide.image.url || defaultImages.mediumNavy
                              })`,
                            }}
                          />
                        </div>
                        <div className='o-layout__item c-cb4-2__content-wrap o-module__item u-5/12@desktop'>
                          <div className='c-cb4-2__content'>
                            <h3 className='c-cb4-2__name u-h4'>
                              <a className='suburbs_link' href={slide.link}>
                                {slide.name}
                              </a>
                            </h3>
                            <h4 className='c-cb4-2__state'>{slide.state}</h4>
                            <p className='c-cb4-2__text'>{slide.text}</p>
                          </div>
                          <div className='c-cb4-2__price-wrap c-cb4-2__price-wrap--top'>
                            <p className='c-cb4-2__price'>{slide.price}</p>
                            <p className='c-cb4-2__price-label'>
                              {slide.priceLabel}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </Slider>
          </div>
          <div className='c-cb4-2__bottom o-layout o-layout--large o-module'>
            <div className='o-layout__item c-cb4-2__link-wrap o-module__item u-5/12@desktop u-4/12@tablet'>
              <a
                target={isExternalURL(link.url) ? '_blank' : '_self'}
                href={link.url}
                className='c-link c-link--brand'
              >
                {link.text}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FeaturedSuburbs.defaultProps = {
  heading: 'Featured suburbs',
}

FeaturedSuburbs.ApiBlockToProps = (data) => {
  let rawData = data.value
  return {
    slides:
      rawData.suburbs &&
      rawData.suburbs.map((item) => {
        return {
          image: {
            url: item.featured_image_thumb && item.featured_image_thumb.url,
            alt: item.title,
          },
          name: item.title,
          state: `${item.state.toUpperCase() || ''} ${item.postcode || ''}`,
          text: item.preamble || '',
          link: item.get_url,
          price: null,
          priceLabel: null,
        }
      }),
    link: {
      text: rawData.call_to_action_text || 'view all Suburb Profiles',
      url: rawData.call_to_action_link && rawData.call_to_action_link.path,
    },
  }
}

export default FeaturedSuburbs
