import Image from '@global/Image'
import { isExternalURL } from '@lib/helpers'

const ImageText = (props) => {
  if (!props.item) return null

  return (
    <div>
      <a href={props.item.link && props.item.link.url}>
        <Image
          src={props.item.image && props.item.image.url}
          alt={props.item.image && props.item.image.alt}
        />
      </a>
      <div>
        <h4>{props.item.title}</h4>
        <p>{props.item.description}</p>
        {props.item.link && props.item.link.url && props.item.link.text ? (
          <p>
            <a
              target={isExternalURL(props.item.link.url) ? '_blank' : '_self'}
              href={props.item.link.url}
            >
              {props.item.link.text}
            </a>
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default ImageText
